import iconLowFrame from '../../../../assets/svgs/icon-low-frame'
import iconMixteFrame from '../../../../assets/svgs/icon-mixte-frame'
import iconHighFrame from '../../../../assets/svgs/icon-high-frame'
import iconFrontLoader from '../../../../assets/svgs/icon-front-loader'
import iconLongtail from '../../../../assets/svgs/icon-longtail'

const frameIcon = (slug) => {
    if (slug === 'low-frame') {
        return iconLowFrame
    }

    if (slug === 'mixte-frame') {
        return iconMixteFrame
    }

    if (slug === 'high-frame') {
        return iconHighFrame
    }

    if (slug === 'frontloader') {
        return iconFrontLoader
    }

    if (slug === 'longtail') {
        return iconLongtail
    }

    return ''
}

export default frameIcon
