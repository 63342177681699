export default class ButtonSwitch {

    static checkedBooleanMap = {
        'checked': true,
        'unchecked': false,
    }

    static booleanCheckedMap = {
        'true': 'checked',
        'false': 'unchecked',
    }

    constructor(html, config, callback) {
        this.html = html
        this.config = config
        this.callback = callback
        this.model = false
        this.inputElement = null

        this.init()
    }

    init() {
        this.findElements()
        this.addEventListeners()
    }

    findElements() {
        this.inputElement = this.html.querySelector('.button-switch__input')
    }

    addEventListeners() {
        this.inputElement.addEventListener('click', this.onButtonClicked.bind(this))
    }

    onButtonClicked() {
        this.model = this.inputElement.checked
        this.tryCallback()
    }

    getMappedOutput() {
        const { optionalValueMap } = this.config
        let value

        if (optionalValueMap) {
            const valueStringified = this.model.toString()
            const key = this.constructor.booleanCheckedMap[valueStringified]
            value = optionalValueMap[key]
        } else {
            value = this.model
        }

        return {
            [ this.config.name ]: value
        }
    }

    tryCallback() {
        if (this.callback) {
            const mappedOutput = this.getMappedOutput()
            this.callback(mappedOutput)
        }
    }

    updateInputByModel() {
        this.inputElement.checked = this.model
    }

    setAndApplyModel(model) {
        const newValue = model[this.config.name]

        if (newValue) {
            this.model = this.sanitizeValue(newValue)
            this.updateInputByModel()
        }
    }

    sanitizeValue(value) {
        const { optionalValueMap } = this.config

        if (optionalValueMap) {
            const key = Object.keys(optionalValueMap).find(key => optionalValueMap[key] === value)
            return this.constructor.checkedBooleanMap[key]
        } else {
            return value
        }
    }
}
