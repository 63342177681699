/*global _ */
import BaseComponent from './base-component'

const templateStringSelectRange = `
    <div class="select-range">
        <div class="select-range__slider">
            <div class="select-range__gui">
                <div class="select-range__track"></div>
                <div class="select-range__range"></div>
                <span class="select-range__thumb select-range__thumb--left"></span>
                <span class="select-range__thumb select-range__thumb--right"></span>
            </div>

            <input class="select-range__input select-range__input--left" type="range" tabindex="0" step="1"/>
            <input class="select-range__input select-range__input--right" type="range" tabindex="0" step="1"/>
        </div>
        <div class="select-range__labels">
            <p class="select-range__label select-range__label--left"></p>
            <p class="select-range__label-dash">–</p>
            <p class="select-range__label select-range__label--right"></p>
        </div>
    </div>
`

export default class SelectRange extends BaseComponent {

    static type = 'select-range'

    constructor(config, callback) {
        super()

        this.config = config
        this.callback = callback
        this.model = null
        this.modelUserInput = null

        this.inputLeft = null
        this.inputRight = null
        this.thumbLeft = null
        this.thumbRight = null
        this.range = null
        this.labelMin = null
        this.labelMax = null

        this.debounceInput = _.debounce(this.onDebouncedInput.bind(this), 500)
    }

    get templateString() {
        return templateStringSelectRange
    }

    onRendered() {
        this.createModels()
        // add user input handling
        this.findElements()
        this.applyRangeToInputs()
        this.applyModelToInputs()
        this.refreshGui()
        this.addEventListeners()
    }

    createModels() {
        this.model = {
            valueMin: this.config.filterMin,
            valueMax: this.config.filterMax,
        }

        this.modelUserInput = {
            valueMin: null,
            valueMax: null,
        }
    }

    findElements() {
        this.inputLeft = this.html.querySelector('.select-range__input--left')
        this.inputRight = this.html.querySelector('.select-range__input--right')
        this.thumbLeft = this.html.querySelector('.select-range__thumb--left')
        this.thumbRight = this.html.querySelector('.select-range__thumb--right')
        this.range = this.html.querySelector('.select-range__range')
        this.labelMin = this.html.querySelector('.select-range__label--left')
        this.labelMax = this.html.querySelector('.select-range__label--right')
    }

    applyRangeToInputs() {
        const { filterMin, filterMax } = this.config
        this.setInputMinMaxAttrs(this.inputLeft, filterMin, filterMax)
        this.setInputMinMaxAttrs(this.inputRight, filterMin, filterMax)
    }

    setInputMinMaxAttrs(input, min, max) {
        input.setAttribute('min', min)
        input.setAttribute('max', max)
    }

    applyModelToInputs() {
        this.inputLeft.value = this.model.valueMin
        this.inputRight.value = this.model.valueMax
    }

    addEventListeners() {
        this.inputLeft.addEventListener('input', this.onInputLeftChanged.bind(this))
        this.inputRight.addEventListener('input', this.onInputRightChanged.bind(this))
    }

    onInputLeftChanged() {
        this.handleInputChanged(this.inputLeft.value, 'valueMin')
    }

    onInputRightChanged() {
        this.handleInputChanged(this.inputRight.value,'valueMax')
    }

    handleInputChanged(value, side) {
        const sanitizedValue = this.getSanitizedInputValueBySide(side)
        const wasSanitized = value !== sanitizedValue
        const valuePair = {
            [side]: sanitizedValue
        }

        this.setModelUserInputValues(valuePair)
        this.setModelValues(valuePair)

        if (wasSanitized) {
            this.applyModelToInputs()
        }

        this.refreshGui()

        this.debounceInput()
    }

    get bufferMinMaxValue() {
        const { filterMin, filterMax } = this.config
        const buffer =  (filterMax - filterMin) * 0.1
        return this.getRoundedValue(buffer, 'ceil')
    }

    getSanitizedInputValueBySide(sideMoved) {
        if (sideMoved === 'valueMin') {
            const roundedValue = this.getRoundedValue(this.inputLeft.value, 'floor')
            const roundedValueInsideRage = Math.max(roundedValue, this.config.filterMin)
            return Math.min(roundedValueInsideRage, this.model.valueMax - this.bufferMinMaxValue)
        } else if (sideMoved === 'valueMax') {
            const roundedValue = this.getRoundedValue(this.inputRight.value, 'ceil')
            const roundedValueInsideRage = Math.min(roundedValue, this.config.filterMax)
            return Math.max(roundedValueInsideRage, this.model.valueMin - (-1 * this.bufferMinMaxValue))
        }
    }

    getRoundedValue(value, method = 'round') {
        return Math[method](value * 0.01) * 100
    }

    setModelValues(values) {
        const { valueMin, valueMax } = values
        if (valueMin) {
            this.setModelValue('valueMin', values.valueMin)
        }

        if (valueMax) {
            this.setModelValue('valueMax', values.valueMax)
        }
    }

    setModelUserInputValues(values) {
        this.modelUserInput = { ...this.modelUserInput, ...values }
    }

    setModelValue(name, value) {
        this.model[name] = value
    }

    refreshGui() {
        this.setThumbPosition(this.thumbLeft, this.model.valueMin)
        this.setThumbPosition(this.thumbRight, this.model.valueMax)

        this.updateRangePositionAndWidth()
        this.applyModelToLabels()
    }

    setThumbPosition(thumb, value) {
        const positionPercent = this.getPercentFromValue(value)
        thumb.style.left = `${positionPercent}%`
    }

    updateRangePositionAndWidth() {
        const rangeMin = this.config.filterMin
        const rangeMax = this.config.filterMax
        const width = this.model.valueMax - this.model.valueMin
        const widthPercent = (width / (rangeMax - rangeMin)) * 100
        const positionPercent = this.getPercentFromValue(this.model.valueMin)

        this.range.style.left = `${positionPercent}%`
        this.range.style.width = `${widthPercent}%`
    }

    getPercentFromValue(value) {
        const rangeMin = this.config.filterMin
        const rangeMax = this.config.filterMax
        return ((value - rangeMin) / (rangeMax - rangeMin)) * 100
    }

    applyModelToLabels() {
        const { valueMin, valueMax } = this.model
        const { labelMin, labelMax } = this.config

        this.labelMin.innerHTML = `${valueMin}${labelMin}`
        this.labelMax.innerHTML = `${valueMax}${labelMax}`
    }

    onDebouncedInput() {
        this.tryCallback()
    }

    tryCallback() {
        if (this.callback) {
            const mappedOutput = {
                [ this.config.nameMin ]: this.model.valueMin,
                [ this.config.nameMax ]: this.model.valueMax,
            }

            this.callback(this.config.name, mappedOutput)
        }
    }

    tryApplyNewModel(model) {
        const valueMin = model[ this.config.nameMin ]
        const valueMax = model[ this.config.nameMax ]

        const sanitizedValues = {
            valueMin: valueMin ?? this.config.filterMin,
            valueMax: valueMax ?? this.config.filterMax,
        }

        this.setModelUserInputValues({ valueMin, valueMax })
        this.setModelValues(sanitizedValues)
        this.applyModelToInputs()
        this.refreshGui()
    }

    get isPristine() {
        return this.modelUserInput.valueMin == null && this.modelUserInput.valueMax == null
    }

    updateConfig() {}

    forceUpdateConfig(config) {
        const areRangeLimitsUndefined = !config.filterMin || !config.filterMax
        const areRangeLimitsUnchanged = this.config.filterMin === config.filterMin && this.config.filterMax === config.filterMax

        if (areRangeLimitsUndefined === true || areRangeLimitsUnchanged === true) {
            return
        }

        this.config = config

        const limitedValues = this.getLimitedValuesFromConfig()

        this.setModelValues(limitedValues)
        this.applyRangeToInputs()
        this.applyModelToInputs()
        this.refreshGui()
        //
        // todo
        // this.tryCallback()
    }

    getLimitedValuesFromConfig() {
        const { filterMin, filterMax } = this.config

        if (filterMin >= filterMax) {
            filterMin = filterMax - 1
        }

        const isValueMinPristine = !this.modelUserInput.valueMin
        const isValueMaxPristine = !this.modelUserInput.valueMax

        const valueMin = isValueMinPristine ? filterMin : Math.max(filterMin, this.modelUserInput.valueMin)
        const valueMax = isValueMaxPristine ? filterMax : Math.min(filterMax, this.modelUserInput.valueMax)

        return {
            valueMin,
            valueMax,
        }
    }
}
