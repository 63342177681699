const PROGRESS_BAR_HEIGHT = 30

export default class BikeAdvisorProgress {
    constructor(parentElement, questions) {
        this.parentElement = parentElement
        this.toolElement = parentElement.querySelector('.bike-advisor__tool')
        this.progressElement = parentElement.querySelector('.bike-advisor__progress')
        this.progressBarElement = parentElement.querySelector('.bike-advisor__progress-bar')
        this.questions = questions
        this.isVisible = true

        this.addEventListeners()
    }

    addEventListeners() {
        window.addEventListener('scroll', this.handleScrollAndResize.bind(this))
        window.addEventListener('resize', this.handleScrollAndResize.bind(this))
    }

    setProgressByIndex(index) {
        const ratio = (index + 1) / (this.questions.length + 1)
        this.progressBarElement.style.width = `calc(${ratio * 100}% + 10px)`
    }

    handleScrollAndResize() {
        this.updatePosition()
    }

    setVisibility(isVisible) {
        this.isVisible = isVisible
        this.updatePosition()
    }

    updatePosition() {
        this.progressElement.setAttribute('data-position', this.progressBarPosition)
    }

    get progressBarPosition() {
        if (this.isVisible === false) {
            return 'hidden'
        }

        if (
            this.toolElement.getBoundingClientRect().bottom < window.innerHeight * 1.5
            && this.parentElement.getBoundingClientRect().bottom > window.innerHeight
        ) {
            return 'sticky-bottom'
        }

        return 'hidden'
    }
}

