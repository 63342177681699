import type { LatLng, Address, Location } from '@typedefs/base'
import GeocodeService from './geocode-service'
import T3ServiceClassDecorator from './t3-service-class-decorator'
import CookieService from '@services/cookie-service'
import { Constants } from '~/app/Constants'

export default class LocationService extends T3ServiceClassDecorator {

    static serviceName = 'location-service'

    privacyHandler
    cookieService: CookieService
    geocodeService: GeocodeService

    public constructor(context) {
        super(context)
        this.privacyHandler = this.context.getService('privacy-handler')
        this.cookieService = this.context.getService('cookie-service')
        this.geocodeService = this.context.getService('geocode-service')
    }

    private get googleApiAllowed(): boolean {
        return this.privacyHandler.getCookiesAccepted(Constants.COOKIES.ADVERTISING)
    }

    /**
     * restore users location from cookies or locate users postion via google or navigator
     */
    public async getUserLocation(): Promise<Location> {
        let result: Location = {
            city: '',
        }

        const city = this.getStoredCity()

        if (city) {
            return {
                city,
            }
        }

        try {
            const location = await this.getDefaultLocation()
            result = {
                ...result,
                ...location,
            }

            if (this.googleApiAllowed) {
                const {
                    city,
                    country,
                } = await this.geocodeService.getAddressByLocation(location)

                result.city = city && country
                    ? `${city}, ${country}`
                    : city
            }
        } catch (error) {
            console.error('no location found')
        }
        return result
    }

    /**
     * get stored city from cookie
     */
    private getStoredCity(): string | undefined {
        const cityCookie = this.cookieService.getCookieValue(Constants.COOKIES.CITY)
        return cityCookie as string ?? undefined
    }

    /**
     * get stored location from cookies (latlong cookie),
     * otherwise get users location by google geocoding API (if consent is given)
     * or by browser via navigator API
     */
    private async getDefaultLocation(): Promise<LatLng> {
        const locationCookie = this.cookieService.getCookie(Constants.COOKIES.LATLONG)

        if (locationCookie) {
            return new Promise((resolve, reject) => {
                try {
                    const location = JSON.parse(decodeURI(locationCookie))
                    resolve(location)
                } catch (error) {
                    reject(error)
                }
            })
        } else if (this.googleApiAllowed) {
            return this.geocodeService.getLocation()
        } else {
            return this.geocodeService.getLocationByBrowser()
        }
    }
}
