/*global _ */

import { loadAllImages } from '../../helpers/image-loader'

export default class ResultsRenderer {

    static durationHide = 1000
    static durationShow = 1000

    constructor(container, mask) {
        this.container = container
        this.mask = mask || container
        this.template = null
    }

    async showResults(results) {
        this.lockMaskHeight()
        // await this.fadeOutResults()
        this.removeResults()
        this.renderResults(results)
        this.loadImages()
        // this.fadeInResults()
        this.unlockMaskHeight()
    }

    removeResults() {
        this.container.innerHTML = ''
    }

    renderResults(results) {
        const template = _.template(this.template)
        const parsedTemplate = template(results)
        this.container.innerHTML = parsedTemplate
    }

    loadImages() {
        loadAllImages(this.container)
    }

    fadeOutResults() {
        this.container.classList.add('hidden')

        return new Promise((resolve) => {
            setTimeout(() => resolve(), this.constructor.durationHide)
        })
    }

    fadeInResults() {
        this.container.classList.remove('hidden')
    }

    lockMaskHeight() {
        const actualHeight = this.mask.offsetHeight
        this.mask.style.height = `${actualHeight}px`
    }

    unlockMaskHeight() {
        this.mask.style.height = 'auto'
    }
}
