import { Cubic, TweenLite } from '~/app-modules/utils/global-imports'
import { toggleElementClass } from '~/app-modules/utils/html-element-utils'
import {scrollElementIntoView} from '~/app-modules/utils/animation-utils'
import BikeAdvisorSummarySentences from '@modules/bike-advisor/partials/bike-advisor-summary-sentences'

const TOP_BAR_HEIGHT = 56

export default class BikeAdvisorSummary {
    constructor(parentElement) {
        this.outputElement = parentElement.querySelector('.bike-advisor__summary-question-inner')
        this.toolElement = parentElement.querySelector('.bike-advisor__tool')
        this.resultsElement = parentElement.querySelector('.bike-advisor__results')
        this.summaryWrapperElement = parentElement.querySelector('.bike-advisor__summary-wrapper')
        this.summaryElement = parentElement.querySelector('.bike-advisor__summary')
        this.toggleButton = this.summaryElement.querySelector('.bike-advisor__button-toggle-output')
        this.summaryFooterElement = this.summaryElement.querySelector('.bike-advisor__summary-footer')

        this.isVisible = false
        this.isTransitionFinished = false
        this.isPinnedToTool = false
        this.isScrollingTopLocked = false

        this.addEventListeners()
    }

    addEventListeners() {
        window.addEventListener('scroll', this.handleScrollAndResize.bind(this))
        window.addEventListener('resize', this.handleScrollAndResize.bind(this))

        this.toggleButton.addEventListener('click', this.onButtonToggleClicked.bind(this))
    }

    onButtonToggleClicked() {
        if (this.isSummaryScrolledIntoView) {
            this.scrollToResults()
        } else {
            this.scrollToSummary()
        }
    }

    scrollToSummary() {
        TweenLite.to(window, 0.8, {
            scrollTo: { y: this.scrollLockPosition, autoKill: false },
            delay: 0.0,
            ease: Cubic.easeInOut,
        })
    }

    scrollToResults() {
        const scrollOptions = {
            duration: 0.8,
            reference: 'bottom',
            offset: TOP_BAR_HEIGHT * -1,
        }

        scrollElementIntoView(this.toolElement, scrollOptions)
    }

    get isSummaryScrolledIntoView() {
        return this.summaryElement.getBoundingClientRect().top - TOP_BAR_HEIGHT > -20
    }

    get isScrollLockPositionReached() {
        return this.toolElement.getBoundingClientRect().bottom >= this.summaryElement.offsetHeight + TOP_BAR_HEIGHT
    }

    get scrollLockPosition() {
        return window.scrollY + this.toolElement.getBoundingClientRect().bottom - this.summaryElement.offsetHeight - TOP_BAR_HEIGHT
    }

    setScrollingTopLocked(isLocked) {
        this.isScrollingTopLocked = isLocked
    }

    handleScrollAndResize() {
        this.checkIsTransitionFinished()
        this.updateWrapperPosition()
        this.updateButtonRotation()
        this.handleScrollLock()
    }

    handleScrollLock() {
        if (
            this.isScrollingTopLocked &&
            this.isScrollLockPositionReached
        ) {
            window.scrollTo({ top: this.scrollLockPosition })
        }
    }

    checkIsTransitionFinished() {
        if (this.resultsElement.getBoundingClientRect().top <= TOP_BAR_HEIGHT) {
            this.isTransitionFinished = true
        }
    }

    updateButtonRotation() {
        this.summaryWrapperElement.setAttribute(
            'data-button-rotation',
            this.isSummaryScrolledIntoView ? 'bottom' : 'top',
        )
    }

    updateWrapperPosition() {
        this.summaryWrapperElement.setAttribute(
            'data-position',
            this.wrapperPosition
        )
    }

    get hasScrolledResultsToTop() {
        return this.resultsElement.getBoundingClientRect().top <= TOP_BAR_HEIGHT
    }

    get hasScrolledResultsIntoViewport() {
        return this.toolElement.getBoundingClientRect().bottom < window.innerHeight * 0.4
    }

    get wrapperPosition() {
        if (
            this.isTransitionFinished
            && this.toolElement.getBoundingClientRect().bottom < TOP_BAR_HEIGHT
        ) {
            return 'fixed-top'
        } else {
            return 'attached-to-tool'
        }
    }

    showButtons() {
        return new Promise((resolve) => {
            TweenLite.to(this.summaryFooterElement, 0.5, {opacity: 1, ease: Cubic.easeOut, onComplete: resolve })
        })
    }

    hideButtonsInstant() {
        TweenLite.set(this.summaryFooterElement, {opacity: 0})
    }

    setSummaryTransitions(enabled) {
        toggleElementClass(this.summaryElement, 'bike-advisor__summary--no-transition', !enabled)
    }

    resetAnimations() {
        this.isTransitionFinished = false

        this.setSummaryTransitions(false)
        this.updateWrapperPosition()
        this.hideButtonsInstant()

        return new Promise((resolve) => {
            requestAnimationFrame(() => {
                this.setSummaryTransitions(true)
                resolve()
            })
        })
    }

    async addSentencesWithChoiceAnimated(sentences, selectedSlugs) {
        const outputInstance = new BikeAdvisorSummarySentences(sentences, selectedSlugs)
        this.outputElement.appendChild(outputInstance.html)

        await outputInstance.fadeIn()
    }

    removeOutput() {
        this.outputElement.innerHTML = ''
    }

    fadeInOut(show = true) {
        return new Promise((resolve) => {
            const opacity = show ? 1 : 0
            const onComplete = () => {
                this.summaryWrapperElement.style.pointerEvents = show ? 'auto' : 'none'
                resolve()
            }
            TweenLite.to(this.summaryWrapperElement, 0.85, {opacity, ease: Cubic.easeInOut, onComplete })
        })
    }

    show() {
        return this.fadeInOut(true)
    }

    hide() {
        return this.fadeInOut(false)
    }
}
