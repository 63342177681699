import axios from 'axios'
import { getActualLanguage } from '@helpers/language'


export default class GraphQlBaseApi {
    constructor() {
        this.endpoint = ''
        this.axios = axios.create()
    }

    callAPI(query, variables = {}) {
        const options = {
            query,
            variables,
        }

        const config = {
            headers: {
                'Accept-Language': getActualLanguage(),
            },
        }

        return this.axios.post(this.endpoint, options, config)
    }
}
