import BaseComponent from '@partials/selects/base-component'
import {
    getNodeRecursively,
    splitTextOptionIntoWordsFilled
} from '@modules/bike-advisor/bike-advisor-helper'
import {addAnimationClass, getDurationFromCustomProperty} from '@modules/bike-advisor/aimation-helper'

const wordElement = ({type, text}) => {
    if (type === 'part') {
        return `<span class="sentence__part">${text}</span>`
    }

    if (type === 'punctuation') {
        return `<span class="sentence__part sentence__part--punctuation">${text}</span>`
    }

    if (type === 'bold') {
        return `<span class="sentence__placeholder">${text}</span>`
    }
}

const wordTemplate = (word) => {
    if (word.type === 'wrapper') {
        return `<span class="sentence__wrapper">
                    ${word.parts.map(part => wordElement(part)).join('')}
                </span>`
    } else {
        return wordElement(word)
    }
}

const template = (data) => `
    ${data.words.map(part => wordTemplate(part)).join('')}
`

export default class BikeAdvisorSummarySentences extends BaseComponent {
    constructor(sentences, selectedSlugs) {
        super()
        this.config = this.createConfig(sentences, selectedSlugs)
        this.init()
    }


    createConfig(sentences, selectedSlugs) {
        const words = sentences.reduce((accumulator, { question, choice }) => {
            const { node: textOption } = getNodeRecursively(question.text_options, selectedSlugs)
            const parts = splitTextOptionIntoWordsFilled(textOption, choice)
            return [...accumulator, ...parts]
        }, [])

        return {
            words,
        }
    }

    setRootElement() {
        super.setRootElement()
        this.animatedElements = this.templateElement.content.querySelectorAll('.sentence__part, .sentence__placeholder')
    }

    fillTemplateString() {
        this.parsedTemplate = template(this.config)
    }

    get durationFade() {
        if (!this._durationFade) {
            this._durationFade = getDurationFromCustomProperty(this.animatedElements[0], '--duration-fade')
        }
        return this._durationFade
    }

    fadeIn(delay = 0) {
        return Promise.all(
            Array.from(this.animatedElements).map((element, index) => {
                const elementDelay = delay + index * 80
                return addAnimationClass(element, 'fade-in', this.durationFade, elementDelay)
            })
        )
    }
}
