const templateCardBike = `
        <div class='s-12 m-6 l-5<% if (index%2 !== 0) { %> l-left-2<% }; %> xl-4 xl-left-0'>
            <a href='<%= product.link %>' class='product-bike'>

                <div class='product-bike__label-top dateline addition'>
                    <span><%= product.state %>
                    <% if (product.condition === 'used' && product.conditionDetail) { %>
                        <br>
                        <span><%= staticTranslations.conditionDetail %>:</span>
                        <span class='product-bike__condition-detail'><%= product.conditionDetail %></span>
                    <% }; %>
                </div>

                <% if (product.defaultImage) { %>
                <div class='product-bike__container-figure'>
                    <figure data-src='<%= product.defaultImage.url %>' data-alt='<%= product.defaultImage.alt || "" %>'></figure>
                </div>
                <% }; %>

                <div class='product-bike__wrapper-content'>
                    <div class='product-bike__headline'>
                        <h2><%= product.translation && product.translation.name ? product.translation.name : product.name %></h2>
                        <div class='product-bike__wrapper-price'>
                            <mark class='product-bike__price single-tag'>
                            <% if (product.priceOverride == null) { %>
                                <span><%= staticTranslations.labelListPrice %></span>
                            <% } %>
                            <% if (product.variants.length > 1) { %><%= staticTranslations.labelPrefixPriceFrom %><% }; %>
                            <%= product.priceCheapest %>
                            </mark>
                            <% if (product.priceOverride !== null) { %>
                            <span>
                                <span><%= staticTranslations.labelListPrice %></span>
                                <s><%= product.priceOverride %></s>
                            </span>
                            <% }; %>
                        </div>
                    </div>
                    <div class='product-bike__info'>
                        <% if (product.engine) { %>
                        <p>
                            <b><%= staticTranslations.engine %>:</b>
                            <span class='font--regular'><%= product.engine %></span>
                        <% }; %>
                        <% if (product.gear) { %>
                        <p>
                            <b><%= staticTranslations.gear %>:</b>
                            <span class='font--regular'><%= product.gear %></span>
                        </p>
                        <% }; %>
                    </div>

                    <div class='product-bike__colors colors__container'>
                        <% _.each(product.colors, function(color) { %>
                            <%= colorCircle(color) %>
                        <% }); %>
                    </div>
                </div>
            </a>
        </div>
`

export default templateCardBike
