import {parseToMilliseconds} from '@modules/bike-advisor/bike-advisor-helper'

const getDurationFromCustomProperty = (element, propertyName) => {
    const propertyValue = getComputedStyle(element).getPropertyValue(propertyName)
    return propertyValue ? parseToMilliseconds(propertyValue) : 0
}

const setHeight = (element, referenceElement) => {
    element.style.height = `${referenceElement.offsetHeight}px`
}

const animateHeight = (element, referenceElement) => {
    const duration = getDurationFromCustomProperty(element, '--duration-animate-height')

    return new Promise((resolve) => {
        element.style.height = `${referenceElement.offsetHeight}px`

        setTimeout(() => {
            resolve()
        }, duration)
    })
}

const setHeightAuto = (element) => {
    element.style.height = 'auto'
}

const addAnimationClass = (element, className, duration, delay = 0) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            element.classList.add(className)
        }, delay)

        setTimeout(() => {
            resolve()
        }, delay + duration)
    })
}

export { setHeight, animateHeight, setHeightAuto, getDurationFromCustomProperty, addAnimationClass }
