const TOP_BAR_HEIGHT = 56

export default class BikeAdvisorTool {
    constructor(element) {
        this.element = element

        // this.wrapperProgress = this.element.querySelector('.bike-advisor__wrapper-progress')
        this.coverElement = this.element.querySelector('.bike-advisor__tool-cover')
    }

    sanitizeHeight(sanitizeScrollPos = true) {
        const oldHeight = this.element.offsetHeight
        const newHeight = Math.max(
            this.element.querySelector('.bike-advisor__tool-inner').offsetHeight,
            window.innerHeight - TOP_BAR_HEIGHT
        )

        const oldScrollY = window.scrollY
        console.log('SH: ', {oldHeight, newHeight, oldScrollY })

        this.element.style.height = `${newHeight}px`

        if (sanitizeScrollPos) {
            const top = window.scrollY + this.element.getBoundingClientRect().bottom + (window.innerHeight * -1)
            window.scrollTo({ top })
        }
    }
}
