import templateRetailerCard from './template-retailer-card'

const templateRetailerMapOverlay = `
    <div class="retailer-item maps-overlay background-white">
        ${templateRetailerCard}
        <button data-ref="button-close" class="btn-icon icon-close medium"></button>
    </div>
`

export default templateRetailerMapOverlay
