import iconCheck from '../../assets/svgs/icon-check'
import { toggleElementClass } from './../../utils/html-element-utils'
import BaseComponent from './base-component'

const templateSelectOptions = `
    <div class="select-options">
        <% _.each( items, function( item, index ) { %>
        <div class="select-options__item">
            <input
                class="select-options__input"
                type="checkbox"
                id="<%= name %>-<%= item.id %>"
                name="<%= item.name %>">

            <label class="select-options__label" for="<%= name %>-<%= item.id %>"><%= item.label %></label>
            <div class="select-options__box">${iconCheck}</div>
        </div>
        <% }); %>
    </div>
`

export default class SelectOptions extends BaseComponent {

    static type = 'select-options-multi'

    constructor(config, callback) {
        super()

        this.config = config
        this.callback = callback
        this.model = null
        this.checkboxes = []
    }

    get templateString() {
        return templateSelectOptions
    }

    onRendered() {
        this.createModel()
        this.parseItems()
    }

    createModel() {
        this.model = []
    }

    parseItems() {
        this.config.items.forEach((item) => {
            const name = `${this.config.name}-${item.id}`
            const checkbox = this.html.querySelector(`#${name}`)
            this.checkboxes.push(checkbox)

            this.addEventListener(checkbox)
        })
    }

    addEventListener(checkbox) {
        checkbox.addEventListener('change', this.onCheckboxChanged.bind(this))
    }

    onCheckboxChanged(event) {
        // use input id as name, to cover checkboxes and radio-buttons
        const { name, checked } = event.target

        this.callHookCheckboxChanged(name, checked)
        this.setModelValue(name, checked)
        this.tryCallback()
    }

    callHookCheckboxChanged() {}

    setModelValue(name, checked) {
        if (checked) {
            if (!this.model.includes(name)) {
                this.model.push(name)
            }
        } else {
            this.model = this.model.filter((itemName) => {
                return itemName !== name
            })
        }
    }

    getMappedOutput() {
        return {
            [ this.config.name ]: this.model
        }
    }

    tryCallback() {
        if (this.callback) {
            const mappedOutput = this.getMappedOutput()
            this.callback(this.config.name, mappedOutput)
        }
    }

    tryApplyNewModel(modelAllFilters) {
        /*
        Object.entries(modelAllFilters).forEach(([key, values]) => {
            if (key === this.config.name) {
                this.updateAllValues(values)
            }
        })
        */

        const values = modelAllFilters[this.config.name] || []
        this.updateAllValues(values)
    }

    updateAllValues(values) {
        this.checkboxes.forEach(checkbox => {
            // use input id as name, to cover checkboxes and radio-buttons
            const { name } = checkbox
            const shouldBeChecked = values.includes(name)

            checkbox.checked = shouldBeChecked

            this.setModelValue(name, shouldBeChecked)
        })
    }

    updateConfig(config) {
        this.config = config
        this.applyConfigToItems(true)
    }

    forceUpdateConfig(config) {
        this.config = config
        this.applyConfigToItems(false)
    }

    applyConfigToItems(onlyApplyEnabledItems = false) {
        this.checkboxes.forEach(checkbox => {
            const { name } = checkbox
            const relatedConfig = this.config.items.find(item => item.name === name)

            if (onlyApplyEnabledItems === true && relatedConfig.disabled === true) {
                return
            }

            const parentElement = checkbox.parentElement
            this.setDisabledToCheckbox(parentElement, relatedConfig.disabled)
        })
    }

    setDisabledToCheckbox(checkbox, disabled) {
        toggleElementClass(checkbox, 'select-options__item--disabled', disabled)
    }
}
