/*global axios */

const questionsApiUrl = '/api/advisor/sections/'
const resultsApiUrl = '/api/advisor/results/'

const fetchQuestions = () => {
    return axios.get(questionsApiUrl)
}

const fetchResults = (choices, csrfMiddlewareToken) => {
    const config = {
        headers: {
            // needed for api calls when user login is required
            'X-CSRFToken': csrfMiddlewareToken,
        }
    }
    return axios.post(resultsApiUrl, { choices }, config)
}

export { fetchQuestions, fetchResults }
