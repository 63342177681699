import T3ServiceClassDecorator from './t3-service-class-decorator'
import type { Location } from '@typedefs/base'
import { fetchRetailerById } from '@api/rest/retailer-search-api'
import { Config, Constants } from '~/app/Constants'

export default class CookieService extends T3ServiceClassDecorator {

    static serviceName = 'cookie-service'

    private readonly functionalCookies: string[]
    private readonly performanceCookies: string[]
    private readonly cookiesGA: Record<'regular' | 'global' | 'dirty', string>

    constructor(context) {
        super(context)

        this.functionalCookies = [
            Constants.COOKIES.CITY,
            Constants.COOKIES.LATLONG,
            Constants.COOKIES.PRECISION_LAYER,
            Constants.COOKIES.RETAILER_ID,
            Constants.COOKIES.RETAILER_NAME,
            Constants.COOKIES.RETAILER_CITY,
            Constants.COOKIES.RETAILER_URL,
        ]

        this.performanceCookies = [
            '_gat_globalTracker',
            '_gat',
            '_gid',
            '_ga',
        ]

        this.cookiesGA = {
            regular: 'ga-disable-' + this.config.gaProperty,
            global: 'ga-disable-' + this.config.gaPropertyGlobal,
            dirty: 'ga-disable-' + this.config.gaPropertyDirty,
        }
    }

    public getCookie(name: string) {
        const regexp = new RegExp(name + '=([^;]+)')
        const result = regexp.exec(document.cookie)
        return result?.length
            ? result[1]
            : null
    }

    public createCookie(
        name: string,
        value: string | number | boolean,
        expiresInDays: number = Config.COMMON_COOKIE_LIFETIME,
        domain?: string,
    ) {
        let cookie = `${name}=${encodeURIComponent(value)};`

        const currentTimeInMiliseconds = new Date().getTime()
        const expiresInMiliseconds = expiresInDays * 1000 * 60 * 60 * 24
        const expirationDate = new Date(currentTimeInMiliseconds + expiresInMiliseconds)
        cookie += `expires=${expirationDate.toGMTString()};`
        cookie += 'path=/;'

        if (domain) {
            cookie += `domain=${domain};`
        }

        document.cookie = cookie
    }

    public deleteCookie(name: string, domain?: string) {
        if (this.getCookie(name)) {
            this.createCookie(name, '', -1, domain)
        }
    }

    private deleteMultipleCookies(cookies: string[]) {
        cookies.forEach((name) => this.deleteCookie(name))
    }

    public deleteAllCookies() {
        this.deleteMultipleCookies(Object.values(Constants.COOKIES))
    }

    private decodeCookie(value: string | null): string | null {
        return value
            ? decodeURIComponent(value)
            : null
    }

    public getCookieValue(name: string) {
        return this.decodeCookie(this.getCookie(name))
    }

    public getRetailerFromCookies() {
        return {
            name: this.getCookieValue(Constants.COOKIES.RETAILER_NAME),
            city: this.getCookieValue(Constants.COOKIES.RETAILER_CITY),
            url: this.getCookieValue(Constants.COOKIES.RETAILER_URL),
        }
    }

    public setLocationCookies(location: Location) {
        const { city, country, ...coordinates } = location
        this.deleteCookie(Constants.COOKIES.CITY)
        this.deleteCookie(Constants.COOKIES.LATLONG)

        if (city) {
            this.createCookie(Constants.COOKIES.CITY, city)
        }
        if (coordinates.lat && coordinates.lng) {
            this.createCookie(Constants.COOKIES.LATLONG, JSON.stringify(coordinates))
        }
    }

    /**
     * disable GA Tracking
     * https://developers.google.com/analytics/devguides/collection/analyticsjs/user-opt-out
     */
    public setCookieGAOptOut() {
        this.createCookie(this.cookiesGA.regular, true, 365 * 100)
        this.createCookie(this.cookiesGA.global, true, 365 * 100)
        this.createCookie(this.cookiesGA.dirty, true, 365 * 100)

        window[this.cookiesGA.regular] = true
        window[this.cookiesGA.global] = true
        window[this.cookiesGA.dirty] = true

        window.trackingEnabled = false
    }

    public setCookieGAOptIn() {
        window[this.cookiesGA.regular] = true
        window[this.cookiesGA.global] = true
        window[this.cookiesGA.dirty] = true

        window.trackingEnabled = true

        this.deleteCookie(this.cookiesGA.regular)
        this.deleteCookie(this.cookiesGA.global)
        this.deleteCookie(this.cookiesGA.dirty)
    }

    public async setRetailerCookies(id: number) {
        try {
            const retailer = await fetchRetailerById(id)
            if (retailer) {
                this.createCookie(Constants.COOKIES.RETAILER_ID, retailer.retailer_id)
                this.createCookie(Constants.COOKIES.RETAILER_NAME, retailer.name)
                this.createCookie(Constants.COOKIES.RETAILER_CITY, retailer.city)
                this.createCookie(Constants.COOKIES.RETAILER_URL, retailer.absolute_url)
            } else {
                this.deleteCookie(Constants.COOKIES.RETAILER_ID)
            }
        } catch (error) {
            console.warn('Cookie Service - Retailer not found')
        }
    }

    public setAllCookies() {
        this.setFunctionalCookies()
        this.setPerformanceCookies()
        this.setAdvertisingCookies()
        this.context.broadcast(Broadcasts.COOKIES_ALL_ACCEPTED)
    }

    public setFunctionalCookies() {
        this.createCookie(Constants.COOKIES.FUNCTIONAL, true)
        this.context.broadcast(Broadcasts.COOKIES_FUNCTIONAL_ACCEPTED)
    }

    public deleteFunctionalCookies() {
        this.deleteCookie(Constants.COOKIES.FUNCTIONAL)
        this.deleteMultipleCookies(this.functionalCookies)
    }

    public setPerformanceCookies() {
        this.setCookieGAOptIn()
        window.createGaTracking()

        this.createCookie(Constants.COOKIES.PERFORMANCE, true)
        this.context.broadcast(Broadcasts.COOKIE_PERFORMANCE_ACCEPTED)
    }

    public deletePerformanceCookies() {
        this.deleteCookie(Constants.COOKIES.PERFORMANCE)
        this.deleteMultipleCookies(this.performanceCookies)
        this.deleteMultipleCookies(Object.values(this.cookiesGA))

        this.setCookieGAOptOut()
    }

    public setAdvertisingCookies() {
        this.createCookie(Constants.COOKIES.ADVERTISING, true)
        this.context.broadcast(Broadcasts.COOKIES_ADVERTISING_ACCEPTED)
    }

    public deleteAdvertisingCookies() {
        this.deleteCookie(Constants.COOKIES.ADVERTISING)
    }
}
