import T3ClassDecorator from './../t3-class-decorator'
import { hideBreadcrumb } from '../../helpers/hide-breadcrumb'

export default class Headline extends T3ClassDecorator {

    static moduleName = '06-headline'

    constructor(context) {
        super(context)

        if (this.config.showBreadcrumb === true) {
            hideBreadcrumb()
        }
    }
}
