/*global _ */
import BaseComponent from './base-component'

const templateStringSelectRange = `
    <div class="select-range">
        <div class="select-range__slider">
            <div class="select-range__gui">
                <div class="select-range__track"></div>
                <div class="select-range__range"></div>
                <span class="select-range__thumb select-range__thumb--left"></span>
            </div>

            <input class="select-range__input select-range__input--left" type="range" tabindex="0" step="1"/>
        </div>
        <div class="select-range__labels">
            <p class="select-range__label select-range__label--left"></p>
        </div>
    </div>
`

export default class SelectRangeSingle extends BaseComponent {

    static type = 'select-range-single'

    constructor(config, callback) {
        super()

        this.config = config
        this.callback = callback
        this.model = null
        this.modelUserInput = null

        this.input = null
        this.thumb = null
        this.range = null
        this.labelCenter = null

        this.debounceInput = _.debounce(this.onDebouncedInput.bind(this), 500)
    }

    get templateString() {
        return templateStringSelectRange
    }

    onRendered() {
        this.setModelValue(this.config.value)

        // add user input handling
        this.findElements()
        this.applyValues()
        this.addEventListeners()
    }

    findElements() {
        this.input = this.html.querySelector('.select-range__input--left')
        this.thumb = this.html.querySelector('.select-range__thumb--left')
        this.range = this.html.querySelector('.select-range__range')
        this.labelCenter = this.html.querySelector('.select-range__label--left')
    }

    applyValues() {
        // 1. min, max must be set first
        this.setInputAttributes(this.input)

        // 2. value must be set second
        this.applyModelToInputs()

        // 3. apply changes to gui markup
        this.refreshGui()
    }

    setInputAttributes(input) {
        input.setAttribute('min', this.config.min)
        input.setAttribute('max', this.config.max)
    }

    applyModelToInputs() {
        this.input.value = this.model
    }

    addEventListeners() {
        this.input.addEventListener('input', this.onInputChanged.bind(this))
    }

    onInputChanged() {
        const { value: newValue, wasSanitized } = this.getLimitedInputValue()

        this.setModelUserInputValue(newValue)
        this.setModelValue(newValue)

        if (wasSanitized) {
            this.applyModelToInputs()
        }

        this.refreshGui()
        this.debounceInput()
    }

    refreshGui() {
        this.setThumbPosition(this.thumb, this.model)
        this.updateRangeWidth()
        this.applyModelToLabel()
    }

    getLimitedInputValue() {
        const valueLimitedMin = Math.max(this.config.filterMin, this.input.value)
        const valueLimitedMinMax = Math.min(valueLimitedMin, this.config.filterMax)

        const wasSanitized = this.input.value !== valueLimitedMinMax

        return {
            value: valueLimitedMinMax,
            wasSanitized,
        }
    }

    setModelValue(value) {
        this.model = value
    }

    setModelUserInputValue(value) {
        this.modelUserInput = value
    }

    setThumbPosition(thumb, value) {
        const positionPercent = this.getPercentFromValue(value)
        thumb.style.left = `${positionPercent}%`
    }

    updateRangeWidth() {
        const widthPercent = this.getPercentFromValue(this.model)

        this.range.style.left = '0'
        this.range.style.width = `${widthPercent}%`
    }

    getPercentFromValue(value) {
        const { min, max } = this.config
        return ((value - min) / (max - min)) * 100
    }

    applyModelToLabel() {
        this.labelCenter.innerHTML = `${this.model}${this.config.labelCenter}`
    }

    onDebouncedInput() {
        this.tryCallback()
    }

    tryCallback() {
        if (this.callback) {
            const mappedOutput = {
                [ this.config.name ]: this.model,
            }

            this.callback(this.config.name, mappedOutput)
        }
    }

    tryApplyNewModel(model) {
        const value = model[ this.config.name ]
        const sanitizedValue = value ?? this.config.value

        this.setModelUserInputValue(value)
        this.setModelValue(sanitizedValue)
        this.applyModelToInputs()
        this.refreshGui()
    }

    get isPristine() {
        return this.modelUserInput == null
    }

    updateConfig() {}

    forceUpdateConfig(config) {
        const areRangeLimitsUndefined = !config.filterMin || !config.filterMax
        const areRangeLimitsUnchanged = this.config.filterMin === config.filterMin && this.config.filterMax === config.filterMax

        if (areRangeLimitsUndefined === true || areRangeLimitsUnchanged === true) {
            return
        }

        this.config = config

        const { filterMin, filterMax } = this.config
        const newValue = Math.min(Math.max(filterMin, this.model), filterMax)

        this.setModelValue(newValue)
        this.applyModelToInputs()
        this.refreshGui()
    }
}
