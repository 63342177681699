import iconArrowDown from '@icon_templates/icon-arrow-down'

const templateButtonLoadMore = `
    <div class="wrapper-button flex-center test-to-remove">
        <button
            id="btn-load-retailer"
            class="button-load-more button-circle button-label-top"
        >
            <span class="circle">
                <span>${iconArrowDown}</span>
                <span>${iconArrowDown}</span>
            </span>
            <label class="button-label">
                <%= config.list.buttonLabel %>
            </label>
        </button>
    </div>
`
export default templateButtonLoadMore
