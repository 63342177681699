const isNodeExcluded = ({ excludes }, comparatorSlugs) => {
    return !!excludes.find((excludeSlug) => comparatorSlugs.includes(excludeSlug))
}


const isAnyNodeConditionFulfilled = ({ conditions }, comparatorSlugs) => {
    if (conditions.length < 1) return true
    return !!conditions.find((requiredSlug) => comparatorSlugs.includes(requiredSlug))
}

const filterForPermittedNodes = (nodes, selectedSlugs) => {
    return nodes.filter(node => {
        if (isNodeExcluded(node, selectedSlugs)) {
            return false
        }
        return isAnyNodeConditionFulfilled(node, selectedSlugs)
    })
}

const splitTextOptionIntoWords = ({ text }) => {
    const textFragments = text.replace('###', '###___###').split(' ')

    return textFragments.reduce((accumulator, value) => {
        if (value.includes('###___###')) {
            const parts = value.split('###')
            accumulator.push({ type: 'placeholder', parts })
            return accumulator
        }

        if (value.length > 0) {
            accumulator.push({ type: 'part', text: value })
            return accumulator
        }

        return accumulator
    }, [])
}

const splitChoice = (text, choice) => {
    const punctuation = text.replace('###', '')
    const choiceTexts = choice.text.split(' ')

    return choiceTexts.map((text, index) => {
        if (punctuation.length > 0 && index >= choiceTexts.length - 1) {
            const parts = [
                { type: 'bold', text },
                { type: 'punctuation', text: punctuation},
            ]
            return { type: 'wrapper', parts }
        } else {
            return { type: 'bold', text }
        }
    })
}

const splitTextOptionIntoWordsFilled = ({ text }, choice) => {
    const textFragments = text.split(' ')

    return textFragments.reduce((accumulator, value) => {
        if (value.includes('###')) {
            const parts = splitChoice(value, choice)
            return [...accumulator, ...parts]
        } else {
            accumulator.push({ type: 'part', text: value })
            return accumulator
        }
    }, [])
}

const getNodeRecursively = (nodes, selectedSlugs, index = -1) => {
    index = index + 1
    const node = nodes[index]

    if (!node) {
        // console.error('no node could be found: check config')
        return { node, index }
    }

    if (
        isNodeExcluded(node, selectedSlugs) === true ||
        isAnyNodeConditionFulfilled(node, selectedSlugs) === false
    ) {
        return getNodeRecursively(nodes, selectedSlugs, index)
    }

    return  { node, index }
}

const parseToMilliseconds = (string) => {
    if (string.includes('ms')) {
        return Number(string.replace('ms',''))
    }

    if (string.includes('s')) {
        return Number(string.replace('s','')) * 1000
    }

    return 0
}

const timeoutPromise = (duration) => {
    return new Promise((resolve) => {
        setTimeout(resolve, duration)
    })
}

const requestAnimationFramePromise = () => {
    return new Promise((resolve) => {
        window.requestAnimationFrame(resolve)
    })
}

export {
    isNodeExcluded,
    isAnyNodeConditionFulfilled,
    splitTextOptionIntoWordsFilled,
    getNodeRecursively,
    parseToMilliseconds,
    timeoutPromise,
    splitTextOptionIntoWords,
    filterForPermittedNodes,
    requestAnimationFramePromise,
}
