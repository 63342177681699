const configuratorFeatureBlacklist = [    
    {
        // Roadster touring
        codePrefix: 'F00733',
        featureIDs: ['73'],
    },
    {
        // Roadster touring HS
        codePrefix: 'F00734',
        featureIDs: ['73'],
    },
    {
        // Roadster vario
        codePrefix: 'F00735',
        featureIDs: ['73'],
    },
    {
        // Roadster vario HS
        codePrefix: 'F00736',
        featureIDs: ['73'],
    },
    {
        // Roadster Mixte touring
        codePrefix: 'F00737',
        featureIDs: ['73'],
    },
    {
        // Roadster Mixte touring HS
        codePrefix: 'F00738',
        featureIDs: ['73'],
    },
    {
        // Roadster Mixte vario
        codePrefix: 'F00739',
        featureIDs: ['73'],
    },
    {
        // Roadster Mixte vario HS
        codePrefix: 'F00740',
        featureIDs: ['73'],
    },
    {
        // Charger 4 touring
        codePrefix: 'F00789',
        featureIDs: ['85', '73', '49'],
    },
    {
        // Charger 4 vario
        codePrefix: 'F00790',
        featureIDs: ['85', '73', '49'],  
    },
    {
        // Charger 4 GT touring
        codePrefix: 'F00791',
        featureIDs: ['85', '73', '49'],
    },
    {
        // Charger 4 GT vario
        codePrefix: 'F00792',
        featureIDs: ['85', '73', '49'],
    },
    /*
    {
        // Charger 4 Mixte touring
        codePrefix: 'F00793',
        featureIDs: ['85', '73'],
    },
    {
        // Charger 4 Mixte vario
        codePrefix: 'F00794',
        featureIDs: ['85', '73'],
    },
    {
        // Charger 4 Mixte GT touring
        codePrefix: 'F00795',
        featureIDs: ['85', '73'],
    },
    {
        // Charger 4 Mixte GT vario
        codePrefix: 'F00796',
        featureIDs: ['85', '73'],
    },
    */
    {
        // Multicharger GT touring 750
        codePrefix: 'F00798',
        featureIDs: ['85', '73'],
    },
    {
        // Multicharger GT vario 750
        codePrefix: 'F00800',
        featureIDs: ['85', '73'],
    },
    {
        // Multicharger Mixte GT light
        codePrefix: 'F00804',
        featureIDs: ['73'],
    },
    {
        // Multicharger Mixte GT touring 750
        codePrefix: 'F00805',
        featureIDs: ['85', '73'],
    },
    {
        // Multicharger Mixte GT touring HS
        codePrefix: 'F00806',
        featureIDs: ['73'],
    },
    {
        // Multicharger Mixte GT vario 750
        codePrefix: 'F00807',
        featureIDs: ['85', '73'],
    },
    {
        // Multicharger Mixte GT vario HS
        codePrefix: 'F00808',
        featureIDs: ['73'],
    },
    {
        // Multicharger Mixte GT rohloff
        codePrefix: 'F00809',
        featureIDs: ['73'],
    },
    {
        // Multicharger Mixte GT rohloff HS
        codePrefix: 'F00810',
        featureIDs: ['73'],
    },
    {
        // Delite GT touring
        codePrefix: 'F00816',
        featureIDs: ['73'],
    },
    {
        // Delite GT touring HS
        codePrefix: 'F00817',
        featureIDs: ['73'],
    },
    {
        // Delite GT vario
        codePrefix: 'F00818',
        featureIDs: ['73'],
    },
    {
        // Delite GT vario HS
        codePrefix: 'F00819',
        featureIDs: ['73'],
    },
    {
        // Delite GT rohloff
        codePrefix: 'F00820',
        featureIDs: ['73'],
    },
    {
        // Delite GT rohloff HS
        codePrefix: 'F00821',
        featureIDs: ['73'],
    },
    {
        // Delite mountain touring
        codePrefix: 'F00822',
        featureIDs: ['73'],
    },
    {
        // Delite mountain rohloff
        codePrefix: 'F00823',
        featureIDs: ['73'],
    },
    {
        // Multicharger Mixte GT touring
        codePrefix: 'F00839',
        featureIDs: ['73'],
    },
    {
        // Multicharger Mixte GT vario
        codePrefix: 'F00840',
        featureIDs: ['73'],
    },
    {
        // Packster 70 family
        codePrefix: 'F00847',
        featureIDs: ['26', '73', '70', '99', '44', '49'],
    },
    {
        // UBN Five touring
        codePrefix: 'F00870',
        featureIDs: ['26', '73'],
    },
    {
        // UBN Five silent
        codePrefix: 'F00871',
        featureIDs: ['26', '73'],
    },
    {
        // UBN Five singlespeed
        codePrefix: 'F00872',
        featureIDs: ['26', '73'],
    },
    {
        // UBN Seven touring
        codePrefix: 'F00873',
        featureIDs: ['26', '73'],
    },
    {
        // UBN Seven silent
        codePrefix: 'F00874',
        featureIDs: ['26', '73'],
    },
    {
        // UBN Seven singlespeed
        codePrefix: 'F00875',
        featureIDs: ['26', '73'],
    },
]

export { configuratorFeatureBlacklist }
