import { ga4Custom } from '~/app-modules/utils/global-imports'

const trackStartClicked = () => {
    ga4Custom('bikeadvisor_start')
}

const trackChoiceClicked = (slug) => {
    ga4Custom('bikeadvisor_answer_select', { selected_answer: slug })
}

const trackSelectionClicked = () => {
    ga4Custom('bikeadvisor_answer_redo')
}

const trackPlaceholderClicked = () => {
    ga4Custom('bikeadvisor_placeholder_click')
}

const trackBackClicked = () => {
    ga4Custom('bikeadvisor_back')
}

const trackBackRestartClicked = () => {
    ga4Custom('bikeadvisor_summary_back')
}

const trackRestartClicked = () => {
    ga4Custom('bikeadvisor_summary_restart')
}

const trackLinkBikeClicked = (url) => {
    ga4Custom('bikeadvisor_results_config')
}

const trackLinkSeriesClicked = (url) => {
    ga4Custom('bikeadvisor_results_series')
}

const trackCompleted = (slugs) => {
    ga4Custom('bikeadvisor_complete', { choices: slugs })
}

const trackSummaryViewed = () => {
    ga4Custom('bikeadvisor_summary_show')
}

const trackResultsViewed = () => {
    ga4Custom('bikeadvisor_reults_show')
}

export {
    trackStartClicked,
    trackChoiceClicked,
    trackSelectionClicked,
    trackPlaceholderClicked,
    trackBackClicked,
    trackBackRestartClicked,
    trackRestartClicked,
    trackLinkBikeClicked,
    trackLinkSeriesClicked,
    trackCompleted,
    trackSummaryViewed,
    trackResultsViewed,
}
