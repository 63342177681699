let vh = null
let previousWidth = window.innerWidth

const shouldUpdate = () => {
    if (vh === null) {
        return true
    }

    // large screens (desktop) always must be updated
    if (window.innerWidth >= 768) {
        return true
    }

    // small screens (mobile) only get updated in case of orientation-change
    if (window.innerWidth !== previousWidth) {
        return true
    }

    return false
}

const updateCssCustomProperty = () => {
    if (shouldUpdate()) {
        previousWidth = window.innerWidth
        vh = window.innerHeight * 0.01
        document.documentElement.style.setProperty('--vh', `${vh}px`)
    }
}

const addViewportHeightHelper = () => {
    updateCssCustomProperty()
    window.addEventListener('resize', updateCssCustomProperty)
}

export { addViewportHeightHelper }
