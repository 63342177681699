import T3ClassDecorator from './../t3-class-decorator'
import UrlSearchParamsHandler from '../../helpers/url-search-params-handler'
import { BIKE_CONDITION } from '../../constants/bike-condition-constants'
import { getT3Service } from '../../utils/t3-bridge'

const { findByKey } = getT3Service('product-detail-utils')

export default class BikeEquivalentTeaser extends T3ClassDecorator {

    static moduleName = 'bike-equivalent-teaser'

    constructor(context) {
        super(context)
        
        this.urlSearchParamsHandler = new UrlSearchParamsHandler()
        this.buttonProductOverview = this.module.querySelector('.button-product-overview')
    }

    init() {
        super.init()
        this.buttonProductOverview.href = this.createProductOverviewLink()
    }
    
    createProductOverviewLink() {
        const baseUrl = this.config.productOverviewUrl
        const series = findByKey(this.config.product.metadata, 'series')
        const condition_detail = this.getConditionsForEquivalents()

        return this.urlSearchParamsHandler.createUrlFromModel(baseUrl, {
            series,
            condition_detail,
        })
    }
    
    getConditionsForEquivalents() {
        const productVariant = this.config.product.variants[0]
        const productCondition = findByKey(productVariant.metadata, 'condition_detail')

        const {
            new: conditionsNewBikes,
            refurbished: conditionsRefurbishedBikes
        } = BIKE_CONDITION
        
        if (conditionsNewBikes.includes(productCondition)) {
            return conditionsRefurbishedBikes
        } else {
            return conditionsNewBikes
        }
    }
}
