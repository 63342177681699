import { axios } from '@utils/global-imports'
import type { LatLng, Address } from '@typedefs/base'
import T3ServiceClassDecorator from './t3-service-class-decorator'

export default class GeocodeService extends T3ServiceClassDecorator {

    static serviceName = 'geocode-service'

    private googleMapsLoader
    private browserService

    private geocoder?: google.maps.Geocoder

    public constructor(context) {
        super(context)
        this.googleMapsLoader = this.context.getService('google-maps-loader')
        this.browserService = this.context.getService('browser')
    }

    private async initGeocoder() {
        await this.googleMapsLoader.loadAsync()
        this.geocoder = new google.maps.Geocoder()
    }

    /**
     * get lat and lng for given address
     * using google geocoding api
     */
    public async getLocationByAddress(address?: string | null): Promise<LatLng> {
        if (!this.geocoder) {
            await this.initGeocoder()
        }

        if (!address) {
            return this.getLocation()
        }

        return new Promise((resolve, reject) => {
            this.geocoder!.geocode({ address }, (results, status) => {
                if (status === google.maps.GeocoderStatus.OK && results?.length) {
                    const location = results.shift()!.geometry.location
                    resolve({
                        lat: location.lat(),
                        lng: location.lng(),
                    })
                }
                reject()
            })
        })
    }

    /**
     * get users current location by browser
     * using navigator api
     */
    public async getLocationByBrowser(): Promise<LatLng> {
        return new Promise((resolve, reject) => {
            if (navigator && navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    position => resolve({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    }),
                    () => reject(),
                )
            } else {
                reject()
            }
        })
    }

    /**
     * get users current location by browser or by google geolocation api
     */
    public async getLocation() {
        if (this.browserService.isMobile()) {
            return this.getLocationByBrowser()
        }

        const apiKey = encodeURIComponent(this.context.getGlobalConfig('mapsAPIkey'))
        try {
            const response = await axios.post(`https://www.googleapis.com/geolocation/v1/geolocate?key=${apiKey}`)
            if (response.status === 200) {
                return response.data.location
            } else {
                return this.getLocationByBrowser()
            }
        } catch (error) {
            console.warn('geolocating failed', error)
            return this.getLocationByBrowser()
        }
    }

    /**
     * get city and country from lat lng
     * using google geocoding api
     */
    public async getAddressByLocation(location: LatLng): Promise<Address> {
        if (!this.geocoder) {
            await this.initGeocoder()
        }

        return new Promise((resolve, reject) => {
            this.geocoder!.geocode({ location }, (results, status) => {
                if (status === google.maps.GeocoderStatus.OK && results) {
                    const addressComponents = results.shift()!.address_components
                    const address: Address = {}

                    addressComponents.forEach(({ types, long_name }) => {
                        if (types.includes('locality')) {
                            address.city = long_name
                        } else if (types.includes('country')) {
                            address.country = long_name
                        }
                    })

                    if (address.city && address.country) {
                        resolve(address)
                    }
                }
                reject()
            })
        })
    }
}
