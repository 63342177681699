// start polyfills
import 'es7-object-polyfill' // todo: remove asap support is given
import 'regenerator-runtime/runtime.js' // todo: remove asap support is given
// end polyfills

import {
    registerT3ModuleClasses,
    exportAsT3Service,
    registerT3ServiceClasses,
    exportClassAsT3Service,
} from '@utils/t3-bridge'
import InputAddressAutocomplete from '@modules/retailer-search/input-address-autocomplete'
import ProductOverview from '@modules/product-overview/product-overview'
import ProductDetailTitle from '@modules/product-detail/product-detail-title'
import FamilyOverview from '@modules/bike-overview/family-overview'
import SeriesOverview from '@modules/bike-overview/series-overview'
import LanguageChooser from '@modules/language-chooser'
import YouTube from '@modules/you-tube'
import Dropdown from './partials/selects/dropdown'
import addValidatorT3Service from '@utils/t3-validator-service'
import iconTemplateBridge from '@utils/t3-icon-template-bridge'
import BikeAdvisor from '@modules/bike-advisor/bike-advisor'
import BikeEquivalentTeaser from '@modules/product-detail/bike-equivalent-teaser'
import Dialogue from '@modules/dialogue'
import Headline from '@modules/base/headline'
import Link from '@modules/base/link'
import RetailerCard from '@modules/retailer-search/partials/retailer-card'
import RetailerSearch from '@modules/retailer-search/retailer-search'
import RetailerSearchServiceHub from '@modules/retailer-search/retailer-search-service-hub'
import { addViewportHeightHelper } from '@helpers/viewport-height'
import { tryApplyInvertedGui } from '@utils/apply-inverted-gui'
import { initSentry } from '@utils/sentry'
import { hideBreadcrumb } from '@helpers/hide-breadcrumb'
import { removeFeaturesFromNavigation } from '@helpers/configurator-helper'
import CookieService from '@services/cookie-service'
import GeocodeService from '@services/geocode-service'
import LocationService from '@services/location-service'
import RetailerSearchStore from '@services/retailer-search-store'
import TopBar from '@modules/top-bar'

initSentry()

const services = [
    CookieService,
    GeocodeService,
    LocationService,
    RetailerSearchStore,
]
registerT3ServiceClasses(services)

const modules = [
    RetailerCard,
    RetailerSearch,
    RetailerSearchServiceHub,
    InputAddressAutocomplete,
    ProductDetailTitle,
    ProductOverview,
    SeriesOverview,
    FamilyOverview,
    LanguageChooser,
    YouTube,
    BikeAdvisor,
    BikeEquivalentTeaser,
    Headline,
    Dialogue,
    Link,
    TopBar,
]
registerT3ModuleClasses(modules)

exportClassAsT3Service('dropdown', Dropdown)
exportAsT3Service('try-apply-inverted-gui', tryApplyInvertedGui)
exportAsT3Service('hide-breadcrumb', hideBreadcrumb)
exportAsT3Service('remove-features-from-navigation', removeFeaturesFromNavigation)

addValidatorT3Service()
iconTemplateBridge()

addViewportHeightHelper()
