import { getSanitizedLanguage } from '@helpers/language'

const formatTimeString = (time: string): string => {
    const date = new Date(`2000-01-01T${time}`)
    const language = getSanitizedLanguage()
    
    if (language) {
        const formatLang = language.includes('en')
            ? 'en-ca'
            : language
        return date.toLocaleTimeString(formatLang, { timeStyle: 'short' })
    }
    const [hour, minute, second] = time.split(':')
    return `${hour}:${minute}`
}

export { formatTimeString }
