import { getT3Service } from '~/app-modules/utils/t3-bridge'
import { Constants } from '~/app-modules/utils/global-imports'

const EASING_FACTOR = 0.3
const FRAMES_PER_SECOND = 30
const TOP_BAR_HEIGHT = 56

const roundValue = (value, decimals = 1) => {
    const factor = Math.pow(10, decimals)
    return Math.round(value * factor) / factor
}

const approachValueToTarget = (actual, target, easing = EASING_FACTOR) => {
    return actual + (target - actual) * easing
}

const animatedValue = (initialValue, decimals = 1, easing = 0.3) => {
    let actualValue = initialValue
    let targetValue = initialValue
    let oldValue
    let hasChanged = false

    return {
        setTarget(value) {
            targetValue = roundValue(value, decimals)
        },
        animateFrame() {
            oldValue = actualValue
            actualValue = roundValue(approachValueToTarget(actualValue, targetValue, easing), decimals)
            hasChanged = actualValue !== oldValue
        },
        getValue() {
            return actualValue
        },
        hasChanged() {
            return hasChanged
        },
    }
}

export default class BikeAdvisorHeader {
    constructor(element) {
        this.element = element

        this.figureElement = this.element.querySelector('.bike-advisor__header-figure')
        this.isMobile = true
        this.browserService = getT3Service('browser')

        this.scale = animatedValue(1, 5, 0.4)
        this.translateY = animatedValue(0, 1, 0.3)

        this.checkIsMobile()
        this.addEventListeners()
    }

    addEventListeners() {
        // $(window).on('scroll', this.onScroll.bind(this))
        window.addEventListener('scroll', this.onScroll.bind(this))
        window.addEventListener('resize', this.onResize.bind(this))

        setInterval(this.onRenderInterval.bind(this), 1000 / FRAMES_PER_SECOND)
    }

    onScroll() {
        this.updateTargetValues()
    }

    onResize() {
        this.checkIsMobile()
        this.updateTargetValues()
    }

    checkIsMobile() {
        this.isMobile = this.browserService.getMediaQuery() === Constants.MQ.SMALL_ONLY
    }


    updateTargetValues() {
        this.scale.setTarget(this.targetScale)
        this.translateY.setTarget(this.targetTranslateY)
    }

    get scrollDistance() {
        const offset = this.isMobile ? TOP_BAR_HEIGHT : 0
        const distance = this.isHeaderStillInViewport
            ? this.element.getBoundingClientRect().top
            : this.element.offsetHeight * -1

        return distance - offset
    }

    get isHeaderStillInViewport() {
        return this.element.getBoundingClientRect().bottom > 0
    }

    get targetScale() {
        return 1 + this.scrollDistance * this.scaleFactor
    }

    get targetTranslateY() {
        return this.scrollDistance * this.translateYFactor
    }

    get scaleFactor() {
        return this.isMobile ? -0.002 : -0.0002
    }

    get translateYFactor() {
        return this.isMobile ? 0.25 : 0.15
    }

    onRenderInterval() {
        this.scale.animateFrame()
        this.translateY.animateFrame()

        if (this.scale.hasChanged() || this.translateY.hasChanged()) {
            this.figureElement.style.transform = `translateY(${this.translateY.getValue()}px) scale(${this.scale.getValue()})`
        }
    }
}
