export const CONDITION_TOGGLE_FILTER_NAME = 'condition_detail'
export const CONDITION_TOGGLE_FILTER_ITEM_NEW = 'new'

export const getItemNamesRefurbished = (items) => {
    return items.filter(({ name }) => name !== CONDITION_TOGGLE_FILTER_ITEM_NEW).map(({ name }) => name)
}

export default class ConditionToggle {
    constructor(element, selectsConfig, context, callbackChange, callbackPreFetch) {
        this.element = element
        this.context = context
        this.callbackChange = callbackChange
        this.callbackPreFetch = callbackPreFetch
        this.buttonNew = null
        this.buttonRefurbished = null

        const conditionDetailConfig = this.extractConditionDetailConfig(selectsConfig)

        if (!conditionDetailConfig) {
            return
        }

        this.createDataByConfig(conditionDetailConfig)
        this.init()
    }

    extractConditionDetailConfig(filtersConfig) {
        return filtersConfig.find((filter) => filter && filter.name === CONDITION_TOGGLE_FILTER_NAME)
    }

    createDataByConfig(conditionDetailConfig) {
        this.data = {
            itemNamesRefurbished: getItemNamesRefurbished(conditionDetailConfig.items),
            config: conditionDetailConfig,
            model: {},
            active: {
                new: false,
                refurbished: false,
            },
            disabled: {
                new: false,
                refurbished: false,
            },
        }
    }

    init() {
        this.findElements()
        this.addEventListeners()
        this.showElements()
    }

    findElements() {
        this.buttonNew = this.element.querySelectorAll('.pill[name="condition-new"]')
        this.buttonRefurbished = this.element.querySelectorAll('.pill[name="condition-refurbished"]')
    }

    addEventListeners() {
        this.buttonNew.forEach((button) => {
            button.addEventListener('click', this.onButtonNewClicked.bind(this))
        })

        this.buttonRefurbished.forEach((button) => {
            button.addEventListener('click', this.onButtonRefurbishedClicked.bind(this))
        })
    }

    showElements() {
        const conditionToggles = this.element.querySelectorAll('.filter-bar__condition-toggle')
        conditionToggles.forEach((ct) => {
            ct.classList.remove('filter-bar__condition-toggle--hidden')
        })
    }

    setAndApplyModelAndConfig(model, config) {
        if (model) {
            this.data.model = model
        }

        if (config) {
            this.data.config = this.extractConditionDetailConfig(config)
        }

        this.updateButtonStatesByModel()
        this.updateGui()
    }

    updateButtonStatesByModel() {
        const items = this.data.model[CONDITION_TOGGLE_FILTER_NAME] ?? []

        if (items.length < 1) {
            this.data.active = { new: false, refurbished: false }
        } else {
            const newActive = items.includes(CONDITION_TOGGLE_FILTER_ITEM_NEW)
            const refurbishedActive = this.includesListAnyRefurbishedItem(items)

            this.data.active.new = newActive
            this.data.active.refurbished = refurbishedActive
        }

        const disabledNames = this.data.config.items.filter(({ disabled }) => disabled).map(({ name }) => name)

        if (disabledNames.length < 1) {
            this.data.disabled = { new: false, refurbished: false }
        } else {
            const newDisabled = disabledNames.includes(CONDITION_TOGGLE_FILTER_ITEM_NEW)
            const refurbishedDisabled = disabledNames.length >= this.data.itemNamesRefurbished.length // this.includesListAnyRefurbishedItem(disabledNames)

            this.data.disabled.new = newDisabled
            this.data.disabled.refurbished = refurbishedDisabled
        }
    }

    updateGui() {
        this.buttonNew.forEach((button) => {
            this.updateButton(button, this.data.active.new, this.data.disabled.new)
        })

        this.buttonRefurbished.forEach((button) => {
            this.updateButton(button, this.data.active.refurbished, this.data.disabled.refurbished)
        })
    }

    updateButton(element, active = false, disabled = false) {
        element.classList[active ? 'add' : 'remove']('pill--active')
        element.classList[(disabled && !active) ? 'add' : 'remove']('pill--disabled')
    }

    onButtonNewClicked() {
        const items = this.data.model[CONDITION_TOGGLE_FILTER_NAME] ?? []
        let newItems

        if (items.includes(CONDITION_TOGGLE_FILTER_ITEM_NEW)) {
            newItems = items.filter(item => item !== CONDITION_TOGGLE_FILTER_ITEM_NEW)
        } else {
            newItems = [...items, CONDITION_TOGGLE_FILTER_ITEM_NEW]
        }

        this.triggerCallback(newItems)
    }

    onButtonRefurbishedClicked() {
        const items = this.data.model[CONDITION_TOGGLE_FILTER_NAME] ?? []
        let newItems = items.includes(CONDITION_TOGGLE_FILTER_ITEM_NEW) ? [CONDITION_TOGGLE_FILTER_ITEM_NEW] : []

        if (!this.includesListAnyRefurbishedItem(items)) {
            newItems = [
                ...newItems,
                ...this.getSelectableRefurbishedItemNames()
            ]
        }

        this.triggerCallback(newItems)
    }

    getSelectableRefurbishedItemNames() {
        return this.data.config.items
            .filter(({ name, disabled }) => {
                return name !== CONDITION_TOGGLE_FILTER_ITEM_NEW && !disabled
            })
            .map(({ name }) => name)
    }

    triggerCallback(items = []) {
        this.callbackChange({ [CONDITION_TOGGLE_FILTER_NAME]: items })
    }

    includesListAnyRefurbishedItem(list) {
        return list.some(name => this.data.itemNamesRefurbished.includes(name))
    }
}

