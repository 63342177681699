export default class T3ServiceClassDecorator {
    
    protected context
    protected config
    
    constructor(context) {
        this.context = context
        this.config = context.getGlobalConfig()
    }
}
