import BaseBikeOverview from './base-bike-overview'
import {ga4Custom} from '../../utils/global-imports'

export default class SeriesOverview extends BaseBikeOverview {
    static moduleName = 'series-overview'

    sanitizeGrid = true

    constructor(context) {
        super(context)
    }

    getElements() {
        this.groupItems = this.module.querySelectorAll('[data-filter-item="group"]')
        this.photoItemsContainer = this.module.querySelector('.series-chooser__results-photo')
        this.listItemsContainer = this.module.querySelector('.series-chooser__results-list')
    }

    trackInputModelChanged(model) {
        ga4Custom('serieschooser_filter_apply', {
            features: model.features
        })
    }
}
