import { ga4Custom } from '~/app-modules/utils/global-imports'

const trackInputType = () => {
    ga4Custom('retailersearch_input_type')
}

const trackSearchSubmit = () => {
    ga4Custom('retailersearch_search_submit')
}

const trackShowMoreClick = () => {
    ga4Custom('retailersearch_showmore_click')
}

const trackSuggestFailed = () => {
    ga4Custom('retailersearch_suggest_fail')
}

const trackMapViewClick = () => {
    ga4Custom('retailersearch_mapview_click')
}

const trackListViewClick = () => {
    ga4Custom('retailersearch_listview_click')
}

const trackPinClick = () => {
    ga4Custom('retailersearch_pin_click')
}

export {
    trackInputType,
    trackSearchSubmit,
    trackShowMoreClick,
    trackSuggestFailed,
    trackMapViewClick,
    trackListViewClick,
    trackPinClick,
}
