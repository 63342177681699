import { getT3Application } from '@utils/t3-bridge.js'

const createFixedOverlay = (content: string): HTMLElement => {
    const fixedOverlay = document.createElement('div')
    fixedOverlay.classList.add('fixed-overlay-wrapper')
    fixedOverlay.innerHTML = content
    return fixedOverlay
}

const initOverlay = (overlay: HTMLElement, onClose?: Function) => {
    const closeButton = overlay.querySelector('[data-ref="button-close"]')
    closeButton?.addEventListener('click', () => {
        if (onClose) {
            onClose()
        } else {
            overlay.parentNode?.removeChild(overlay)
        }
    })
    
    const application = getT3Application()
    application.startAll(overlay)
}


export {
    createFixedOverlay,
    initOverlay,
}