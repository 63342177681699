import * as Sentry from '@sentry/browser'
import { getT3Application } from '~/app-modules/utils/t3-bridge'

const initSentry = () => {
    const application = getT3Application()
    const { sentryDsn } = application.getGlobalConfig()

    try {
        Sentry.init({ dns: sentryDsn })
    } catch (error) {
        console.warn('sentry not initialized')
    }
}

export {initSentry}
