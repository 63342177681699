import T3ClassDecorator from '@modules/t3-class-decorator'
import type { ProductVariant } from '@typedefs/product'

export default class ProductDetailTitle extends T3ClassDecorator {
    static moduleName = 'product-detail-title'

    private model
    private state: HTMLElement
    private condition: HTMLElement

    constructor(context) {
        super(context)

        this.model = this.context.getService('product-detail-model')
        this.state = this.module.querySelector('[data-ref="state"]')
        this.condition = this.module.querySelector('[data-ref="condition"]')
    }

    init() {
        const variant = this.model.getVariant()
        this.state.textContent = this.getState(variant)

        if (this.condition) {
            this.condition.textContent = this.getCondition(variant)
        }
    }

    private getState(variant: ProductVariant): string {
        const productState = variant.metadata.find(({key}) => key === 'state')
        return this.model.getTranslations().stateNames[productState?.value] ?? ''
    }

    private getCondition(variant: ProductVariant): string {
        const productCondition = variant.metadata.find(({key}) => key === 'condition_detail')
        return this.model.getTranslations().conditionNames[productCondition?.value] ?? ''
    }
}
