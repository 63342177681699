/*global _ */

/*
    BASE COMPONENT MAY NOT WORK STANDALONE, MUST BE EXTENDED
 */
export default class BaseComponent {

    static type = 'select-range-single'

    constructor() {
        this.config = {}
        this.parsedTemplate = null
        this.templateElement = null

        this.rootElement = null
    }

    init() {
        this.render()
    }

    render(onRenderedCallack = this.onRendered.bind(this)) {
        this.fillTemplateString()
        this.createTemplateElement()
        this.fillTemplateStringElement()
        this.setRootElement()

        onRenderedCallack()
    }

    onRendered() {}

    get templateString() {
        return ''
    }

    get html() {
        return this.templateElement.content
    }

    setRootElement() {
        this.rootElement = this.templateElement.content.querySelector('*')
    }

    remove() {
        this.rootElement.remove()
    }

    fillTemplateString() {
        const underscoreTemplate = _.template(this.templateString)
        this.parsedTemplate = underscoreTemplate(this.config)
    }

    createTemplateElement() {
        this.templateElement = document.createElement('template')
    }

    fillTemplateStringElement() {
        this.templateElement.innerHTML = this.parsedTemplate
    }

    get name() {
        return this.config.name
    }
}
