export default class CountResults {
    constructor(html) {
        this.html = html

        this.init()
    }

    init() {}

    setCount(count) {
        this.html.innerHTML = count
    }
}
