export default class T3ClassDecorator {
    constructor(context) {
        this.context = context
        this.module = context.getElement()
        this.config = context.getConfig()
    }

    publicT3Methods() {
        return {
            behaviors: this.constructor.behaviors || [],
            messages: this.constructor.messages || [],
            onmessage: this.onmessage.bind(this),
            onclick: this.onclick.bind(this),
            init: this.init.bind(this),
            destroy: this.destroy.bind(this),
        }
    }

    publicT3Attributes() {
        return {
            module: this.module,
            config: this.config,
        }
    }

    onmessage(name, data) {}
    init() {}
    onclick() {}
    destroy() {}
}
