import SelectOptions from './select-options'

export default class SelectOptionsSingle extends SelectOptions {

    static type = 'select-options-single'

    constructor(config, callback) {
        super(config, callback)
    }

    callHookCheckboxChanged(name, checked) {
        this.uncheckOtherCheckboxes(name)
        this.resetModel()
    }

    uncheckOtherCheckboxes(nameSelected) {
        this.checkboxes.forEach(checkbox => {
            const { name } = checkbox

            if (name !== nameSelected) {
                checkbox.checked = false
            }
        })
    }

    setModelValue(name, checked) {
        this.model = (checked)? name : ''
    }

    resetModel() {
        this.model = ''
    }
}
