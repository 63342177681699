import Broadcasts from '~/app/Broadcasts'
import T3ClassDecorator from '@modules/t3-class-decorator'
import LocationService from '@services/location-service'
import RetailerSearchStore from '@services/retailer-search-store'
import { trackSearchSubmit } from './retailer-search-tracking'
import RetailerSearchList from './partials/retailer-search-list'
import templateRetailerRadios from './partials/templates/template-retailer-radios'

export default class RetailerSearchServiceHub extends T3ClassDecorator {
    static moduleName = 'retailer-search-service-hub'
    static behaviors = ['track-in-viewport']
    static messages = [Broadcasts.RETAILER_SEARCH_SUBMIT]

    private list: RetailerSearchList

    private readonly store: RetailerSearchStore
    private readonly locationService: LocationService

    constructor(context) {
        super(context)

        this.store = this.context.getService('retailer-search-store')
        this.locationService = this.context.getService('location-service')

        this.list = new RetailerSearchList(
            this.module.querySelector('[data-ref="list-view"]'),
            templateRetailerRadios,
            this.config,
        )
    }

    async init() {
        this.initFilter()
        if (!this.store.isLocationSet) {
            await this.setDefaultInputValue()
        }
    }

    async onmessage(name) {
        if (name === Broadcasts.RETAILER_SEARCH_SUBMIT) {
            await this.onSearchSubmitted()
        }
    }

    private initFilter() {
        const { serviceHubs } = this.config.filter
        const filter = {
            cnc: true,
            no_prio: true,
        }
        serviceHubs.forEach((hub) => {
            // ecargo is called cargo in serviceHubs
            hub = hub === 'ecargo' ? 'cargo' : hub
            filter[`service_hub_${hub}`] = true
        })
        this.store.setFilter(filter)
    }

    private async onSearchSubmitted() {
        trackSearchSubmit()
        await this.list.onSearchSubmit()
    }

    private async setDefaultInputValue() {
        const location = await this.locationService.getUserLocation()
        this.store.setLocation(location)
        this.context.application.broadcast(Broadcasts.RETAILER_SEARCH_SUBMIT)
    }
}
