import ResultsRenderer from '../../partials/content/results-renderer'
import templateCardBike from './templates/template-card-bike'
import templateCardService from './templates/template-card-service'
import Translator from '@helpers/translator'
import { colorCircle } from '../../partials/templates/color-circle'
import { subscriptionInterval } from '../../constants/subscription-interval-constants'

const templateOutput = `
    <div class='grid grid--gutter-vertical-large'>
        <% _.each( products, function( product, index ) { %>
        <% if (product.productType.name === 'Bikes') { %>
        ${templateCardBike}
        <% } else if (product.productType.name === 'Services') { %>
        ${templateCardService}
        <% } %>
        <% }); %>
    </div>
`

export default class ProductOverviewOutput extends ResultsRenderer {
    constructor(container, mask, translations, productBaseUrl) {
        super(container, mask)

        this.translations = translations
        this.template = templateOutput
        this.productBaseUrl = productBaseUrl
        this.translator = new Translator(this.translations.productCards)
    }

    getFrameColorFromVariant(variant) {
        const attributeColor = variant.attributes.find(attribute => attribute.attribute.slug === 'framecolor')
        return attributeColor?.value.value
    }

    getColorsFromVariants(variants) {
        const colors = variants.map(variant => {
            return this.getFrameColorFromVariant(variant)
        })

        return [...new Set(colors)]
    }

    getLinkFromResult({ slug }) {
        return `${this.productBaseUrl}${slug}/`
    }

    getCheapestVariant(variants) {
        return variants.reduce((prev, curr) =>
            prev.price.amount < curr.price.amount
                ? prev
                : curr,
        )
    }

    getPriceCheapestFromVariants(variants) {
        const cheapestVariant = this.getCheapestVariant(variants)
        return cheapestVariant.price.localized
    }

    getMixedPrices(variants) {
        const allPrices = variants.map(variant => variant.price.amount)
        return !allPrices.every(price => price === allPrices[0])
    }

    getOriginalPriceFromVariant(product, variant) {
        if (product.productType.name === 'Services') {
            const originalPrice = variant.metadata.find(data => data.key === 'original_price')
            return originalPrice ? originalPrice.value : null
        } else {
            const originalPrice = variant.originalPrice.localized
            return originalPrice ? originalPrice : null
        }
    }

    getStateFromVariants(variants) {
        const metadata = variants[0].metadata
        const state = metadata.find(data => data.key === 'state') || {}
        if (state.value) {
            return this.translations.stateNames[state.value] ?? null
        }
        return null
    }

    getConditionFromVariants(variants) {
        const metadata = variants[0].metadata
        const state = metadata.find(data => data.key === 'condition') || {}
        return state.value ?? null
    }

    getConditionDetailFromVariants(variants) {
        const metadata = variants[0].metadata
        const state = metadata.find(data => data.key === 'condition_detail') || {}
        if (state.value) {
            return this.translations.conditionNames[state.value] ?? null
        }
        return null
    }

    getPriceOverrideFromVariants(product, variants) {
        if (variants.length === 1) {
            return this.getOriginalPriceFromVariant(product, variants[0])
        } else {
            return this.getOriginalPriceFromVariant(product, this.getCheapestVariant(variants))
        }
    }

    getTranslationsFromResult(product, variants) {
        if (product.productType.name === 'Services') {
            return this.getTranslationsForCardService(product, variants)
        }

        if (product.productType.name === 'Bikes') {
            return {}
        }
    }

    getTranslationsForCardService(product, variants) {
        const price = product.availability.priceRange.start.gross.localized

        return {
            availableVariants: this.translator.translate('availableVariants', {
                variants: variants.length,
            }),
            price: this.translator.translate('price', { price }),
            priceFrom: this.translator.translate('priceFrom', { price }),
        }
    }

    getDefaultImage(product, variants) {
        const images = variants[0].images.length
            ? variants[0].images
            : product.images

        if (images.length) {
            return images[0]
        }
    }

    parseResults(results) {
        return results.map(product => {
            const availableVariants = product.variants.filter(variant => variant.stockQuantity)

            const priceCheapest = this.getPriceCheapestFromVariants(availableVariants)
            const priceOverride = this.getPriceOverrideFromVariants(product, availableVariants)

            product.link = this.getLinkFromResult(product)
            product.dynamicTranslations = this.getTranslationsFromResult(product, availableVariants)

            product.priceCheapest = priceCheapest
            product.priceOverride = priceCheapest === priceOverride ? null : priceOverride
            product.mixedPrices = this.getMixedPrices(availableVariants)

            product.colors = this.getColorsFromVariants(availableVariants)
            product.defaultImage = this.getDefaultImage(product, availableVariants)

            product.state = this.getStateFromVariants(availableVariants)
            product.condition = this.getConditionFromVariants(availableVariants)
            product.conditionDetail = this.getConditionDetailFromVariants(availableVariants)

            return product
        })
    }

    async showResults(results) {
        const parsedResults = this.parseResults(results)
        const mappedResults = {
            products: parsedResults,
            staticTranslations: this.translations.productCards,
            colorCircle: colorCircle,
            subscriptionInterval: subscriptionInterval,
        }
        super.showResults(mappedResults)
    }
}
