import iconArrowRight from '@icon_templates/icon-arrow-right'
import iconArrowLeft from '@icon_templates/icon-arrow-left'

const templateRetailerCard = `
    <div class='retailer-card container-text' data-module='retailer-card'>
        <script type='text/x-config'>
            {
                "id": <%= retailer.id %>,
                "testbikes": <%= JSON.stringify(retailer.testbikes) %>,
                "translations": {
                    "expand": "<%= config.testbikes.expand %>"
                }
            }
        </script>
        <div class='retailer-info active text-spaces' data-ref='retailer-info-card'>
            <p class='dateline addition'><%= retailer.distanceLocalized %></p>
            <h4><%= retailer.name %></h4>

            <div class='container-tags'>
                <% _.each(retailer.tags, function(tag, index) { %>
                    <mark class='tag <%= tag.tag %>'>
                        <%= tag.translation %>
                    </mark>
                <% }) %>
            </div>

            <p class='street-1 addition'>
                <%= retailer.street %><br>
                <%= retailer.cityLocalized %>
            </p>

            <p class='opening-hours ignore-addition'>
                <% if (retailer.isClosed) { %>
                    <% if (retailer.onHoliday) { %>
                        <b><%= config.openings.closed.holiday %></b>
                    <% } else { %>
                        <b><%= config.openings.closed.today %></b>
                    <% } %>
                <% } else { %>
                    <b><%= config.openings.openToday %></b>
                    <br />
                    <%= retailer.openingHours %> <%= config.openings.hour %>
                <% } %>
            </p>

            <p>
                <b><%= config.testbikes.headline %></b>
                <br />
                <span class='testbikes-teaser' data-ref='retailer-testbikes-teaser'>
                    <span class='testbikes-teaser__wrapper'><%= config.testbikes.empty %></span>
                </span>
            </p>
        </div>

        <% if (retailer.testbikes.length) { %>
            <div class='testbikes-detail' data-ref='retailer-testbikes-card'>
                <button data-ref='back-button' class='button-back'>
                    <span>${iconArrowLeft}</span>
                    <b><%= config.testbikes.back %></b>
                </button>
                <div class='text-spaces'>
                    <p>
                        <b><%= config.testbikes.headline %></b>
                        <br />
                        <span><%= retailer.testbikes.map(bike => bike.name).join(', ') %></span>
                    </p>
                </div>
            </div>
        <% } %>

        <div>
            <% if (retailer.testdrive) { %>
                <button
                    class='button-circle button-underline button-next btn-open-layer'
                    data-ref='testdrive-button'
                    data-display-type='link'
                >
                    <span class='circle'>
                        <span>${iconArrowRight}</span>
                        <span>${iconArrowRight}</span>
                    </span>
                    <label class='button-label'>
                        <%= config.testdrive.buttonLabel %>
                    </label>
                </button>
            <% } %>
            <a
                class='button-circle button-underline button-next button-to-retailer'
                data-display-type='link'
                href='<%= retailer.absolute_url %>'
            >
                <span class='circle'>
                    <span>${iconArrowRight}</span>
                    <span>${iconArrowRight}</span>
                </span>
                <label class='button-label'>
                    <%= config.detail.buttonLabel %>
                </label>
            </a>
        </div>
    </div>
`

export default templateRetailerCard
