import { getActualLanguage } from '@helpers/language'
import { formatTimeString } from '@helpers/time-formatter'
import { getCurrentDay, getCurrentWeekday, isCurrentDate } from '@helpers/date'
import { getLocalizedDistance } from '@helpers/distance'
import type { Retailer } from '@typedefs/retailer-search'

const parseOpeningHours = ({ opening_hours, holidays }, config) => {
    let openingHours = ''
    let isClosed = false
    let onHoliday = false

    if (!config.openings) {
        return {
            openingHours,
            isClosed,
            onHoliday,
        }
    }

    const parsedOpeningHours: Record<number, string[]> = JSON.parse(opening_hours)
    const parsedHolidays: string[] = JSON.parse(holidays)
    onHoliday = parsedHolidays?.some(isCurrentDate)

    const times = parsedOpeningHours[getCurrentDay()]

    if (times?.length) {
        openingHours = times.reduce((accumulator, [from, to], index) => {
            if (times.length > 1 && index > 0) {
                accumulator += ` ${config.openings.separator} `
            }
            accumulator += `${formatTimeString(from)} - ${formatTimeString(to)}`
            return accumulator
        }, '')
    } else {
        isClosed = true
    }

    return {
        openingHours,
        isClosed,
        onHoliday,
    }
}

const parseOpeningText = (config): string => {
    return config.openings.open[getCurrentWeekday()] ?? config.openings.open.today
}

const parseLocalizedCity = ({ zipcode, city }): string => {
    const language = getActualLanguage()
    if (language?.includes('en')) {
        return `${city} ${zipcode}`
    } else {
        return `${zipcode} ${city}`
    }
}

const parseRetailer = (retailer: Retailer, config) => {
    return {
        ...retailer,
        ...parseOpeningHours(retailer, config),
        cityLocalized: parseLocalizedCity(retailer),
        distanceLocalized: retailer.distance ? getLocalizedDistance(retailer.distance) : '',
    }
}

const parseRetailerTemplateData = (retailer: Retailer, config) => {
    const parsedRetailer = parseRetailer(retailer, config)
    if (config.openings) {
        config.openings.openToday = parseOpeningText(config)
    }

    return {
        retailer: parsedRetailer,
        config,
    }
}

const parseRetailerListTemplateData = (retailer: Retailer[], config) => {
    const retailerList = retailer.map((retailer) => parseRetailer(retailer, config))
    if (config.openings) {
        config.openings.openToday = parseOpeningText(config)
    }

    return {
        retailerList,
        config,
    }
}

export {
    parseRetailer,
    parseRetailerTemplateData,
    parseRetailerListTemplateData,
}
