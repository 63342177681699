const colorNameHexCodeMap = {
    'black':        '#373433',
    'blue':         '#0285cb',
    'gray':         '#a8a5a4',
    'red':          '#a02d0f',
    'turquoise':    '#a4ccc0',
    'white':        '#f8f8f8',
    'yellow':       '#d9a40b',
    'green':        '#23af39',
}

const colorOrderArray = [
    'black',
    'gray',
    'white',
    'yellow',
    'red',
    'blue',
    'turquoise',
    'green',
]


export { colorNameHexCodeMap, colorOrderArray }
