import BaseComponent from '@partials/selects/base-component'
import {splitTextOptionIntoWords, timeoutPromise} from '@modules/bike-advisor/bike-advisor-helper'
import {addAnimationClass, getDurationFromCustomProperty} from '@modules/bike-advisor/aimation-helper'
import {toggleElementClass} from '~/app-modules/utils/html-element-utils'
import {getNodeRecursively} from '../bike-advisor-helper'

const spacer = (text) => `<span class="sentence__placeholder-spacer">${text}</span>`
const palceholderText = (text) => `<span class="sentence__placeholder-text">${text}</span>`

const sentencePart = (text) => {
    if (text.length > 0) {
        return `<span class="sentence__part">${text}</span>`
    } else {
        return ''
    }
}

const placeholderPart = (value) => {
    if (value === '___') {
        return '<span class="sentence__placeholder"></span>'
    } else {
        return sentencePart(value)
    }
}

const sentenceTemplate = ({type, text, parts}) => {
    if (type === 'placeholder') {
        return `<span class="sentence__wrapper">
                    ${parts.map(part => placeholderPart(part)).join('')}
                </span>`
    }

    if (type === 'part') {
        return sentencePart(text)
    }
}

const template = (data) => `
    ${data.sentenceParts.map(part => sentenceTemplate(part)).join('')}
`

export default class BikeAdvisorSentence extends BaseComponent {

    static type = 'bike-advisor-question'

    constructor(question, selectedSlugs, onPlaceholderClicked, onChoiceClicked) {
        super()

        const { node: textOption } = getNodeRecursively(question.text_options, selectedSlugs)
        this.question = question
        this.placeholderElement = null
        this.config = this.createConfig(textOption)
        this.onPlaceholderClicked = onPlaceholderClicked
        this.onChoiceClicked = onChoiceClicked
        this.selectedChoice = null
        this._durationFadeIn = null

        this.init()
    }

    setRootElement() {
        super.setRootElement()

        this.childElements = this.templateElement.content.querySelectorAll('.sentence__part, .sentence__wrapper')
        this.placeholderElement = this.templateElement.content.querySelector('.sentence__placeholder')
        this.animatedElements = this.templateElement.content.querySelectorAll('.sentence__part, .sentence__placeholder')
    }

    fillTemplateString() {
        this.parsedTemplate = template(this.config)
    }

    onRendered() {
        // this.placeholderElement = this.rootElement.querySelector('.sentence__placeholder')
        this.setSpacerToPlaceholder()
        this.addEventListenerToPlaceholder()
    }

    addEventListenerToPlaceholder() {
        this.placeholderElement.addEventListener('click', () => {
            if (this.selectedChoice === null && this.onPlaceholderClicked) {
                this.onPlaceholderClicked(this.question, this.selectedChoice)
            }

            if (this.selectedChoice !== null && this.onChoiceClicked) {
                this.onChoiceClicked(this.question, this.selectedChoice)
            }
        })
    }

    createConfig(textOption) {
        return {
            sentenceParts: splitTextOptionIntoWords(textOption),
            // sentenceParts: getSplittedTextOption(textOption),
        }
    }

    getShortestChoice(possibleChoices) {
        const texts =  possibleChoices.map(({text}) => text)
        return texts.reduce((accumulator, value) => {
            return value.length < accumulator.length ? value : accumulator
        }) || ''
    }

    previewChoice(choice) {
        this.setChoiceToPlaceholder(choice)
    }

    async setChoice(choice) {
        this.setSelectedChoice(choice)
        this.setChoiceToPlaceholder(choice)
        this.setPlaceholderToChosen(true)

        await timeoutPromise(400)
    }

    setSelectedChoice(choice) {
        this.selectedChoice = choice
    }

    removeChoice() {
        this.setSelectedChoice(null)
        this.setSpacerToPlaceholder()
        this.setPlaceholderToChosen(false)
    }

    setChoiceToPlaceholder({ text }) {
        this.setPlaceholderContent(text || '')
    }

    setSpacerToPlaceholder() {
        const content = spacer(this.getShortestChoice(this.question.choices))
        this.setPlaceholderContent(content)
    }

    setPlaceholderContent(content) {
        this.placeholderElement.innerHTML = palceholderText(content)
    }

    setPlaceholderToChosen(isChosen) {
        toggleElementClass(this.placeholderElement, 'sentence__placeholder--chosen', isChosen)
    }

    showInstant() {
        Array.from(this.animatedElements).map((element) => {
            toggleElementClass(element, 'show', true)
        })
    }

    fadeIn(delay = 0) {
        return Promise.all(
            Array.from(this.animatedElements).map((element, index) => {
                const elementDelay = delay + index * 150
                return addAnimationClass(element, 'fade-in', this.durationFade, elementDelay)
            })
        )
    }

    fadeOut(delay = 0, offset = 60) {
        return Promise.all(
            Array.from(this.animatedElements).map((element, index) => {
                const reversedIndex = this.animatedElements.length - index - 1
                const elementDelay = delay + reversedIndex * offset
                return addAnimationClass(element, 'fade-out', this.durationFade, elementDelay)
            })
        )
    }

    swapToAbsolutePosition() {
        for (let i = this.childElements.length - 1; i >= 0; i--) {
            const element = this.childElements[i]
            const top = element.offsetTop
            const left = element.offsetLeft

            element.style.position = 'absolute'
            element.style.top = `${top}px`
            element.style.left = `calc(${left}px + var(--sentence-whitespace) * -0.5)`
        }
    }

    fadeOutAndRemove(delay, stepwise = true) {
        return new Promise((resolve) => {
            const offset = stepwise ? 60 : 0
            this.fadeOut(delay, offset).then(() => {
                this.remove()
                resolve()
            })
        })
    }

    remove() {
        this.childElements.forEach(element => {
            if (element) {
                element.remove()
            }
        })
    }

    get durationFade() {
        if (!this._durationFadeIn) {
            this._durationFadeIn = getDurationFromCustomProperty(this.placeholderElement, '--duration-fade-in')
        }
        return this._durationFadeIn
    }

    get durationFadeOut() {
        if (!this._durationFadeOut) {
            this._durationFadeOut = getDurationFromCustomProperty(this.placeholderElement, '--duration-fade-out')
        }
        return this._durationFadeOut
    }
}
