/*global _ */

import { toggleElementClass } from '../../utils/html-element-utils'

export default class FilterBar {

    static heightTopBar = 56

    constructor(element) {
        this.element = element
        this.inner = null
        this.top = 0
        this.debounceResize = _.debounce(this.onDebouncedResize.bind(this), 500)

        this.init()
    }

    init() {
        this.findElements()
        this.readOutElementTop()
        this.addEventListener()
        this.updateStickyState()
    }

    findElements() {
        this.inner = this.element.querySelector('.filter-bar__inner')
    }

    addEventListener() {
        window.addEventListener('scroll', this.onWindowScroll.bind(this))
        window.addEventListener('resize', this.debounceResize)
    }

    onWindowScroll() {
        this.updateStickyState()
    }

    onDebouncedResize() {
        this.readOutElementTop()
    }

    updateStickyState() {
        const scrollTop = window.pageYOffset
        const shouldStick = scrollTop >= this.top - this.constructor.heightTopBar

        toggleElementClass(this.inner, 'filter-bar__inner--sticky', shouldStick)
    }

    readOutElementTop() {
        const moduleTop = this.element.getBoundingClientRect().top
        this.top = window.pageYOffset + moduleTop
    }
}

