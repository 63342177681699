import T3ClassDecorator from '@modules/t3-class-decorator'
import Translator from '@helpers/translator'
import Broadcasts from '~/app/Broadcasts'
import { Constants } from '~/app/Constants'
import { fetchNearestCities } from '@api/rest/retailer-search-api'
import CookieService from '@services/cookie-service'
import LocationService from '@services/location-service'

export default class Dialogue extends T3ClassDecorator {
    // todo: add tracking (was removed from click-handler due to a lot of refactoring)

    static moduleName = 'dialogue'
    static messages = [
        Broadcasts.COOKIES_ALL_ACCEPTED,
        Broadcasts.COOKIES_FUNCTIONAL_ACCEPTED,
    ]

    private readonly privacyHandler
    private readonly cookieService: CookieService
    private readonly locationService: LocationService
    private readonly translator: Translator

    private copy: HTMLElement
    private link: HTMLElement
    private linkLabel: HTMLElement

    constructor(context) {
        super(context)

        this.cookieService = this.context.getService('cookie-service')
        this.privacyHandler = this.context.getService('privacy-handler')
        this.locationService = this.context.getService('location-service')
        this.translator = new Translator(this.config.translations)

        this.copy = this.module.querySelector('[data-ref="copy"]')!
        this.link = this.module.querySelector('[data-ref="link"]')!
        this.linkLabel = this.link.querySelector('label')!
    }

    async onmessage(name: string) {
        if (name === Broadcasts.COOKIES_ALL_ACCEPTED || name === Broadcasts.COOKIES_FUNCTIONAL_ACCEPTED) {
            await this.init()
        }
    }

    async init() {
        if (!this.privacyHandler.getCookiesAccepted(Constants.COOKIES.FUNCTIONAL)) {
            return
        }

        if (this.config.hideCopy) {
            this.copy.parentElement?.removeChild(this.copy)
            return
        }

        const retailerCookie = this.cookieService.getCookieValue(Constants.COOKIES.RETAILER_ID)
        if (retailerCookie) {
            this.updateCopyWithRetailerInformation()
        } else {
            await this.updateCopyWithLocationInformation()
        }
    }

    private updateCopyWithRetailerInformation() {
        const { name, city, url } = this.cookieService.getRetailerFromCookies()

        if (name && city) {
            this.copy.innerText = this.translator.translate('retailerCopy', {
                retailer: name,
                city,
            })
        }

        if (url) {
            this.link.setAttribute('href', url)
            this.linkLabel.innerText = this.translator.translate('labelRetailerPage')
        }
    }

    private async updateCopyWithLocationInformation() {
        const location = await this.locationService.getUserLocation()
        this.cookieService.setLocationCookies(location)

        if (!location.city && !location.lat && !location.lng) {
            return
        }

        try {
            const [cityNext1, cityNext2] = await fetchNearestCities(location)
            this.copy.innerText = this.translator.translate('locationCopy', {
                city1: cityNext1,
                city2: cityNext2,
            })
        } catch (error) {
            console.warn('error while fetching nearest citites', error)
        }
    }
}
