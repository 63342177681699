import Broadcasts from '~/app/Broadcasts'
import InputSuggest, { InputSuggestOption } from '@partials/inputs/input-suggest'
import { fetchPlacesPredictions } from '@api/rest/places-autocomplete-api'
import type { AutocompletePrediction } from '@typedefs/autocomplete'
import RetailerSearchStore from '@services/retailer-search-store'
import { trackInputType } from './retailer-search-tracking'
import { debounce } from 'underscore'

export default class InputAddressAutocomplete extends InputSuggest {
    static moduleName = 'input-address-autocomplete'
    static messages = [Broadcasts.RETAILER_SEARCH_SUBMIT]

    private isTrackingEnabled: boolean

    private readonly store: RetailerSearchStore
    private readonly debounceAutocomplete: Function

    constructor(context) {
        super(context)

        this.store = this.context.getService('retailer-search-store')

        this.isTrackingEnabled = false

        this.debounceAutocomplete = debounce(this.getAutocompletionOptions.bind(this), 300)
    }

    onmessage(name: string) {
        if (name === Broadcasts.RETAILER_SEARCH_SUBMIT) {
            this.value = this.store.searchText
            this.toggleButtonResetVisibility()
        }
    }

    protected onInputChange() {
        super.onInputChange()

        if (this.isTrackingEnabled) {
            trackInputType()
            this.isTrackingEnabled = false
        }

        if (!this.store.googleApiAllowed) {
            this.store.setLocation({ city: this.value })
            return
        }

        this.debounceAutocomplete()
        this.toggleButtonResetVisibility()
    }
    
    private submitSearch() {
        const { city } = this.store.searchParams

        if (!city) {
            return
        }
        
        this.context.application.broadcast(Broadcasts.RETAILER_SEARCH_SUBMIT)
    }

    protected onFormSubmit(event?: Event) {
        event?.preventDefault()

        if (!this.store.googleApiAllowed) {
            this.submitSearch()
            return
        }

        if (!this.options.length) return

        const { city } = this.store.searchParams
        const noOptionSelected = this.value.length && !city

        if (noOptionSelected) {
            this.selectOptionByIndex(0)
        }

        this.hideResults()
        this.submitSearch()
        this.clearResults()
    }

    protected onResetClick(event: Event) {
        super.onResetClick(event)
        this.store.setLocation({
            city: '',
        })
        this.submitSearch()
    }

    protected updateSelectedOption(option: HTMLElement) {
        super.updateSelectedOption(option)

        this.store.setLocation({
            city: this.value,
        })
    }
    
    private async getAutocompletionOptions() {
        if (!this.store.googleApiAllowed) return
        if (this.value.length > 2) {
            this.fetchResults().then((predictions) => {
                this.renderResults(predictions)
                this.showResults()
            })
        } else {
            this.hideResults()
        }
    }

    private async fetchResults(): Promise<InputSuggestOption[]> {
        return fetchPlacesPredictions(this.value).then((predictions) => {
            return predictions.map((prediction) => {
                return {
                    value: prediction.description,
                    label: this.createLabelFromPrediction(prediction)
                }
            })
        })
    }

    private createLabelFromPrediction(predicton: AutocompletePrediction): string {
        const { description, matched_substrings: matches } = predicton
        const firstMatch = matches[0]
        const lastMatch = matches[matches.length - 1]
        
        let label = description.substring(0, firstMatch.offset)
        
        // hightlight matching letters in description
        matches.forEach(({ offset, length }, index) => {
            label += `<strong>${description.substr(offset, length)}</strong>`
            if (index < matches.length - 1) {
                label += description.substring(
                    matches[index].offset + matches[index].length,
                    matches[index + 1].offset,
                )
            }
        })
        label += description.substr(
            lastMatch.offset +
            lastMatch.length
        )
        return label
    }
}
