import T3ClassDecorator from '@modules/t3-class-decorator'
import Translator from '@helpers/translator'
import { TestbikeSeriesGroup } from '@typedefs/retailer-search'
import RetailerSearchStore from '@services/retailer-search-store'
import { debounce } from 'underscore'
import { toggleElementClass } from '@utils/html-element-utils'

const MAX_TESTBIKES = 5

export default class RetailerCard extends T3ClassDecorator {
    static moduleName = 'retailer-card'

    private testbikes: TestbikeSeriesGroup[]
    private lastWindowWidth: number

    private infoCard: HTMLElement
    private testbikesCard: HTMLElement | null
    private testbikeTeaser: HTMLElement
    private testbikeTextWrapper: HTMLElement | null
    private testDriveButton: HTMLElement | null

    private readonly translator: Translator
    private readonly store: RetailerSearchStore

    constructor(context) {
        super(context)

        this.infoCard = this.module.querySelector('[data-ref="retailer-info-card"]')!
        this.testbikesCard = this.module.querySelector('[data-ref="retailer-testbikes-card"]')
        this.testDriveButton = this.module.querySelector('[data-ref="testdrive-button"]')
        this.testbikeTeaser = this.module.querySelector('[data-ref="retailer-testbikes-teaser"]')!
        this.testbikeTextWrapper = this.testbikeTeaser.querySelector('span')!

        this.testbikes = this.config.testbikes

        this.translator = new Translator(this.config.translations)
        this.store = this.context.getService('retailer-search-store')
        this.lastWindowWidth = window.innerWidth

        const backButton = this.module.querySelector('[data-ref="back-button"]')
        backButton?.addEventListener('click', this.closeDetailView.bind(this))

        const debounceResize = debounce(this.onResize.bind(this), 200)
        this.module.addEventListener('resize', debounceResize)
    }

    init() {
        this.updateTestdriveFormLink()
        this.updateTeaserText()
        this.initDetailLink()
        this.lockCardHeight()
    }

    get selectedTestbike(): TestbikeSeriesGroup | undefined {
        return this.testbikes.find((seriesGroup) => {
            return seriesGroup.id == this.store.filter.series_group_id
        })
    }

    private lockCardHeight() {
        const actualHeight = this.module.offsetHeight
        this.module.style.height = `${actualHeight}px`
    }

    private onResize() {
        this.module.style.height = 'auto'
        this.lockCardHeight()
    }

    private initDetailLink() {
        if (!this.testbikesCard) {
            return
        }

        const hiddenBikes = this.selectedTestbike
            ? this.testbikes.length - 1
            : this.testbikes.length - MAX_TESTBIKES

        if (hiddenBikes <= 0) {
            return
        }

        const detailLinkButton = document.createElement('button')
        detailLinkButton.addEventListener('click', this.openDetailView.bind(this))

        detailLinkButton.innerText = this.translator.translate('expand', {
            count: hiddenBikes,
        })

        this.testbikeTeaser.appendChild(detailLinkButton)
    }

    private updateTestdriveFormLink() {
        let url = `/api/forms/get_form_testdrive?retailer_id=${this.config.id}`
        const { series_group_id } = this.store.filter

        if (series_group_id) {
            url += `&series_group=${series_group_id}`
        }

        this.testDriveButton?.setAttribute('data-layer-url', url)
    }

    private updateTeaserText() {
        if (!this.testbikes.length || !this.testbikeTextWrapper) {
            return
        }
        this.testbikeTextWrapper.innerHTML = this.buildTestbikeString()
        toggleElementClass(
            this.testbikeTextWrapper,
            'testbikes-teaser__wrapper--exact-match',
            !!this.selectedTestbike,
        )
    }

    private buildTestbikeString(): string {
        if (this.selectedTestbike) {
            return this.selectedTestbike.name
        }

        return this.testbikes
            .slice(0, MAX_TESTBIKES)
            .map((bike) => bike.name)
            .join(', ')
    }

    private openDetailView() {
        this.infoCard.classList.remove('active')
        this.testbikesCard?.classList.add('active')
    }

    private closeDetailView() {
        this.testbikesCard?.classList.remove('active')
        this.infoCard.classList.add('active')
    }
}
