import axios from 'axios'
import type { Location } from '@typedefs/base'
import type {
    Paginated,
    Retailer,
    RetailerMapInfo,
    RetailerSearchParams,
} from '@typedefs/retailer-search'

const retailerBaseUrl = '/api/retailer/'

const createUrl = (endpoint: string, params: Record<string, any>) => {
    const urlParams = new URLSearchParams()

    Object.entries(params).forEach(([key, value]) => {
        const valuesArray = Array.isArray(value) ? value : [value]
        const urlParamArrayIdentifier = (Array.isArray(value)) ? '[]' : ''
        const urlParamName = `${key}${urlParamArrayIdentifier}`

        valuesArray.forEach(value => {
            if (value !== undefined) {
                urlParams.append(urlParamName, value)
            }
        })
    })

    return `${retailerBaseUrl}${endpoint}?${urlParams.toString()}`
}

const fetchLocations = async (params: RetailerSearchParams): Promise<Paginated<RetailerMapInfo>> => {
    const endpoint = createUrl('locations/', {
        format: 'json',
        limit: Number.MAX_SAFE_INTEGER,
        offset: 0,
        ...params,
    })

    return axios.get(endpoint).then(response => {
        if (response.status == 200) {
            return response.data
        }
        throw new Error(response.statusText)
    })
}

const fetchRetailer = async (params: RetailerSearchParams | Location & {
    id: string
}): Promise<Paginated<Retailer>> => {
    const endpoint = createUrl('find', {
        format: 'json',
        ...params,
    })

    return axios.get(endpoint).then(response => {
        if (response.status == 200) {
            return response.data
        }
        throw new Error(response.statusText)
    })
}

const fetchRetailerByUrl = async (url: string): Promise<Paginated<Retailer>> => {
    return axios.get(url).then(response => {
        if (response.status == 200) {
            return response.data
        }
        throw new Error(response.statusText)
    })
}

const fetchRetailerById = async (id: number): Promise<Retailer> => {
    const endpoint = `${retailerBaseUrl}${id}/`

    return axios.get(endpoint).then(response => {
        if (response.status == 200) {
            return response.data
        }
        throw new Error(response.statusText)
    })
}

const fetchTotalFilterResults = async (params: RetailerSearchParams): Promise<{
    results: number,
    name: string
}> => {
    const endpoint = createUrl('number-of-results', {
        format: 'json',
        ...params,
    })

    return axios.get(endpoint).then(response => {
        if (response.status == 200) {
            return response.data
        }
        throw new Error(response.statusText)
    })
}

const fetchNearestCities = async (params: Location): Promise<string[]> => {
    const endpoint = createUrl('nearest-cities', {
        format: 'json',
        ...params,
    })

    return axios.get(endpoint).then(response => {
        if (response.status == 200) {
            return response.data
        }
        throw new Error(response.statusText)
    })
}

export {
    fetchRetailer,
    fetchRetailerByUrl,
    fetchRetailerById,
    fetchLocations,
    fetchTotalFilterResults,
    fetchNearestCities,
}
