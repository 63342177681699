import Broadcasts from '../../app/Broadcasts'
import T3ClassDecorator from './t3-class-decorator'
import { toggleElementClass } from '~/app-modules/utils/html-element-utils'
import { TweenLite, Constants } from '~/app-modules/utils/global-imports'

export default class LanguageChooser extends T3ClassDecorator {

    static moduleName = 'language-chooser'
    static messages = [
        Broadcasts.Navigation.OPEN_LANGCHOOSER_REQUESTED,
        Broadcasts.Navigation.CLOSE_LANGCHOOSER_REQUESTED,
        Broadcasts.CustomSelect.OPENED,
    ]

    constructor(context) {
        super(context)

        this.fixedContentController = this.context.getService('fixed-content-controller')
        this.cookieService = this.context.getService('cookie-service')

        this.buttonSubmit = this.module.querySelector('button[type=submit]')
        this.wrapperLanguageSelect = this.module.querySelector('[data-ref="wrapper-language-select"]')
        this.wrapperMain = this.module.querySelector('[data-ref="wrapper-main"]')

        this.languageChooser = this.module.querySelector('#language')
        this.countryChooser = this.module.querySelector('#country')
        this.errorWrapper = this.module.querySelector('.errors')

        this.translatedUrls = this.context.getGlobalConfig('translatedUrls')
        this.activeLocale = this.context.getGlobalConfig('locale')

        this.locales = []
        this.countries = {}
        this.languages = {}

        this.transformLanguageData()
    }

    onmessage(name, data) {
        switch (name) {
            case Broadcasts.Navigation.OPEN_LANGCHOOSER_REQUESTED:
                this.openLanguageChooser()
                break
            case Broadcasts.Navigation.CLOSE_LANGCHOOSER_REQUESTED:
                this.closeLanguageChooser()
                break
            case Broadcasts.CustomSelect.OPENED:
                // only handle custom selects inside of language chooser module
                if (this.module.querySelectorAll(`#${ data.id }`).length)
                    this.openDropdown(data)

                break
        }
    }

    get activeLanguageCode() {
        return this.activeLocale?.substr(0, 2)
    }

    get activeCountryCode() {
        return this.activeLocale?.substr(this.activeLocale.length - 2)
    }

    openLanguageChooser() {
        this.selectedCountry = this.activeCountryCode
        this.selectedLanguage = this.activeLanguageCode

        this.transformLanguageData()
        this.createSelectOptions(this.countryChooser, this.countries, this.selectedCountry)
        this.createSelectOptions(this.languageChooser, this.languages[this.selectedCountry], this.selectedLanguage)

        // show error message, when current page is not available for selected country-lang pair
        toggleElementClass(this.errorWrapper, 'display-none', this.isPageForSelectionAvailable())

        //Sprachen-Dropdown direkt enablen, wenn das Land mehrere Sprachen hat
        const hasMultipleLanguageOptions = Object.keys(this.languages[this.selectedCountry]).length > 1
        if (hasMultipleLanguageOptions) {
            this.wrapperLanguageSelect.classList.remove('disabled')
        }

        TweenLite.set(this.wrapperMain, { opacity: 0 })
        this.module.classList.add('active')

        window.requestAnimationFrame(() => {
            TweenLite.to(this.wrapperMain, 0.7, {
                delay: 0.45,
                opacity: 1,
                ease: Quint.easeOut,
            })
            this.addEventListener()
        })
    }

    closeLanguageChooser() {
        this.cookieService.createCookie(Constants.COOKIES.ACCEPTED_MARKET, this.activeLocale)
        this.removeEventListener()

        TweenLite.to(this.wrapperMain, 0.4, {
            opacity: 0,
            ease: Constants.EASE_IN,
            onComplete: () => this.module.classList.remove('active'),
        })
    }

    openDropdown({ id }) {
        TweenLite.to(this.module.querySelector('.wrapper-vertical'), 0.65, {
            delay: 0.0,
            scrollTo: {
                y: this.module.querySelector('.wrapper-custom-select').offsetTop + 10,
                autoKill: false,
            },
            ease: Constants.EASE_IN_OUT,
        })
    }

    addEventListener() {
        this.module.addEventListener('click', this.onModuleClicked.bind(this))
        this.buttonSubmit.addEventListener('click', this.onSubmit.bind(this))
        this.countryChooser.addEventListener('change', this.onCountryChange.bind(this))
        this.languageChooser.addEventListener('change', this.onLanguageChange.bind(this))
    }

    removeEventListener() {
        this.module.removeEventListener('click', this.onModuleClicked.bind(this))
        this.buttonSubmit.removeEventListener('click', this.onSubmit.bind(this))
        this.countryChooser.removeEventListener('change', this.onCountryChange.bind(this))
        this.languageChooser.removeEventListener('change', this.onLanguageChange.bind(this))
    }

    onModuleClicked(event) {
        const elementClass = event.target.getAttribute('class')

        if (!elementClass) {
            return
        }

        if (
            elementClass.includes('overlay-base-wrapper')
            || elementClass.includes('overlay-wrapper-horizontal')
            || elementClass.includes('overlay-btn-close')
        ) {
            this.fixedContentController.onCloseFixedContentClicked()
        }
    }

    onCountryChange(event) {
        this.selectedCountry = event.target.value
        const languages = this.languages[this.selectedCountry]
        this.selectedLanguage = languages[Object.keys(languages)[0]]

        this.createSelectOptions(this.languageChooser, languages, this.selectedLanguage)
        console.log(this.selectedLanguage)

        // zweites Dropdown nur enablen, wenn mehr als eine Sprache wählbar ist
        const hasSingleLanguageOptions = Object.keys(languages).length === 1
        toggleElementClass(this.wrapperLanguageSelect, 'disabled', hasSingleLanguageOptions)

        toggleElementClass(this.errorWrapper, 'display-none', this.isPageForSelectionAvailable())
    }

    isPageForSelectionAvailable() {
        // get actual url and url, that fits to selected country-lang pair
        let url = this.translatedUrls[this.activeLocale]
        let selectedUrl = this.translatedUrls[this.getSantizedLocale()]

        // sanitize malformed urls rendered by django
        url = url.replace('//', '/')
        selectedUrl = selectedUrl.replace('//', '/')

        // count appearances of / strings
        // differing count indicates more url-segments
        // so url with less segments leads to parent page
        return (url.match(/\//g) || []).length === (selectedUrl.match(/\//g) || []).length
    }

    getSantizedLocale() {
        return this.locales.find((locale) => {
            return (
                locale === `${this.selectedLanguage}-${this.selectedCountry}`
                || locale.includes(this.selectedCountry) && locale.includes(this.selectedLanguage) && this.selectedCountry !== this.selectedLanguage
                || this.selectedLanguage === this.selectedCountry && locale === this.selectedLanguage
            )
        })
    }


    onLanguageChange(event) {
        this.selectedLanguage = event.target.value
        this.languageChooser.parentElement.classList.add('filled')

        toggleElementClass(this.errorWrapper, 'display-none', this.isPageForSelectionAvailable())
    }

    onSubmit(event) {
        event.preventDefault()

        if (!this.selectedCountry) {
            return
        }

        const activeSlug = `/${this.activeLocale}/${window.location.search}`
        const locale = this.getSantizedLocale()
        this.cookieService.createCookie(Constants.COOKIES.ACCEPTED_MARKET, locale)

        let href = `/${locale}/`

        if (this.translatedUrls[locale] && this.isPageForSelectionAvailable()) {
            href = this.translatedUrls[locale]
        }

        href += window.location.search

        if (href !== activeSlug) {
            this.cookieService.deleteCookie(Constants.COOKIES.PRECISION_LAYER)
            this.cookieService.deleteCookie(Constants.COOKIES.PRECISION_LAYER_SHOWN)
            this.context.broadcast(Broadcasts.HREF_CLICKED, { href })
        } else {
            this.fixedContentController.onCloseFixedContentClicked()
        }
    }

    createSelectOptions(optionsWrapper, values, selectedValue) {
        optionsWrapper.innerHTML = Object.entries(values)
            .map(([key, value]) => (`
                <option
                    class='option'
                    value='${value}'
                    ${value === selectedValue ? 'selected="selected"' : ''}
                >${key}</option>
            `))
            .join()

        const inputId = optionsWrapper.parentElement.getAttribute('id')
        this.context.broadcast(Broadcasts.CustomSelect.FORCE_POPULATE, { id: inputId })
    }

    transformLanguageData() {
        const availableLanguages = this.context.getGlobalConfig('availableLanguages')

        availableLanguages.forEach(([locale, countryCode, languageCode, country, language]) => {
            this.countries[country] = countryCode

            this.languages[countryCode] = {
                ...this.languages[countryCode] ?? {},
                [language]: languageCode,
            }

            this.locales.push(locale)
        })
    }
}
