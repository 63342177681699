export default class FilterQueryMapParser {
    constructor() {
        this.typeMethodMap = {
            'select-colors': this.parseFilterOptions.bind(this),
            'select-options-single': this.parseFilterOptions.bind(this),
            'select-options-multi': this.parseFilterOptions.bind(this),
            'select-range': this.parseFilterRange.bind(this),
            'select-range-single': this.parseFilterRangeSingle.bind(this),
        }

        this.filterQueryMap = {}
    }

    parseFilterOptions(filter) {
        const { name, parent } = filter
        this.filterQueryMap[name] = { parent, name, isParentArray: true }
    }

    parseFilterRange(filter) {
        const { name } = filter
        this.filterQueryMap[`${name}-min`] = { parent: name, name: 'min', type: Number }
        this.filterQueryMap[`${name}-max`] = { parent: name, name: 'max', type: Number }
    }

    parseFilterRangeSingle(filter) {
        const { name } = filter
        this.filterQueryMap[name] = { parent: null, name, type: Number }
    }

    parseFilter(filter) {
        const method = this.typeMethodMap[filter.type]
        if (method) {
            method(filter)
        }
    }

    getQueryMapFromFilters(filters) {
        this.filterQueryMap = {}

        filters.forEach(filter => {
            this.parseFilter(filter)
        })

        return this.filterQueryMap
    }
}
