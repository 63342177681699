import { getT3Application } from '@utils/t3-bridge'

const languageMap: Partial<Record<LanguageCode, LanguageCode>> = {
    'fr-ca': 'fr',
}

type LanguageCode = 
    'de-de'
    | 'de-at' 
    | 'de-ch' 
    | 'es-es' 
    | 'fr' 
    | 'fr-be' 
    | 'fr-ch' 
    | 'fr-ca' 
    | 'nl' 
    | 'nl-be' 
    | 'it' 
    | 'it-ch' 
    | 'en-en' 
    | 'en-us' 
    | 'en-ca' 
    | 'en-gb' 
    | 'en-fi' 
    | 'en-se' 
    | 'en-dk' 
    | 'en-no' 
    | 'en-jp' 
    | 'en-au' 
    | 'en-nz' 
    | 'en-pt'

const getActualLanguage = (): LanguageCode | undefined => {
    const t3Application = getT3Application()

    if (t3Application) {
        return t3Application.getGlobalConfig().locale as LanguageCode
    }
}

/*
 * get sanizited language for retailer-search and localTimeString
 */
const getSanitizedLanguage = (): LanguageCode | undefined => {
    const language = getActualLanguage()
    if (language) {
        return languageMap[language] ?? language
    }
}

export {
    LanguageCode,
    getActualLanguage,
    getSanitizedLanguage,
}
