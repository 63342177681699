import Broadcasts from '../../app/Broadcasts'
import T3ClassDecorator from './t3-class-decorator'

export default class YouTube extends T3ClassDecorator {

    static moduleName = 'm60-youtube'
    static messages = [
        Broadcasts.SECTIONS_READY,
    ]

    constructor(context) {
        super(context)

        this.initialized = false
        this.buttonCta = this.module.querySelector('.youtube__cta')
        this.baseSrc = 'https://www.youtube-nocookie.com/embed/'
        this.youtubeConfig = {
            autoplay: 0,
            showinfo: 0,
            rel: 0,
            color: 'white',
            modestbranding: 1,
        }
    }

    init () {
        this._init()
    }

    onmessage(name) {
        if (name === Broadcasts.SECTIONS_READY) {
            this._init()
        }
    }

    _init() {
        if (this.initialized)
            return

        this.buttonCta.addEventListener('click', this.onCtaClick.bind(this))
    }

    onCtaClick() {
        this.buttonCta.classList.add('youtube__cta--hidden')

        const params = new URLSearchParams(this.youtubeConfig)
        const src = `${this.baseSrc}${this.config.youtubeID}?${params.toString()}`
        const iframe = this.module.querySelector('.youtube__iframe')

        iframe.setAttribute('src', src)
    }
}
