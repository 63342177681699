import {colorCircle} from '@partials/templates/color-circle'
import {circleButton} from '@partials/templates/circle-button'
import {trackLinkBikeClicked, trackLinkSeriesClicked} from '../../bike-advisor-tracking'

window.trackLinkBikeClicked = trackLinkBikeClicked
window.trackLinkSeriesClicked = trackLinkSeriesClicked


const header = (bike) => `
    <div class="advised-bike__header">
        <div class="advised-bike__info dateline addition">${bike.label}</div>
        ${ bike.isRecommended ? `
            <h2 class="advised-bike__headline">${bike.translations.bikeNamePrefix} ${bike.name}</h2>
        ` : `
            <h3 class="advised-bike__headline">${bike.translations.bikeNamePrefix} ${bike.name}</h3>
        `}
    </div>
`

const media = (bike) => `
    <div class="advised-bike__media">
        ${ bike.images && bike.images[0] && `
            <div class="advised-bike__media-figure">
                <figure data-src="${bike.images[0].url}"></figure>
            </div>
        `}

        <p class="text-medium">${bike.translations.imageDisclaimer}</p>

        <div class="advised-bike__media-bottom">
            <div class="colors__container">
                ${bike.colors.map(color => colorCircle(color.hexcode)).join('')}
            </div>

            <mark class="advised-bike__price single-tag">${bike.translations.recommendedPrice} ${bike.price}</mark>
        </div>
    </div>
`

const info = (bike, headerSlot) => {
    const optionsBikeLink = {
        label: bike.translations.bikeLink,
        link: bike.links.bike,
        target: '_blank',
        order: 'label-right',
        className: ['advised-bike__bike-button', 'button-next'],
        onclick: `trackLinkBikeClicked('${bike.links.bike}')`
    }

    const optionsSeriesLink = {
        label: bike.translations.seriesLink,
        link: bike.links.series,
        target: '_blank',
        order: 'label-right',
        className: ['advised-bike__series-button', 'button-next'],
        onclick: `trackLinkSeriesClicked('${bike.links.series}')`
    }

    return `
        <div class="advised-bike__info">
            ${headerSlot ? headerSlot : ''}
            ${bike.isRecommended ? `<p class="bike-chooser__copy">${bike.translations.featuresPrefix} ${bike.description}</p>` : ''}

            ${bike.isRecommended ? `<p class="bike-chooser__cta">${bike.translations.bikeCta}</p>` : ''}
            ${circleButton(optionsBikeLink)}

            ${bike.isRecommended ? `<p class="bike-chooser__cta">${bike.seriesCTA}</p>` : ''}
            ${circleButton(optionsSeriesLink)}
        </div>
    `
}

const bikeResult = (bike, index) => `
    ${ bike.isRecommended === true ? `
        <div class="s-12 m-12 l-12">
            <div class="advised-bike" data-type="recommended">
                <div class="grid grid--gutter-vertical-default">
                    <div class="s-12 m-12 l-6">
                        ${media(bike)}
                    </div>
                    <div class="s-12 m-12 l-5 l-left-1">
                        ${info(bike, header(bike, index))}
                    </div>
                </div>
            </div>
        </div>
    ` : ''}

    ${ bike.isRecommended === false ? `
        <div class="${index === 1 ? 's-12 m-6 l-5 l-right-1' : 's-12 m-6 l-5 l-left-1' }">
            <div class="advised-bike" data-type="alternative">
                ${header(bike, index)}
                ${media(bike)}
                ${info(bike)}
            </div>
        </div>
    ` : ''}
`

const bikeResultsTemplate = (data) => `
    <div class="grid">
        ${data.results.map((result, index) => bikeResult(result, index)).join('')}
    </div>
`

export default bikeResultsTemplate
