import BaseComponent from '@partials/selects/base-component'
import {addAnimationClass, getDurationFromCustomProperty} from '@modules/bike-advisor/aimation-helper'
import {toggleElementClass} from '~/app-modules/utils/html-element-utils'
import frameIcon from './templates/frame-icon'

const template = (data) => `
    <div class='choice'>
        <div class='choice__inner'>
            ${data.text}
        </div>
        ${frameIcon(data.slug)}
    </div>
`

export default class BikeAdvisorChoice extends BaseComponent {

    static type = 'bike-advisor-answer'

    constructor(choice, onChange, onMouseOver, onMouseOut) {
        super()

        this.choice = choice
        this.config = choice // this.getConfigFromQuestion(choice)
        this.onChange = onChange
        this.onMouseOver = onMouseOver
        this.onMouseOut = onMouseOut

        this.init()
    }

    fillTemplateString() {
        this.parsedTemplate = template(this.config)
    }

    getConfigFromQuestion(choice) {
        return {
            choiceLabel: choice.text,
        }
    }

    onRendered() {
        this.addEventLister()
    }

    addEventLister() {
        this.rootElement.addEventListener('click', () => {
            if (this.onChange) {
                this.setChosen(true)
                this.onChange(this.choice)
            }
        })

        this.rootElement.addEventListener('mouseover', () => {
            if (this.onMouseOver) {
                this.onMouseOver(this.choice)
            }
        })

        this.rootElement.addEventListener('mouseout', () => {
            if (this.onMouseOut) {
                this.onMouseOut(this.choice)
            }
        })
    }

    setChosen(isChosen) {
        toggleElementClass(this.rootElement, 'choice--chosen', isChosen)
    }

    fadeIn(delay = 0) {
        return addAnimationClass(this.rootElement, 'choice--fade-in', this.durationFadeOut, delay)
    }

    fadeOut(delay = 0) {
        return addAnimationClass(this.rootElement, 'choice--fade-out', this.durationFadeOut, delay)
    }

    get durationFadeOut() {
        return getDurationFromCustomProperty(this.rootElement, '--duration-fade-out')
    }

    playPulseAnimation() {
        this.rootElement.classList.add('choice--pulse')

        setTimeout(() => {
            this.rootElement.classList.remove('choice--pulse')
        }, 275)
    }
}
