import T3ClassDecorator from '@modules/t3-class-decorator'
import { TweenLite, Cubic } from '@utils/global-imports'

export type InputSuggestOption = {
    label: string,
    value: string,
}

/**
 * @abstract
 * base class for input with suggestions,
 * implements base functionality like key navigation.
 * abstract method onFormSubmit must be overwritten by child class.
 */
export default class InputSuggest extends T3ClassDecorator {
    
    protected input: HTMLInputElement
    protected optionsWrapper: HTMLElement
    protected options: HTMLElement[]

    protected buttonSubmit: HTMLElement
    protected buttonReset: HTMLElement

    protected inputFocused: boolean
    protected contentExpanded: boolean

    protected selectedOption: HTMLElement | null

    protected constructor(context) {
        super(context)

        this.input = this.module.querySelector('input')
        this.optionsWrapper = this.module.querySelector('[data-ref="options-wrapper"]')
        
        this.buttonSubmit = this.module.querySelector('button[type="submit"]')
        this.buttonReset = this.module.querySelector('button[type="reset"]')
        
        this.options = []
        this.selectedOption = null
        this.inputFocused = false
        this.contentExpanded = false
    }

    protected get value(): string {
        return this.input.value
    }

    protected set value(value: string | null) {
        this.input.value = value ?? ''
    }
    
    init() {
        this.bindListener()
    }
    
    destroy() {
        super.destroy()
        this.input.removeEventListener('keydown', this.onNavigateListByKey.bind(this))
    }

    protected bindListener() {
        this.module.addEventListener('submit', this.onFormSubmit.bind(this))
        this.buttonSubmit.addEventListener('click', this.onFormSubmit.bind(this))
        this.buttonReset.addEventListener('click', this.onResetClick.bind(this))

        this.input.oninput = this.onInputChange.bind(this)
        this.input.addEventListener('keydown', this.onNavigateListByKey.bind(this))
        this.input.addEventListener('click', this.onInputFocus.bind(this))
        this.input.addEventListener('focusout', this.onInputFocusOut.bind(this))
    }

    protected onInputFocus() {
        if (!this.inputFocused) {
            this.input.focus()
        }
        this.inputFocused = true
    }

    protected onInputFocusOut() {
        this.inputFocused = false
    }

    protected onInputChange() {
        const isInputEmpty = this.value.length > 0
        this.input.classList[isInputEmpty ? 'remove' : 'add']('filled')
    }

    protected onBodyClick(event: MouseEvent) {
        const element = event.target as HTMLElement

        if (!element.closest('.input-search')) {
            this.hideResults()
        }
    }

    protected onNavigateListByMouse(option: HTMLElement) {
        this.updateSelectedOption(option)
        this.hideResults()
        this.onFormSubmit()
    }

    protected onNavigateListByKey(event: KeyboardEvent) {
        if (!this.contentExpanded) return

        if (event.keyCode === 40) {
            // arrow down
            if (this.selectedOption) {
                const currentIndex = this.options.indexOf(this.selectedOption)
                if (currentIndex >= 0 && currentIndex < this.options.length - 1) {
                    this.selectOptionByIndex(currentIndex + 1)
                } else {
                    this.selectOptionByIndex(0)
                }
            } else {
                this.selectOptionByIndex(0)
            }
        } else if (event.keyCode === 38) {
            // arrow up
            if (this.selectedOption) {
                const currentIndex = this.options.indexOf(this.selectedOption)
                if (currentIndex > 0 && currentIndex <= this.options.length - 1) {
                    this.selectOptionByIndex(currentIndex - 1)
                } else {
                    this.selectOptionByIndex(this.options.length - 1)
                }
            } else {
                this.selectOptionByIndex(this.options.length - 1)
            }
        }
    }

    protected onKeyUp(event: KeyboardEvent) {
        // escape
        if (event.keyCode == 27) {
            this.hideResults()
        }
    }

    protected onResetClick(event: Event) {
        event.preventDefault()

        this.value = ''
        this.input.focus()

        this.buttonReset.classList.add('hidden')
        this.module.classList.add('input-search--unfilled')
    }

    protected selectOptionByIndex(index: number) {
        const nextOption = this.options[index]
        this.selectedOption?.classList.remove('hover')
        nextOption?.classList.add('hover')
        this.updateSelectedOption(nextOption)
    }

    protected updateSelectedOption(option?: HTMLElement) {
        this.selectedOption = option ?? null
        const value = option?.getAttribute('data-description')
        this.value = value ?? ''
    }

    /**
     * open dropdown and add key and click listener
     */
    protected showResults() {
        if (this.contentExpanded) return

        document.body.addEventListener('click', this.onBodyClick.bind(this))
        document.addEventListener('keyup', this.onKeyUp.bind(this))
        this.module.classList.add('expanded')
        
        TweenLite.to(this.optionsWrapper, 0.2, {
            autoAlpha: 1,
            ease: Cubic.easeInOut
        })

        this.contentExpanded = true
    }

    /**
     * close dropdown, remove key and click listener and reset selected option
     */
    protected hideResults() {
        if (!this.contentExpanded) return

        document.body.removeEventListener('click', this.onBodyClick.bind(this))
        document.removeEventListener('keyup', this.onKeyUp.bind(this))
        this.module.classList.remove('expanded')

        TweenLite.to(this.optionsWrapper, 0.2, {
            autoAlpha: 0,
            ease: Cubic.easeIn
        })
        
        this.input.focus()

        if (this.selectedOption) {
            this.selectedOption.classList.remove('hover')
            this.selectedOption = null
        }

        this.contentExpanded = false
    }

    /**
     * remove dropdown options
     */
    protected clearResults() {
        this.optionsWrapper.innerHTML = ''
        this.options = []
    }
    
    /**
     * create dropdown options from values.
     * use in child class to render fetched results.
     */
    protected renderResults(options: InputSuggestOption[]) {
        this.clearResults()
        if (!options.length) {
            return
        }
        if (this.config.maxResults) {
            options = options.slice(0, this.config.maxResults)
        }
        this.options = options.map(({ label, value }) => {
            const option = document.createElement('div')
            option.classList.add('option')
            option.innerHTML = label
            option.setAttribute('data-description', value)
            return option
        })
        this.optionsWrapper.append(...this.options)
        this.options.forEach((option) => {
            option.addEventListener('click', () => this.onNavigateListByMouse(option))
        })
    }

    /**
     * hide resetButton if input is empty or show if there is any content to remove.
     * method should be called every time the input value has changed.
     */ 
    protected toggleButtonResetVisibility() {
        const isVisible = this.value.length > 0
        this.buttonReset.classList[isVisible ? 'remove' : 'add']('hidden')
        this.module.classList[isVisible ? 'remove' : 'add']('input-search--unfilled')
    }
    
    /**
     * @abstract
     * override in child class
     */
    protected onFormSubmit(event?: Event) {
        throw new Error('Method "onFormSubmit" must be implemented by clild class')
    }
    
}
