import SelectOptions from './select-options'
import SelectOptionsSingle from './select-options-single'
import SelectColors from './select-colors'
import SelectRange from './select-range'
import SelectRangeSingle from './select-range-single'
import extendSelectWithWrapper from './../../modules/product-overview/wrapper-select'

export default class SelectRenderer {

    static typeSelectClassMap = {
        'select-options-single': SelectOptionsSingle,
        'select-options-multi': SelectOptions,
        'select-colors': SelectColors,
        'select-range': SelectRange,
        'select-range-single': SelectRangeSingle,
    }

    constructor() {
        this.selectInstances = []
    }

    renderSelect(container, filter, callbackChange, callbackOpenWrapper) {
        const { type, wrapAccordion = true } = filter

        let selectClass = this.constructor.typeSelectClassMap[type]

        if (wrapAccordion === true) {
            selectClass = extendSelectWithWrapper(selectClass)
        }

        const selectInstance = new selectClass(filter, callbackChange, callbackOpenWrapper)
        selectInstance.init()
        const html = selectInstance.html

        container.appendChild(html)

        this.selectInstances.push(selectInstance)
    }

    renderAllSelects(container, selectsConfig, callbackChange, callbackOpenWrapper) {
        selectsConfig.forEach(filter => {
            this.renderSelect(container, filter, callbackChange, callbackOpenWrapper)
        })

        return this.selectInstances
    }
}
