import iconExplanationMark from '@icon_templates/icon-explanation-mark'

const templateErrorMessage = `
    <div class="no-results-found">
        <span class="icon-circle-outline-small">
           ${iconExplanationMark}
        </span>
        <p class="no-results-found__msg"><%= message %></p>
    </div>
`

export default templateErrorMessage
