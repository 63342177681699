export const CONDITION_DETAIL = Object.freeze({
    new: 'new',
    likeNew: 'like_new',
    slightlyUsed: 'light_usage',
    averagelyUsed: 'moderate_usage',
    heavilyUsed: 'heavy_usage',
})

export const BIKE_CONDITION = Object.freeze({
    new: [
        CONDITION_DETAIL.new,
    ],
    refurbished: [
        CONDITION_DETAIL.likeNew,
        CONDITION_DETAIL.slightlyUsed,
        CONDITION_DETAIL.averagelyUsed,
        CONDITION_DETAIL.heavilyUsed,
    ],
})
