import SelectRenderer from '../../partials/selects/select-renderer'
import { toggleElementClass } from '../../utils/html-element-utils'
import { mergeModels } from './../../utils/objects'

export default class SidebarFilters {
    constructor(element, selectsConfig, context, callbackChange, callbackPreFetch) {
        this.element = element
        this.context = context
        this.callbackChange = callbackChange
        this.callbackPreFetch = callbackPreFetch
        this.selectRenderer = new SelectRenderer()
        this.containerContent = null
        this.outputTotalCount = null
        this.selectInstances = null
        this.isOpened = false
        this.fixedContentController = this.context.getService('fixed-content-controller')

        this.data = {
            config: selectsConfig,
            model: {},
            totalCount: null,
        }

        this.tempData = {
            config: null,
            model: null,
            totalCount: null,
        }

        this.init()
    }

    init() {
        this.findElements()
        this.addEventListeners()
        this.createFilterInstances()
    }

    findElements() {
        this.containerContent = this.element.querySelector('.sidebar-filters__content')
        this.outputTotalCount = this.element.querySelector('.sidebar-filters__total-count')
    }

    addEventListeners() {
        const buttonReset = this.element.querySelector('.sidebar-filters__button--reset')
        const buttonDiscard = this.element.querySelector('.sidebar-filters__button--discard')
        const buttonApply = this.element.querySelector('.sidebar-filters__button-apply')

        buttonReset.addEventListener('click', this.onButtonResetClicked.bind(this))
        buttonDiscard.addEventListener('click', this.onButtonDiscardClicked.bind(this))
        buttonApply.addEventListener('click', this.onButtonApplyClicked.bind(this))
    }

    createFilterInstances() {
        this.selectInstances = this.selectRenderer.renderAllSelects(
            this.containerContent,
            this.data.config,
            this.onFilterChanged.bind(this),
            this.onWrapperSelectOpened.bind(this),
        )
    }

    onWrapperSelectOpened(nameSelectInstance) {
        const exceptions = [nameSelectInstance]
        this.closeAllSelectInstances(exceptions)
    }

    closeAllSelectInstances(exceptions = []) {
        this.selectInstances.forEach(selectInstance => {
            const name = selectInstance.name

            if (exceptions.includes(name) === true) {
                return
            }

            if (typeof selectInstance.setAndApplyExpanded === 'function') {
                selectInstance.setAndApplyExpanded(false)
            }
        })
    }

    applyModelToSelectInstances(model) {
        this.selectInstances.forEach(filterInstance => {
            filterInstance.tryApplyNewModel(model)
        })
    }

    onButtonResetClicked() {
        this.tempData.model = this.getPristineModel()
        this.applyModelToSelectInstances(this.tempData.model)
        this.setStateLoading(true)
        this.prefetchResults()
    }

    onButtonDiscardClicked() {
        this.discardChanges()
        this.tryCloseSidebar()
    }

    onButtonApplyClicked() {
        this.copyTempModelToModel()
        this.copyTempCountToCount()
        this.dispatchModelChange()
        this.tryCloseSidebar()
    }

    copyTempModelToModel() {
        this.data.model = { ...this.tempData.model }
    }

    copyTempCountToCount() {
        this.data.totalCount = this.tempData.totalCount
    }

    resetTempDataToData() {
        this.tempData.model = { ...this.data.model }
        this.tempData.config = [...this.data.config]
        this.tempData.totalCount = this.data.totalCount
    }

    copyTempDataToData() {
        this.data.model = { ...this.tempData.model }
        this.data.config = [...this.tempData.config]
        this.data.totalCount = this.tempData.totalCount
    }

    discardChanges() {
        this.resetTempDataToData()
        this.updateFooter(this.tempData.totalCount)
        this.applyModelToSelectInstances(this.tempData.model)
        this.applyConfigToSelectInstances(this.tempData.config)
    }

    tryCloseSidebar() {
        this.fixedContentController.onCloseFixedContentClicked()
    }

    onFilterChanged(name, selectedValues) {
        this.tempData.model = mergeModels(this.tempData.model, selectedValues)
        this.setStateLoading(true)
        this.prefetchResults()
    }

    setAndApplyModel(model) {
        this.data.model = { ...model }
        this.tempData.model = { ...model }
        this.applyModelToSelectInstances(this.data.model)
    }

    prefetchResults() {
        this.callbackPreFetch(this.tempData.model)
    }

    dispatchModelChange() {
        this.callbackChange(this.data.model, this.tempData.config)
    }

    setTempData(data) {
        this.tempData = { ...this.tempData, ...data }
    }

    onPrefilterSuccess(totalCount) {
        this.setTotalCountIfUnset(totalCount)
        this.setTempData({ totalCount })
        this.updateFooter(totalCount)
        this.setStateLoading(false)
    }

    onPrefetchSuccess(config, totalCount) {
        const invalidOptions = this.findSelectedAndDisabledItems(config)

        if (invalidOptions.length > 0) {
            this.removeInvalidOptionsFromTempModel(invalidOptions)
            this.applyModelToSelectInstances(this.tempData.model)
            this.prefetchResults()
        } else {
            this.setTotalCountIfUnset(totalCount)
            this.setTempData({ config, totalCount })
            this.setStateLoading(false)
            this.updateFooter(totalCount)
            this.applyConfigToSelectInstances(config)
        }
    }

    removeInvalidOptionsFromTempModel(invalidOptions) {
        invalidOptions.forEach(invalidOption => {
            const { name, nameValue } = invalidOption
            this.tempData.model[name] = this.tempData.model[name].filter(value => value !== nameValue)
        })
    }

    findSelectedAndDisabledItems(config) {
        let allDisabledAndSelectedItems = []

        config.forEach(filterConfig => {
            const { name, items, type } = filterConfig
            const isTypeValid = type === 'select-options-multi' || type === 'select-colors'
            const relatedModelValues = this.tempData.model[name]
            const areValuesDefined = Array.isArray(relatedModelValues) && relatedModelValues.length > 1

            if (isTypeValid === false || areValuesDefined === false) {
                return
            }

            const disabledAndSelectedOptions = items.filter(item => {
                const { disabled, name } = item
                const isItemSelected = relatedModelValues.includes(name)
                return disabled === true && isItemSelected
            })

            const sanitizedOptions = disabledAndSelectedOptions.map(option => {
                return {
                    name: name,
                    nameValue: option.name,
                }
            })

            allDisabledAndSelectedItems = [...allDisabledAndSelectedItems, ...sanitizedOptions]
        })

        return allDisabledAndSelectedItems
    }

    setAndUpdateTotalCount(totalCount) {
        this.data.totalCount = totalCount
        this.tempData.totalCount = totalCount

        this.printTotalCount(totalCount)
        this.updateFooter(totalCount)
    }

    setTotalCountIfUnset(totalCount) {
        if (this.data.totalCount === null) {
            this.data.totalCount = totalCount
        }
    }

    updateFooter(totalCount) {
        const hasNoResults = totalCount < 1

        if (hasNoResults === false) {
            this.printTotalCount(totalCount)
        }

        this.setStateNoResults(hasNoResults)
    }

    printTotalCount(totalCount) {
        this.outputTotalCount.innerHTML = totalCount
    }

    openRequested() {
        this.closeAllSelectInstances()
        this.setStateOpened(true)
    }

    closeRequested() {
        this.setStateOpened(false)
    }

    onBackdropClicked() {
        if (this.isOpened) {
            this.discardChanges()
        }
    }

    applyConfigToSelectInstances(config) {
        this.selectInstances.forEach(selectInstance => {
            const instanceName = selectInstance.name
            const relatedConfig =  config.find(item => item && item.name === instanceName)

            if (relatedConfig) {
                selectInstance.forceUpdateConfig(relatedConfig)
            }
        })
    }

    setStateOpened(isOpened) {
        this.isOpened = isOpened
        toggleElementClass(this.element, 'sidebar-filters--opened', this.isOpened)
    }

    setStateLoading(isLoading) {
        toggleElementClass(this.element, 'sidebar-filters--loading', isLoading)
    }

    setStateNoResults(hasNoResults) {
        toggleElementClass(this.element, 'sidebar-filters--no-results', hasNoResults)
    }

    getPristineModel() {
        const model = {}

        this.data.config.forEach(item => {
            if (!item) {
                return
            }

            const { type, name } = item

            if (type === 'select-options-single') {
                model[name] = ''
            } else if (type === 'select-options-multi' || type === 'select-colors') {
                model[name] = []
            } else if (type === 'select-range-single') {
                model[name] = null
            } else if (type === 'select-range') {
                model[item.nameMin] = null
                model[item.nameMax] = null
            }
        })

        return model
    }

    /**
     * Checks whether the filter is active by examining the length of features in the data model.
     * @returns {boolean} Returns true if the filter is active, otherwise false.
     */
    isFilterActive() {
        return !!this.data.model.features.length
    }
}

