import { toggleElementClass } from '../../utils/html-element-utils'

export default class Pagination {
    constructor(html, config, callback) {
        this.html = html
        this.config = config
        this.callback = callback
        this.model = 1
        this.barElement = null
        this.buttonPrev = null
        this.buttonNext = null
        this.outputActualPage = null
        this.outputTotalPages = null

        this.init()
    }

    init() {
        this.findElements()
        this.addEventListeners()
    }

    findElements() {
        this.barElement = this.html.querySelector('.pagination__bar')
        this.buttonPrev = this.html.querySelector('.pagination__button-prev')
        this.buttonNext = this.html.querySelector('.pagination__button-next')
        this.outputActualPage = this.html.querySelector('.pagination__actual-page')
        this.outputTotalPages = this.html.querySelector('.pagination__total-count-pages')
    }

    addEventListeners() {
        this.buttonPrev.addEventListener('click', this.onButtonPrevClicked.bind(this))
        this.buttonNext.addEventListener('click', this.onButtonNextClicked.bind(this))
    }

    onButtonPrevClicked() {
        this.handlePagerClicked(-1)
    }

    onButtonNextClicked() {
        this.handlePagerClicked(1)
    }

    handlePagerClicked(offset) {
        this.model += offset
        this.updateGui()
        this.tryCallback()
    }

    updateGui() {
        this.updateBarWidth()
        this.updateBarPosition()
        this.updateOutput()
        this.updateButtonStates()
    }

    updateBarWidth() {
        const widthPercent = 100 / this.config.totalPages
        this.barElement.style.width = `${widthPercent}%`
    }

    updateBarPosition() {
        const leftPercent = ((this.model - 1) / this.config.totalPages) * 100
        this.barElement.style.left = `${leftPercent}%`
    }

    updateOutput() {
        this.outputActualPage.innerHTML = this.model
        this.outputTotalPages.innerHTML = this.config.totalPages
    }

    updateButtonStates() {
        const isButtonPrevDisabled = this.model <= 1
        const isButtonNextDisabled = this.model >= this.config.totalPages

        toggleElementClass(this.buttonPrev, 'button-disabled', isButtonPrevDisabled)
        toggleElementClass(this.buttonNext, 'button-disabled', isButtonNextDisabled)
    }

    getMappedOutput() {
        return {
            [ this.config.name ]: this.model
        }
    }

    tryCallback() {
        if (this.callback) {
            const mappedOutput = this.getMappedOutput()
            this.callback(mappedOutput)
        }
    }

    setAndApplyModel(model) {
        const actualPage = model[this.config.name]

        if (actualPage) {
            this.model = this.sanitizeValue(actualPage)
            this.updateGui()
        }
    }

    sanitizeValue(dirtyValue) {
        const value = Array.isArray(dirtyValue) ? dirtyValue[0] : dirtyValue
        return Number(value)
    }

    setTotalPages(count) {
        this.config.totalPages = Number(count)
        this.updateGui()
    }
}
