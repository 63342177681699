import BaseBikeOverview from './base-bike-overview'
import {ga4Custom} from '../../utils/global-imports'

export default class FamilyOverview extends BaseBikeOverview {
    static moduleName = 'family-overview'

    sanitizeGrid = false

    constructor(context) {
        super(context)
    }

    getElements() {
        this.groupItems = this.module.querySelectorAll('[data-filter-item="group"]')
        this.photoItemsContainer = this.module.querySelector('[data-container="photo"]')
        this.listItemsContainer = this.module.querySelector('[data-container="list"]')
        this.teaserItems = this.module.querySelectorAll('[data-ref="tile-teaser"]')
    }

    trackInputModelChanged(model) {
        ga4Custom('family_overview_filter_apply', {
            features: model.features
        })
    }
}
