import BikeAdvisorSentence from '@modules/bike-advisor/partials/bike-advisor-sentence'
import {animateHeight, setHeight, setHeightAuto} from '@modules/bike-advisor/aimation-helper'

const resolveTasksInSeries = (tasks) => {
    return tasks.reduce((promise, task) => {
        return promise.then(() => task())
    }, Promise.resolve())
}

export default class BikeAdvisorQuestion {
    constructor(element, onPlaceholderClicked, onChoiceClicked) {
        this.element = element
        this.sentenceInstances = []
        this.innerElement = this.element.firstElementChild
        this.onPlaceholderClicked = onPlaceholderClicked
        this.onChoiceClicked = onChoiceClicked

        this.addEventListeners()
    }

    addEventListeners() {
        window.addEventListener('resize', this.onWindowResize.bind(this))
    }

    onWindowResize() {
        setHeight(this.element, this.innerElement)
    }

    get actualSentence() {
        return this.sentenceInstances[this.sentenceInstances.length - 1]
    }

    async addSentenceWithPlaceholder(question, selectedSlugs) {
        setHeight(this.element, this.innerElement)

        const sentence = new BikeAdvisorSentence(question, selectedSlugs, this.onPlaceholderClicked, this.onChoiceClicked)

        this.sentenceInstances.push(sentence)
        this.innerElement.appendChild(sentence.html)

        sentence.fadeIn()

        await animateHeight(this.element, this.innerElement)

        // setHeightAuto(this.element)
    }

    async setChoiceToActualSentence(choice) {
        await this.actualSentence.setChoice(choice)
    }

    previewChoiceWithinActualSentence(choice) {
        // const height = this.element.offsetHeight

        // setHeight(this.element, this.innerElement)
        this.actualSentence.previewChoice(choice)

        // const newHeight = this.element.offsetHeight
    }

    async addSentenceWithChoiceInstant(question, choice, selectedSlugs) {
        // const { node: textOption } = getNodeRecursively(question.text_options, selectedSlugs)
        const sentence = new BikeAdvisorSentence(question, selectedSlugs, this.onPlaceholderClicked, this.onChoiceClicked)
        sentence.setChoice(choice)

        this.sentenceInstances.push(sentence)
        this.innerElement.appendChild(sentence.html)

        sentence.showInstant()
    }

    async removeChoiceFromActualSentence() {
        setHeight(this.element, this.innerElement)

        this.actualSentence.removeChoice()

        await animateHeight(this.element, this.innerElement)
    }

    async removeLastSentences(countRemove = 1) {
        const sentencesForRemove = this.sentenceInstances.slice(countRemove * -1)
        this.sentenceInstances = this.sentenceInstances.slice(0, this.sentenceInstances.length - countRemove)

        if (sentencesForRemove.length < 1) return

        setHeight(this.element, this.innerElement)

        this.actualSentence.removeChoice()

        for (let i = sentencesForRemove.length - 1; i >= 0; i--) {
            const sentence = sentencesForRemove[i]
            const fadeStepwise = sentencesForRemove.length < 2

            sentence.swapToAbsolutePosition()
            sentence.fadeOutAndRemove(0, fadeStepwise)
        }

        await animateHeight(this.element, this.innerElement)
        // setHeightAuto(this.element)
    }

    lockHeight() {
        setHeight(this.element, this.innerElement)
    }

    async removeAllSentences() {
        await this.hideAllSentences()
        this.removeSentences()
    }

    removeAllSentencesInstant() {
        // height "auto" prevents height transition
        setHeightAuto(this.element)
        this.removeSentences()
    }

    hideAllSentences() {
        const promises = []

        this.sentenceInstances.forEach((sentence) => {
            promises.push(sentence.fadeOut())
        })

        return Promise.all(promises)
    }

    removeSentences() {
        this.innerElement.innerHTML = ''
        this.sentenceInstances = []
    }
}

