export default class Translator {

    private translations: Record<string, string>

    constructor(translations: Record<string, string>) {
        this.translations = translations
    }

    public translate(name: string, variables: Record<string, string | number> = {}): string {
        let text = this.translations[name] || ''
        Object.entries(variables).forEach(([key, value]) => {
            text = text.replace(`[${key}]`, `${value}`)
        })
        return text
    }
}
