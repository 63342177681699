const weekday = {
    1: 'monday',
    2: 'tuesday',
    3: 'wednesday',
    4: 'thursday',
    5: 'friday',
    6: 'saturday',
    7: 'sunday',
}

const getCurrentWeekday = (): string => {
    const currentDay = getCurrentDay()
    return weekday[currentDay]
}

const getCurrentDay = (): number => {
    let currentDay = new Date(Date.now()).getDay()
    if (currentDay === 0) currentDay = 7
    return currentDay
}

const isCurrentDate = (dateString: string): boolean => {
    const date = new Date(dateString)
    const today = new Date(Date.now())
    return today.toDateString() === date.toDateString()
}

export {
    getCurrentDay,
    getCurrentWeekday,
    isCurrentDate,
}
