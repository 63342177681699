/*global TweenLite, Cubic, axios, Constants, ga4Custom, $, _ */

const _TweenLite = TweenLite
const _Cubic = Cubic
const _axios = axios
const _config = Config
const _constants = Constants
const _ga4Custom = ga4Custom
const _$ = $
const __ = _

export {
    _TweenLite as TweenLite,
    _Cubic as Cubic,
    _axios as axios,
    _config as Config,
    _constants as Constants,
    _ga4Custom as ga4Custom,
    _$ as $,
    __ as _,
}
