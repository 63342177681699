const templateRetailerRadios = `
    <% _.each(retailerList, function(retailer, index) { %>
        <div class="s-12 m-6 l-6">
            <div class="checkout-retailer__item wrapper-radio-box wrapper-radio-box--v2">
                <input
                    type="radio"
                    id="retailer_<%= retailer.id %>"
                    name="retailer"
                    value="<%= retailer.id %>"
                    data-type="checkout-retailer__item-input"
                >
                <label for="retailer_<%= retailer.id %>">
                    <h4 class="retailer__title"><%= retailer.name %></h4>
                    <p class="retailer__distance text-size--normal bold">
                        <%= retailer.distanceLocalized %>
                    </p>
                    <p class="retailer__address additional-info">
                        <%= retailer.street %><br />
                        <%= retailer.cityLocalized %>
                    </p>
                </label>
            </div>
        </div>
    <% }) %>
`

export default templateRetailerRadios
