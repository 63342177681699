import {
    fetchRetailer,
    fetchRetailerByUrl,
} from '@api/rest/retailer-search-api'
import type { Paginated, Retailer, Filter } from '@typedefs/retailer-search'
import type { Location } from '@typedefs/base'
import { trackListViewClick, trackShowMoreClick, trackSuggestFailed } from '../retailer-search-tracking'
import { parseRetailerListTemplateData } from '@helpers/retailer-parser'
import RetailerSearchStore from '@services/retailer-search-store'
import templateErrorMessage from './templates/template-error-message'
import templateButtonLoadMore from './templates/template-button-load-more'
import { getT3Service } from '@utils/t3-bridge'
import { $, _ } from '@utils/global-imports'

export default class RetailerSearchList {

    private readonly container: HTMLElement
    private readonly template: string

    private retailer: Retailer[]
    private nextResults?: string

    private cachedLocation: Location | null
    private cachedFilter: Filter

    private readonly store: RetailerSearchStore
    private readonly config

    private readonly accordionHandler

    constructor(
        container: HTMLElement,
        template: string,
        config,
    ) {
        this.container = container
        this.template = template

        this.config = config
        this.store = getT3Service('retailer-search-store')
        this.accordionHandler = getT3Service('accordion-handler')

        this.retailer = []
        this.cachedLocation = null
        this.cachedFilter = {}
    }

    public getRootElement(): HTMLElement {
        return this.container
    }

    public async onAppear() {
        trackListViewClick()
        await this.onSearchSubmit()
    }

    public onWindowResize() {
        this.accordionHandler.updateHeight(
            $(this.container.parentElement),
            $(this.container),
        )
    }

    public async onSearchSubmit() {
        if (!this.store.isLocationSet) {
            this.showErrorMessage(this.config.error.noResult)
        } else if (this.cachedLocation !== this.store.location || this.cachedFilter !== this.store.filter) {
            await this.updateRetailer()
        }
    }

    private async updateRetailer() {
        this.removePreviousResults()
        this.container.classList.add('loading')

        const data = await fetchRetailer(this.store.searchParams)
        this.onRetailerLoaded(data)
        this.cachedLocation = this.store.location ?? null
        this.cachedFilter = this.store.filter
    }

    private renderResults() {
        let listTemplate = this.template

        if (this.nextResults) {
            listTemplate += templateButtonLoadMore
        }

        const template = _.template(listTemplate)
        const retailerListData = parseRetailerListTemplateData(this.retailer, this.config)

        this.accordionHandler.appendContent(
            $(this.container.parentElement),
            $(this.container),
            $(template(retailerListData)),
            {
                fadeIn: false,
                callback: () => {
                    Box.Application.startAll(this.container)
                }
            },
        )

        const button = this.container.querySelector('#btn-load-retailer')
        button?.addEventListener('click', this.onLoadMoreClicked.bind(this))
    }

    private removePreviousResults() {
        this.retailer = []
        this.container.innerHTML = ''
    }

    private async onLoadMoreClicked() {
        trackShowMoreClick()

        const loadMoreButton = this.container.querySelector('.test-to-remove')
        loadMoreButton?.remove()

        this.container.classList.add('loading')

        try {
            const data = await fetchRetailerByUrl(this.nextResults!)
            this.onRetailerLoaded(data)
        } catch (error) {
            console.error(error)
        }
    }

    private onRetailerLoaded(data: Paginated<Retailer>) {
        this.retailer = data.results
        this.nextResults = data.next

        this.container.classList.remove('loading')

        if (this.retailer.length) {
            this.renderResults()
        } else {
            this.showErrorMessage(this.config.error.noResultByLocation)
            trackSuggestFailed()
        }
    }

    private showErrorMessage(message: string) {
        const template = _.template(templateErrorMessage)
        this.container.innerHTML = template({ message })
        this.accordionHandler.updateHeight(
            $(this.container.parentElement),
            $(this.container),
        )
    }
}
