
const mergeModels = (modelA, modelB) => {
    const copyModelB = {}

    Object.entries(modelB).forEach(([key, value]) => {
        const copyValue = Array.isArray(value) ? [...value] : value
        copyModelB[key] = copyValue
    })

    return { ...modelA, ...copyModelB }
}

export { mergeModels }
