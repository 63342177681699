import {Cubic, TweenLite} from '~/app-modules/utils/global-imports'

const scrollElementIntoView = (element, { duration = 1.5, reference = 'top', align = 'top', offset = 0 }) => {
    const offsetHeightTopBar = reference === 'top' ? -56 : 0
    const moduleTop = element.getBoundingClientRect()[reference]

    const top =
        window.pageYOffset
        + moduleTop
        + offsetHeightTopBar
        + offset

    return new Promise((resolve) => {
        TweenLite.to(window, duration, {
            scrollTo: { y: top, autoKill: false },
            delay: 0.0,
            ease: Cubic.easeInOut,
            onComplete: resolve,
        })
    })
}

export { scrollElementIntoView }

