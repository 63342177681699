const toggleElementClass = (element, className, add = true) => {
    const operator = add ? 'add' : 'remove'
    element.classList[operator](className)
}

const setElementVisibility = (element, isVisible) => {
    element.style.display = isVisible ? 'block' : 'none'
}

export { toggleElementClass, setElementVisibility }
