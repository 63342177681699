import type { Distance } from '@typedefs/base'
import { getActualLanguage } from '@helpers/language'

enum DistanceUnit {
    KM = 'km',
    MI = 'mi'
}

function formatDistance(distance: number, language: string, digits: number = 1) {
    if (distance > 100) {
        return Math.round(distance)
    }
    return distance.toLocaleString(language, {
        maximumFractionDigits: digits,
    })
}

function convertToMiles(km: number) {
    return km * 0.62137
}

function getLocalizedDistanceFromKm(distance: number, digits: number = 1): string {
    return getLocalizedDistance({
        km: distance,
        mi: convertToMiles(distance),
    }, digits)
}

function getLocalizedDistance(distance: Distance, digits: number = 1): string {
    const language = getActualLanguage() || 'de-de'
    const { mi, km } = distance

    return (language === 'en-us')
        ? `${formatDistance(mi, language, digits)} ${DistanceUnit.MI}`
        : `${formatDistance(km, language, digits)} ${DistanceUnit.KM}`
}

export {
    getLocalizedDistance,
    getLocalizedDistanceFromKm,
}
