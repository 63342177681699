import Broadcasts from '~/app/Broadcasts'
import { Config, Constants } from '~/app-modules/utils/global-imports'
import T3ClassDecorator from '~/app-modules/modules/t3-class-decorator'

export default class TopBar extends T3ClassDecorator {

    static moduleName = 'top-bar'
    static messages = [
        Broadcasts.SECTIONS_READY,
        Broadcasts.SCROLL,
        Broadcasts.SCROLL_FAST,
        Broadcasts.WINDOW_RESIZE,
        Broadcasts.PRELOAD_DONE
    ]

    constructor(context) {
        super(context)

        this.initialized = false
        this.disableAnimation = false
        this.forceDisableAnimation = true
        this.thresholdScroll = 0
        this.notAllowedElements = []

        this.browserService = context.getService('browser')
        this.fixedContentControllerService = context.getService('fixed-content-controller')
    }

    init () {
    }

    onmessage(name) {
        switch (name) {
            case Broadcasts.SECTIONS_READY:
                this._init()
                break
            case Broadcasts.SCROLL:
                this._onScroll()
                break
            case Broadcasts.SCROLL_FAST:
                this._onScroll()
                break
            case Broadcasts.WINDOW_RESIZE:
                this._onWindowResize()
                break
            case Broadcasts.PRELOAD_DONE:
                break
        }
    }

    _init() {
        this._getElements()

        this.disableAnimation = this.forceDisableAnimation || this._disableAnimationCheck()

        if (this.browserService.getMediaQuery() === Constants.MQ.SMALL_ONLY || this.disableAnimation) {
            this._shrinkNavigation(true)
        }

        this._addEventListeners()

        this._updatePrimaryMenu()
        this._updateTop()
        this._onScroll()
    }

    _getElements() {
        this.preloaderImg = document.querySelector('#prelaoder .svg-riesemueller')
        this.burgerMenuButton = this.module.querySelector('[data-ref="top-bar-nav-open"]')
        this.searchButton = this.module.querySelector('[data-ref="top-bar-search"]')
        this.topbarPrimary = this.module.querySelector('[data-ref="top-bar-primary-menu"]')

        // disable for following elements or pages
        this.notAllowedElements = [
            document.querySelector('[data-ref="m16-bike-configurator-rb"]'),
        ]
    }

    _addEventListeners() {
        // this.inputElement.addEventListener('click', this.onButtonClicked.bind(this))
        this.burgerMenuButton?.addEventListener('click', this._onBtnOpenNavClicked.bind(this))
        this.searchButton?.addEventListener('click', this._onBtnOpenSearchClicked.bind(this))
    }

    _disableAnimationCheck () {
        return this.notAllowedElements.some(item => item !== null)
    }

    _shrinkNavigation(shrink) {
        this.module.classList[shrink ? 'add' : 'remove']('narrowed')
    }

    _onScroll() {
        const isMqSmallOnly = this.browserService.getMediaQuery() === Constants.MQ.SMALL_ONLY
        const scrollTop = this.browserService.getScrollTop()
        const shouldShrink = isMqSmallOnly || this.disableAnimation

        if (shouldShrink) {
            this._shrinkNavigation(shouldShrink)
            this.module.classList.toggle('scroll-top-visible', scrollTop > this.thresholdScroll)
        } else {
            this.module.classList.remove('scroll-top-visible')
            this._shrinkNavigation(scrollTop > this.thresholdScroll)
            this.preloaderImg?.classList.toggle('narrowed-top-bar', scrollTop > this.thresholdScroll)
        }
    }

    _onWindowResize() {
        this._updatePrimaryMenu()
        this._updateTop()
        this._onScroll()
    }

    _updateTop ()  {
        this.thresholdScroll = Config.THRESHOLD_SHOW_TOP_BAR_PX
    }

    _onBtnOpenNavClicked(event) {
        event.preventDefault()
        this.fixedContentControllerService.onOpenNavClicked()
    }

    _onBtnOpenSearchClicked(event) {
        event.preventDefault()
        this.fixedContentControllerService.onOpenSearchClicked()
    }

    _updatePrimaryMenu () {
        if (this.config.showTopBarPrimary && this.browserService.getMediaQuery() === Constants.MQ.LARGE_ONLY) {

            if (this.topbarPrimary) {
                const topbarPrimaryWidth = this.topbarPrimary.clientWidth

                let totalChildrenWidth = 0;
                [...this.topbarPrimary.children].forEach(child => {
                    const style = window.getComputedStyle(child)
                    const marginLeft = parseInt(style.marginLeft, 10)
                    const marginRight = parseInt(style.marginRight, 10)
                    totalChildrenWidth += child.offsetWidth + marginLeft + marginRight
                });

                this.module.classList[totalChildrenWidth > topbarPrimaryWidth ? 'add' : 'remove']('top-bar__primary-menu--overflow')
                this.burgerMenuButton.classList[totalChildrenWidth <= topbarPrimaryWidth ? 'add' : 'remove']('display-none')
            }
        } else if (this.config.showTopBarPrimary) {
            this.module.classList['remove']('top-bar__primary-menu--overflow')
            this.burgerMenuButton.classList['remove']('display-none')
        }
    }
}
