import type { AutocompletePrediction } from '@typedefs/autocomplete'
import { getT3Service } from '~/app-modules/utils/t3-bridge'

const googleMapsLoader = getT3Service('google-maps-loader')

const fetchPlacesPredictions = async (inputValue: string): Promise<AutocompletePrediction[]> => {
    const autocompleteService = await getAutocompleteService()
    
    return new Promise((resolve, reject) => {
        const isZipcode = /\d/.test(inputValue)
        const types = isZipcode ? '(regions)' : '(cities)'

        autocompleteService.getPlacePredictions({
            input: inputValue,
            types: [types]
        }, (data, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
                resolve(data as AutocompletePrediction[])
            }
            reject()
        })
    })
}

const getAutocompleteService = async (): Promise<google.maps.places.AutocompleteService> => {
    return googleMapsLoader.loadAsync().then(() => {
        return new google.maps.places.AutocompleteService()
    })
}

export { fetchPlacesPredictions }
