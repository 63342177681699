
export default class UrlSearchParamsHandler {
    constructor() {
        this.urlSearchParams = null
        this.model = {}
    }

    getParamsFromUrl() {
        this.readoutUrlParams()
        this.model = this.createModelFromUrlParams()

        return this.model
    }
    
    createUrlFromModel(baseUrl, model) {
        this.setModel(model)
        this.createUrlSearchParamsFromModel()
        const params = this.urlSearchParams.toString()
        return `${baseUrl}?${params}`
    }

    createUrlFromModel(baseUrl, model) {
        this.setModel(model)
        this.createUrlSearchParamsFromModel()
        const params = this.urlSearchParams.toString()
        return `${baseUrl}?${params}`
    }

    setParams(model) {
        this.setModel(model)
        this.createUrlSearchParamsFromModel()
        this.updateBrowserUrl()
    }

    setModel(model) {
        this.model = model
    }

    readoutUrlParams() {
        const searchString = window.location.search
        this.urlSearchParams = new URLSearchParams(searchString)
    }

    createModelFromUrlParams() {
        const model = []

        this.urlSearchParams.forEach((value, key) => {
            const arrayKeyMatch = key.match(/^(.+?)\[(.*?)\]$/)

            const numericValue = !isNaN(Number(value)) && value.trim() !== '' ? Number(value) : value

            if (arrayKeyMatch && arrayKeyMatch[1]) {
                const sanitizedKey = arrayKeyMatch[1]

                if (!model[sanitizedKey]) {
                    model[sanitizedKey] = []
                }

                model[sanitizedKey].push(numericValue)
            } else {
                model[key] = numericValue
            }
        })

        return model
    }

    createUrlSearchParamsFromModel() {
        this.urlSearchParams = new URLSearchParams()

        Object.entries(this.model).forEach(([key, value]) => {
            if (value) {
                this.addValueToSearchParams(key, value)
            }
        })
    }

    addValueToSearchParams(key, value) {
        const valuesArray = Array.isArray(value) ? value : [value]
        const optionalArrayIdentifier = (Array.isArray(value))? '[]' : ''
        const name = `${key}${optionalArrayIdentifier}`

        valuesArray.forEach(value => {
            this.urlSearchParams.append(name, value)
        })
    }

    updateBrowserUrl() {
        const queryString = this.urlSearchParams.toString()
        window.history.replaceState(null, null, `?${queryString}`)
    }

    setEncodedParams(params, name) {
        const encoded = btoa(JSON.stringify(params))
        window.history.replaceState(null, null, `?${name}=${encoded}`)
    }

    getDecodedParams(name) {
        const urlSearchParams = new URLSearchParams(window.location.search)
        const param = urlSearchParams.get(name)

        if (param) {
            try {
                return JSON.parse(atob(param))
            } catch (error) {
                window.history.replaceState(null, null, window.location.pathname)
                return {}
            }
        }

        return {}
    }
}
