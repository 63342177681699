import templateRetailerCard from './template-retailer-card'

const templateRetailerList = `
    <% _.each(retailerList, function(retailer, index) { %>
        <div class="s-12 m-6 l-4">
            <div class="card retailer-item background-white rb__small-text">
                ${templateRetailerCard}
            </div>
       </div>
   <% }) %>
`

export default templateRetailerList
