import type { Retailer } from '@typedefs/retailer-search'
import { parseRetailerTemplateData } from '@helpers/retailer-parser'
import { initOverlay } from '@helpers/map-overlay-helper'
import templateRetailerMapOverlay from './templates/template-retailer-map-overlay'
import { _ } from '@utils/global-imports'

/**
 * lazy import wrapper -
 * RetailerSearchMapOverlay requires google maps to be loaded
 */
const getRetailerSearchMapOverlay = (position: google.maps.LatLng, retailer: Retailer, config) => {
    class RetailerSearchMapOverlay extends google.maps.OverlayView {

        private div?: HTMLElement

        private readonly config
        private readonly latlng: google.maps.LatLng
        private readonly retailer: Retailer

        constructor(position: google.maps.LatLng, retailer: Retailer, config) {
            super()

            this.latlng = position
            this.config = config
            this.retailer = retailer
        }

        getPosition(): google.maps.LatLng {
            return this.latlng
        }

        getOffsetY(): number {
            if (this.div) {
                const { clientHeight } = this.div.children[0]
                return clientHeight / 2 * -1
            }
            return 0
        }

        /**
         * onAdd is called when the map's panes are ready and the overlay has been
         * added to the map.
         */
        onAdd() {
            this.div = document.createElement('div')
            this.div.style.position = 'absolute'
            this.div.style.visibility = 'hidden'

            const template = _.template(templateRetailerMapOverlay)
            this.div.innerHTML = template(parseRetailerTemplateData(this.retailer, this.config))

            const panes = this.getPanes()!
            panes.floatPane.appendChild(this.div)

            initOverlay(this.div, this.close.bind(this))
        }

        draw() {
            const overlayProjection = this.getProjection()
            const point = overlayProjection.fromLatLngToDivPixel(this.latlng)

            if (this.div && point) {
                const { clientWidth, clientHeight } = this.div.children[0]
                this.div.style.left = (point.x - clientWidth / 2) + 'px'
                this.div.style.top = (point.y - clientHeight - 30) + 'px'
            }
        }

        show() {
            if (this.div) {
                this.div.style.visibility = 'visible'
                Box.Application.startAll(this.div)
            }
        }

        /**
         * The onRemove() method will be called automatically from the API if
         * we ever set the overlay's map property to 'null'.
         */
        onRemove() {
            if (this.div) {
                (this.div.parentNode as HTMLElement).removeChild(this.div)
                delete this.div
            }
        }

        close() {
            try {
                this.setMap(null)
            } catch (error) {
                console.error(error)
            }
        }
    }

    return new RetailerSearchMapOverlay(position, retailer, config)
}

export { getRetailerSearchMapOverlay }
