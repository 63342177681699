import type { Location } from '@typedefs/base'
import type { Filter } from '@typedefs/retailer-search'
import T3ServiceClassDecorator from './t3-service-class-decorator'
import { Constants } from '~/app/Constants'

export default class RetailerSearchStore extends T3ServiceClassDecorator {

    static serviceName = 'retailer-search-store'

    privacyHandler
    cookieService

    public filter: Filter
    public location: Location

    constructor(context) {
        super(context)
        this.privacyHandler = this.context.getService('privacy-handler')
        this.cookieService = this.context.getService('cookie-service')

        this.filter = {}

        this.location = {
            city: '',
        }
    }

    get googleApiAllowed(): boolean {
        return this.privacyHandler.getCookiesAccepted(Constants.COOKIES.ADVERTISING)
    }

    get functionalCookiesAccepted(): boolean {
        return this.privacyHandler.getCookiesAccepted(Constants.COOKIES.FUNCTIONAL)
    }

    get isLocationSet(): boolean {
        return !!this.location.city || (!!this.location.lat && !!this.location.lng)
    }

    get searchParams() {
        return {
            ...this.location,
            ...this.filter,
        }
    }

    get searchText(): string {
        return this.location.city ?? ''
    }

    private setLocationCookie() {
        if (this.functionalCookiesAccepted) {
            this.cookieService.setLocationCookies(this.location)
        }
    }

    public setLocation(location: Location) {
        this.location = location
        this.setLocationCookie()
    }

    public reset() {
        this.location = {
            city: '',
        }
        this.filter = {}
    }

    public resetFilter() {
        this.filter = {}
    }

    public setFilter(filterOptions: Filter) {
        this.filter = filterOptions
    }
}
