import ResultsRenderer from '@partials/content/results-renderer'
import bikeResultsTemplate from '@modules/bike-advisor/partials/templates/bike-result-template'

export default class BikeAdvisorResults extends ResultsRenderer {
    constructor(maskElement, translations) {
        const innerElement = maskElement.querySelector('.bike-advisor__results-inner')
        super(innerElement, maskElement)

        this.translations = translations
    }


    renderResults(results) {
        const rendered = bikeResultsTemplate({ results })
        this.container.innerHTML = rendered
    }

    onResultClicked() {
        // console.log('onResultClicked | event: ', event)
    }

    getLabelFromResult(result, index) {
        if (index < 1) {
            return this.translations.recommendation
        } else {
            return `${this.translations.alternative} 0${index}`
        }
    }

    seriesCTA({name}) {
        return this.translations.seriesCta.replace('[bike_series]', name)
    }

    async showResults(results) {
        const mappedResults = results.map((result, index) => {
            return {
                ...result,
                label:  this.getLabelFromResult(result, index),
                // todo: remove
                // images: [{url: '/media/bikes/F00376/25_2.jpg'}],
                isRecommended: index === 0,
                translations: this.translations,
                seriesCTA: this.seriesCTA(result),
            }
        })

        super.showResults(mappedResults)
    }

    async removeResults() {
        this.container.innerHTML = ''
    }
}
