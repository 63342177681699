const getDefaultBreadcrumb = () => {
    // oh "breadcrump" with P... shi* happens
    const wrapperMain = document.body.querySelector('#wrapper-main')
    const firstSection = wrapperMain.querySelector('section.section')
    return firstSection ? firstSection.querySelector('.breadcrump') : null
}

const hideBreadcrumb = () => {
    const defaultBreadcrumb = getDefaultBreadcrumb()
    if (defaultBreadcrumb) {
        defaultBreadcrumb.classList.add('breadcrump--hidden')
    }
}

export { hideBreadcrumb }
