import { getT3Service } from '@utils/t3-bridge'
import type { LatLng } from '@typedefs/base'
import type { RetailerMapInfo, Filter, Retailer } from '@typedefs/retailer-search'
import { fetchLocations, fetchRetailer } from '@api/rest/retailer-search-api'
import { parseRetailerTemplateData } from '@helpers/retailer-parser'
import { getActualLanguage } from '@helpers/language'
import { decodeGeohash } from '@helpers/geohash-parser'
import { createFixedOverlay, initOverlay } from '@helpers/map-overlay-helper'
import { trackMapViewClick, trackSuggestFailed } from '../retailer-search-tracking'
import { trackPinClick } from '../retailer-search-tracking'
import { getRetailerSearchMapOverlay } from './retailer-search-map-overlay'
import templateErrorMessage from './templates/template-error-message'
import templateRetailerMapOverlay from './templates/template-retailer-map-overlay'
import GeocodeService from '@services/geocode-service'
import RetailerSearchStore from '@services/retailer-search-store'
import { _ } from '@utils/global-imports'
import { Config, Constants } from '~/app/Constants'

export default class RetailerSearchMap {

    private readonly container: HTMLElement
    private readonly mapLegend: HTMLElement
    private readonly config

    private readonly store: RetailerSearchStore
    private readonly geocodeService: GeocodeService
    private readonly googleMapsLoader
    private readonly browser

    private retailer: RetailerMapInfo[]
    private cachedCity: string | null
    private cachedFilter: Filter
    private cachedLocation: LatLng | null

    private map: google.maps.Map | null
    private marker: google.maps.marker.AdvancedMarkerElement[]
    private markerClusterer: MarkerClusterer | null

    private mapOverlay // RetailerSearchMapOverlay | undefined
    private googleMapsMapId: string


    public constructor(container: HTMLElement, mapLegend: HTMLElement, config) {
        this.browser = getT3Service('browser')
        this.googleMapsLoader = getT3Service('google-maps-loader')
        this.geocodeService = getT3Service('geocode-service')
        this.store = getT3Service('retailer-search-store')

        this.googleMapsLoader.loadAsync()
        this.mapLegend = mapLegend
        this.container = container
        this.config = config

        this.retailer = []
        this.cachedCity = null
        this.cachedFilter = {}
        this.cachedLocation = null

        this.marker = []
        this.map = null
        this.markerClusterer = null

        this.googleMapsMapId = this.config.googleMapsMapId || ''
    }

    private get useMarkerAsBounds() {
        return Config.SHOW_MAP_INITIALLY.includes(getActualLanguage()!)
    }

    public getRootElement(): HTMLElement {
        return this.container
    }

    public async onAppear() {
        trackMapViewClick()
        await this.onSearchSubmit()
    }

    public onWindowResize() {
        if (this.map) {
            this.updateMapBounds()
        }
    }

    private updateMapBounds() {
        if (this.useMarkerAsBounds) {
            const bounds = new google.maps.LatLngBounds()
            this.marker.forEach((marker) => {
                bounds.extend(marker.position!)
            })

            this.map!.fitBounds(bounds)
            this.map!.panToBounds(bounds)
        } else if (this.cachedLocation) {
            this.map!.setCenter(this.cachedLocation)
        }
    }

    public async onSearchSubmit() {
        this.mapOverlay?.setMap(null)

        const { filter, location: { city } } = this.store

        if (!city?.length) {
            this.showErrorMessage(this.config.error.noResult)
        } else if (this.cachedCity !== city) {
            await this.updateRetailer()
        } else {
            this.mapLegend.classList.add('active')
        }

        if (this.cachedFilter !== filter) {
            this.updateFilter()
        }
    }

    private updateFilter() {
        this.cachedFilter = this.store.filter
        this.removeMarker()
        this.addMarker()
    }

    private async updateRetailer() {
        this.cachedCity = this.store.location.city ?? null
        this.cachedFilter = this.store.filter

        const data = await fetchLocations(this.store.searchParams)
        this.retailer = data.results

        if (this.retailer.length) {
            this.container.classList.remove('no-results')
            await this.updateMap()
            this.mapLegend.classList.add('active')
        } else {
            this.showErrorMessage(this.config.error.noResultByLocation)
            trackSuggestFailed()
        }
    }

    private async updateMap() {
        this.cachedLocation = await this.geocodeService.getLocationByAddress(this.cachedCity)

        if (this.map) {
            this.map.setCenter(this.cachedLocation)
            this.map.setZoom(Config.GOOGLE_MAPS.ZOOM_LEVEL)
            this.removeMarker()
            this.addMarker()
        } else {
            await this.initMap()
            this.addMarker()
            this.updateMapBounds()
        }
    }

    private showErrorMessage(message: string) {
        this.map = null
        this.marker = []
        this.markerClusterer = null

        const template = _.template(templateErrorMessage)
        this.container.innerHTML = template({ message })
        this.container.classList.add('no-results')
        this.mapLegend.classList.remove('active')
    }

    private async initMap() {
        this.map = new google.maps.Map(this.container, {
            center: this.cachedLocation,
            zoom: Config.GOOGLE_MAPS.ZOOM_LEVEL,
            mapId: this.googleMapsMapId,
        })
    }

    private removeMarker() {
        this.marker.forEach((marker) => marker.map = null)
        this.markerClusterer?.removeMarkers(this.marker)
        this.markerClusterer?.clearMarkers()
        this.marker = []
    }

    private addMarker() {
        this.marker = this.retailer.reduce((list, retailer) => {
            const position = decodeGeohash(retailer.geohash)
            const content = document.createElement('img')

            content.src = this.getMarkerImage(retailer)

            const marker = new google.maps.marker.AdvancedMarkerElement({
                position: position,
                map: this.map,
                content: content,
            })
            marker['id'] = `${retailer.id}`
            marker.addListener('click', () => this.onMarkerClick(marker))
            list.push(marker)

            return list
        }, [] as google.maps.marker.AdvancedMarkerElement[])

        this.markerClusterer = new MarkerClusterer(this.map!, this.marker)
    }

    private async onMarkerClick(marker: google.maps.marker.AdvancedMarkerElement) {
        trackPinClick()
        const retailerId = marker['id']

        try {
            const data = await fetchRetailer({
                ...this.store.location,
                id: retailerId,
            })
            const retailer = data.results[0]

            if (this.browser.getMediaQuery() === Constants.MQ.SMALL_ONLY) {
                this.showFixedOverlay(retailer)
            } else {
                const position = marker.position as google.maps.LatLng

                if (position) {
                    this.showRetailerMapOverlay(retailer, position)
                } else {
                    console.error('Marker position is not correctly defined', marker)
                }
            }
        } catch (error) {
            console.error('error while fetching retailer', retailerId, error)
        }
    }

    private showFixedOverlay(retailer: Retailer) {
        const template = _.template(templateRetailerMapOverlay)
        const data = parseRetailerTemplateData(retailer, this.config)
        const fixedOverlay = createFixedOverlay(template(data))
        this.container.appendChild(fixedOverlay)
        initOverlay(fixedOverlay)
    }

    private showRetailerMapOverlay(retailer: Retailer, position: google.maps.LatLng) {
        if (this.mapOverlay) {
            this.mapOverlay.setMap(null)
        }

        this.mapOverlay = getRetailerSearchMapOverlay(
            position,
            retailer,
            this.config,
        )
        this.mapOverlay.setMap(this.map)

        setTimeout(() => {
            this.map!.panTo(position)
            this.map!.panBy(0, this.mapOverlay.getOffsetY())
            this.mapOverlay.show()
        }, 50)
    }

    private getMarkerImage(retailer: RetailerMapInfo): string {
        if (retailer.testbikes) {
            const hasMatchingTestbike = retailer.testbikes.some((seriesGroup) => {
                return seriesGroup.id == this.cachedFilter.series_group_id
            })

            if (hasMatchingTestbike) {
                return Constants.GOOGLE_MAPS.MARKER_IMAGES.MATCHING_TESTBIKE
            }
        }

        if (retailer.experience_store || retailer.ecargo_hub) {
            return Constants.GOOGLE_MAPS.MARKER_IMAGES.FEATURED_STORE
        }

        return Constants.GOOGLE_MAPS.MARKER_IMAGES.DEFAULT
    }
}
