import SelectOptions from './select-options'
import {toggleElementClass} from '../../utils/html-element-utils'

const templateSelectColors = `
    <div class="select-colors">
        <div class="select-colors__items">
            <% _.each( items, function( item, index ) { %>
            <div class="select-colors__item">
                <input
                    class="select-colors__input"
                    type="checkbox"
                    id="<%= name %>-<%= item.name %>"
                    name="<%= item.name %>">

                <label
                    class="select-colors__label"
                    data-color-name="<%= item.name %>"
                    style="background-color: <%= item.color %>;"
                    for="<%= name %>-<%= item.name %>">
                </label>

                <div class="select-colors__tooltip"><%= item.label %></div>
            </div>
            <% }); %>
        </div>
    </div>
`

export default class SelectColors extends SelectOptions {

    static type = 'select-colors'

    constructor(config, callback) {
        super(config, callback)
    }

    setDisabledToCheckbox(checkbox, disabled) {
        toggleElementClass(checkbox, 'select-colors__item--disabled', disabled)
    }

    get templateString() {
        return templateSelectColors
    }
}
