import type { Coordinates } from '@typedefs/base'

const decodeGeohash = (geohash: string): google.maps.LatLng => {
    const { lat, lng } = geohashToCoordinates(geohash)
    return new google.maps.LatLng(lat, lng)
}

const geohashToCoordinates = (geohash: string): Coordinates => {
    const { latitude, longitude } = GeoHash.decodeGeoHash(geohash)
    return {
        lat: latitude.shift(),
        lng: longitude.shift(),
    }
}

export { decodeGeohash }