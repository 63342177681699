import T3ClassDecorator from '../t3-class-decorator'
import UrlSearchParamsHandler from '~/app-modules/helpers/url-search-params-handler'

export default class Link extends T3ClassDecorator {
    
    static moduleName = 'link'
    
    constructor(context) {
        super(context)
        this.urlSearchParamsHandler = new UrlSearchParamsHandler()
    }
    
    init() {
        this.module.href = this.urlSearchParamsHandler.createUrlFromModel(
            this.config.url,
            this.config.params,
        )
    }
}
