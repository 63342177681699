/*global Box */

const registerT3ModuleClass = (moduleClass) => {
    if (!Box) {
        console.error('t3 module class cannot be initialized - error: "Box" is not available')
        return
    }

    return Box.Application.addModule(moduleClass.moduleName, context => {
        const instance = new moduleClass(context)
        return {
            ...instance.publicT3Methods(),
            ...instance.publicT3Attributes(),
        }
    })
}

const registerT3ModuleClasses = (moduleClasses) => {
    moduleClasses.forEach(registerT3ModuleClass)
}

const registerT3ServiceClass = (serviceClass) => {
    if (!Box) {
        console.error('t3 module class cannot be initialized - error: "Box" is not available')
        return
    }

    return Box.Application.addService(serviceClass.serviceName, context => {
        return new serviceClass(context)
    })
}

const exportClassAsT3Service = (name, exportClass) => {
    const t3Application = getT3Application()

    if (t3Application) {
        Box.Application.addService(name, () => {
            return {
                getClass() {
                    return exportClass
                }
            }
        })
    }
}


const registerT3ServiceClasses = (serviceClasses) => {
    serviceClasses.forEach(registerT3ServiceClass)
}

const getT3Application = () => {
    if (Box && Box.Application) {
        return Box.Application
    } else {
        console.error('t3 service cannot be found - error: "Box" is not available')
    }
}

const getT3Service = (name) => {
    const t3Application = getT3Application()

    if (t3Application) {
        const service = t3Application.getService(name)

        if (service) {
            return service
        } else {
            console.error(`t3 service cannot be found - error: Service "${name}" is not available`)
        }
    }
}

const exportAsT3Service = (name, exportData) => {
    const t3Application = getT3Application()

    if (t3Application) {
        Box.Application.addService(name, () => {
            return exportData
        })
    }
}

export {
    registerT3ModuleClass,
    registerT3ModuleClasses,
    registerT3ServiceClass,
    registerT3ServiceClasses,
    getT3Application,
    getT3Service,
    exportAsT3Service,
    exportClassAsT3Service,
}
