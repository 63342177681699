import BikeAdvisorChoice from '@modules/bike-advisor/partials/bike-advisor-choice'
import {animateHeight, setHeight, setHeightAuto} from '@modules/bike-advisor/aimation-helper'
import {filterForPermittedNodes} from '../bike-advisor-helper'

export default class BikeAdvisorChoices {
    constructor(element, onChange, onMouseOver, onMouseOut) {
        this.element = element
        this.onChange = onChange
        this.onMouseOver = onMouseOver
        this.onMouseOut = onMouseOut

        this.actualQuestion = null
        this.choiceInstances = []
        this.headlineInstance = null

        this.innerElement = this.element.querySelector('.bike-advisor__choices-inner')
        this.outputElement = this.element.querySelector('.bike-advisor__choices-output')
    }

    async addQuestion(question, selectedSlugs) {
        this.actualQuestion = question

        setHeight(this.element, this.innerElement)

        // await this.hideChoices()
        this.removeChoices()

        const permittedChoices = filterForPermittedNodes(question.choices, selectedSlugs)
        this.renderChoices(permittedChoices)

        await Promise.all([
            animateHeight(this.element, this.innerElement),
            this.showChoices()
        ])

        setHeightAuto(this.element)
    }

    lockHeight() {
        setHeight(this.element, this.innerElement)
    }

    async hideQuestion() {
        await this.hideChoices()
    }

    hideChoices() {
        this.actualQuestion = null

        const promises = []
        const length = this.choiceInstances.lengh
        this.choiceInstances.forEach((choice, index) => {
            const delay = (length - index) * 200
            promises.push(choice.fadeOut(delay))
        })

        return Promise.all(promises)
    }

    removeChoices() {
        this.outputElement.innerHTML = ''
        this.choiceInstances = []
    }

    renderChoices(choices) {
        choices.forEach((choice) => {
            const choiceInstance = new BikeAdvisorChoice(
                choice,
                this.onChoiceClicked.bind(this),
                this.onChoiceMouseOver.bind(this),
                this.onChoiceMouseOut.bind(this)
            )
            this.choiceInstances.push(choiceInstance)
            this.outputElement.appendChild(choiceInstance.html)
        })
    }

    showChoices() {
        const promises = []

        this.choiceInstances.forEach((choice, index) => {
            const delay = index * 100
            promises.push(choice.fadeIn(delay))
        })

        return Promise.all(promises)
    }

    onChoiceClicked(choice) {
        if (this.onChange) {
            this.onChange(this.actualQuestion, choice)
        }
    }

    onChoiceMouseOver(choice) {
        this.stopTimeoutMouseOut()

        if (this.onMouseOver) {
            this.onMouseOver(this.actualQuestion, choice)
        }
    }

    onChoiceMouseOut() {
        this.stopTimeoutMouseOut()

        if (this.actualQuestion !== null) {
            this.startTimeoutMouseOut()
        }
    }

    startTimeoutMouseOut() {
        this.timeoutMouseOut = setTimeout(() => {
            if (this.onMouseOut) {
                this.onMouseOut(this.actualQuestion)
            }
        }, 400)
    }

    stopTimeoutMouseOut() {
        clearTimeout(this.timeoutMouseOut)
    }

    playPulseAnimation() {
        this.choiceInstances.forEach((instance, index) => {
            setTimeout(() => {
                instance.playPulseAnimation()
            }, index * 175)
        })
    }
}

