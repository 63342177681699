const mapFilterLabelsFromTranslationsByName = (filters, translations) => {
    return filters.map(filter => {
        const relatedTranslation  = translations[filter.name]

        if (relatedTranslation) {
            filter.label = relatedTranslation
        }

        return filter
    })
}

export { mapFilterLabelsFromTranslationsByName }
