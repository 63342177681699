/*global Box */

import isIBAN from 'validator/lib/isIBAN'
import isBIC from 'validator/lib/isBIC'

const addValidatorT3Service = () => {
    if (!Box) {
        return
    }

    Box.Application.addService('t3-validator-service', () => {
        return {
            getIBANValidator() { 
                return isIBAN 
            },
            getBICValidator() { 
                return isBIC 
            },
        }
    })
}

export default addValidatorT3Service
