import iconArrowRight from '@icon_templates/icon-arrow-right'
import clsx from 'clsx'

const iconMap = {
    right: iconArrowRight,
}

const circleButton = ({
    label,
    link,
    className: propsClassName,
    order = 'label-left',
    icon = 'right',
    target = '_self',
    onclick = undefined
}) => {
    const className = clsx([
        'button-circle',
        `button-${order}`,
        propsClassName,
    ])

    const iconTemplate = iconMap[icon]

    return `
        <a class="${className}" href="${link}" target="${target}" onclick="${onclick}">
            <div class="circle">
                <span>${iconTemplate}</span>
                <span>${iconTemplate}</span>
            </div>
            <label class="button-label">${label}</label>
        </a>
    `
}

export { circleButton }
