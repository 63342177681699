import Broadcasts from '~/app/Broadcasts'
import { Config } from '~/app/Constants'
import T3ClassDecorator from '@modules/t3-class-decorator'
import Dropdown from '@partials/selects/dropdown'
import LocationService from '@services/location-service'
import { getActualLanguage } from '@helpers/language'
import { getLocalizedDistanceFromKm } from '@helpers/distance'
import RetailerSearchStore from '@services/retailer-search-store'
import { fetchTotalFilterResults } from '@api/rest/retailer-search-api'
import Translator from '@helpers/translator'
import { trackSearchSubmit } from './retailer-search-tracking'
import RetailerSearchList from './partials/retailer-search-list'
import RetailerSearchMap from './partials/retailer-search-map'
import templateRetailerList from './partials/templates/template-retailer-list'
import { $, _ } from '@utils/global-imports'

enum RetailerSearchView {
    LIST,
    MAP,
}

export default class RetailerSearch extends T3ClassDecorator {
    static moduleName = 'retailer-search'
    static behaviors = ['track-in-viewport']
    static messages = [
        Broadcasts.MAPS_VIEW_UNLOCKED,
        Broadcasts.RETAILER_SEARCH_SUBMIT,
        Broadcasts.COOKIES_ADVERTISING_ACCEPTED,
    ]

    private readonly content: HTMLElement
    private readonly list: RetailerSearchList
    private readonly map: RetailerSearchMap
    private activeView: RetailerSearchView

    private searchResultsHeadline: HTMLElement
    private inputContentSwitch: HTMLInputElement
    private mapLegend: HTMLElement
    private filterDropdown: Dropdown

    private readonly store: RetailerSearchStore
    private readonly translator: Translator
    private readonly locationService: LocationService

    private readonly accordionHandler
    private readonly fixedContentController

    private readonly globalConfig: any

    constructor(context) {
        super(context)

        this.content = this.module.querySelector('[data-ref="mask"]')

        this.searchResultsHeadline = this.module.querySelector('[data-ref="search-results-headline"]')
        this.inputContentSwitch = this.module.querySelector('input[name="view-toggle"]')
        this.mapLegend = this.module.querySelector('[data-ref="map-legend"]')

        this.store = this.context.getService('retailer-search-store')
        this.locationService = this.context.getService('location-service')
        this.accordionHandler = this.context.getService('accordion-handler')
        this.fixedContentController = this.context.getService('fixed-content-controller')
        this.translator = new Translator(this.config.headline)

        this.globalConfig = context.getGlobalConfig()

        this.config = {
            ...this.config || {},
            googleMapsMapId: this.globalConfig.googleMapsMapId
        }

        this.filterDropdown = new Dropdown(
            this.config.filter,
            this.onDropdownChange.bind(this),
        )
        this.initFilterDropdown()

        this.list = new RetailerSearchList(
            this.module.querySelector('[data-ref="list-view"]'),
            templateRetailerList,
            this.config,
        )

        this.map = new RetailerSearchMap(
            this.module.querySelector('[data-ref="map-view"]'),
            this.mapLegend,
            this.config,
        )

        this.activeView = this.showMapInitially && this.store.googleApiAllowed
            ? RetailerSearchView.MAP
            : RetailerSearchView.LIST

        this.inputContentSwitch.checked = this.activeView === RetailerSearchView.MAP
        this.inputContentSwitch.addEventListener('click', this.onContentSwitchToggled.bind(this))

        navigator.geolocation.watchPosition(this.onBrowserLocationUpdated.bind(this))

        const debouncedResize = _.debounce(this.onResize.bind(this), 250)
        window.addEventListener('resize', debouncedResize)
    }

    async onmessage(name: string) {
        switch (name) {
            case Broadcasts.COOKIES_ADVERTISING_ACCEPTED:
                if (!this.store.isLocationSet) {
                    await this.setDefaultInputValue()
                }
                break
            case Broadcasts.MAPS_VIEW_UNLOCKED:
                await this.onMapViewUnlocked()
                break
            case Broadcasts.RETAILER_SEARCH_SUBMIT:
                await this.onSearchSubmitted()
                break
        }
    }

    private onResize() {
        this.activeModule.onWindowResize()
    }

    private get activeModule() {
        return this.activeView === RetailerSearchView.LIST
            ? this.list
            : this.map
    }

    private get showMapInitially() {
        const language = getActualLanguage()
        if (language) {
            return Config.SHOW_MAP_INITIALLY.includes(language)
        }
        return false
    }

    init() {
        this.searchResultsHeadline.innerText = this.translator.translate('default')
        this.activeModule.onAppear()

        const rootElement = this.activeModule.getRootElement()
        rootElement.classList.add('active')

        this.setDefaultInputValue()
    }

    private onBrowserLocationUpdated({ coords }) {
        if (!this.store.isLocationSet) {
            this.store.setLocation({
                lat: coords.latitude,
                lng: coords.longitude,
            })
            this.context.application.broadcast(Broadcasts.RETAILER_SEARCH_SUBMIT)
        }
    }

    private async getSearchResultHeadline(): Promise<string> {
        if (!this.store.filter.series_group_id || !this.store.isLocationSet) {
            return this.translator.translate('default')
        }

        try {
            const { results, name } = await fetchTotalFilterResults(this.store.searchParams)
            const searchRadius = getLocalizedDistanceFromKm(20, 0)

            const translationKey = results
                ? 'filterResults'
                : 'noFilterResults'

            return this.translator.translate(translationKey, {
                results,
                name,
                searchRadius,
            })
        } catch (error) {
            return this.translator.translate('default')
        }
    }

    private initFilterDropdown() {
        const filterContainer = this.module.querySelector('.retailer-search-mask__dropdown-filter')
        filterContainer.appendChild(this.filterDropdown.html)

        const initialFilterValue = this.config.filter.initial

        if (!initialFilterValue) {
            return
        }

        this.filterDropdown!.setAndApplyModel({
            testbike: initialFilterValue,
        })
        this.onDropdownChange({
            testbike: initialFilterValue,
        })
    }

    protected onDropdownChange(model: { testbike: string }) {
        const { testbike } = model

        if (testbike !== 'all') {
            this.store.setFilter({
                series_group_id: Number(testbike),
            })
        } else {
            this.store.resetFilter()
        }

        this.context.application.broadcast(Broadcasts.RETAILER_SEARCH_SUBMIT)
    }

    private async onSearchSubmitted() {
        trackSearchSubmit()
        this.searchResultsHeadline.innerText = await this.getSearchResultHeadline()
        await this.activeModule.onSearchSubmit()
    }

    private async onContentSwitchToggled(event?: Event) {
        const toggledView = this.inputContentSwitch.checked
            ? RetailerSearchView.MAP
            : RetailerSearchView.LIST

        if (toggledView === RetailerSearchView.LIST) {
            this.mapLegend.classList.remove('active')
        } else if (!this.store.googleApiAllowed) {
            event?.preventDefault()
            this.fixedContentController.openMapsPrivacyLayer()
            return
        }

        await this.switchView(toggledView)
    }

    private async onMapViewUnlocked() {
        if (!this.store.isLocationSet) {
            this.store.setLocation(await this.locationService.getUserLocation())
        }
        this.inputContentSwitch.checked = true
        await this.switchView(RetailerSearchView.MAP)
    }

    private async switchView(view: RetailerSearchView) {
        if (this.activeView === view) return

        let toggledModule = this.activeView === RetailerSearchView.LIST
            ? this.map
            : this.list

        this.accordionHandler.switchContent(
            $(this.content),
            $(toggledModule.getRootElement()),
            $(this.activeModule.getRootElement()),
            {
                updateHeight: true,
                callback: async () => {
                    this.activeView = view
                    await this.activeModule.onAppear()
                },
            },
        )
    }

    private async setDefaultInputValue() {
        const location = await this.locationService.getUserLocation()
        this.store.setLocation(location)
        this.context.application.broadcast(Broadcasts.RETAILER_SEARCH_SUBMIT)
    }
}
