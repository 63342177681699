import { configuratorFeatureBlacklist } from '../constants/configurator-feature-blacklist'

const removeFeaturesFromNavigation = (element, codePrefix) => {
    const blacklist = configuratorFeatureBlacklist.find(item => item.codePrefix === codePrefix)

    if (!blacklist) return

    const navigationItems = element.querySelectorAll('.configurator__navigation-list-item')

    navigationItems.forEach(item => {
        const button = item.querySelector('.configurator__navigation-link')
        const id = button.getAttribute('data-id')

        if (blacklist.featureIDs.includes(id)) {
            item.remove()
        }
    })
}

export { removeFeaturesFromNavigation }
