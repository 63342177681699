/*global Box */

import iconArrowDown from '@icon_templates/icon-arrow-down'
import iconArrowRight from '@icon_templates/icon-arrow-right'
import iconCheck from '@icon_templates/icon-check'

const iconTemplateBridge = () => {
    if (!Box) {
        return
    }

    Box.Application.addService('t3-icon-template-bridge', () => {
        return {
            getIconArrowDown() { 
                return iconArrowDown 
            },
            getIconArrowRight() { 
                return iconArrowRight 
            },
            getIconCheck() { 
                return iconCheck 
            },
        }
    })
}

export default iconTemplateBridge
